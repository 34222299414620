import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { EpaasService } from 'customerdigital-service-lib';
import { Observable, map } from 'rxjs';
import { IAppState } from "../store/app.store";
import { AdobeAnalyticsActions } from '../store/reducers/adobe-analytics.reducer';
import { RouterService, routes } from './router.service';
import { IsEpassInitializedActions } from '../store/reducers/is-epaas-initialized.reducer';
import { CannotAuthenticateActions } from '../store/reducers/cannot-authenticate.reducer';

declare const epaas: any;
const notAvailableEvent = 'consentcontroller.api.notavailable';
const cookiePolicyPageClickEvent = 'consentcontroller.api.showPolicyPageClicked';
@Injectable()
export class EpaasHelperService {

  constructor(
    private translateService: TranslateService,
    private epaasService: EpaasService,
    private store: Store<IAppState>,
    private isEpassInitializedActions:IsEpassInitializedActions,
    private cannotAuthenticateActions: CannotAuthenticateActions,
    @Inject(AdobeAnalyticsActions) private adobeAnalyticsActions: AdobeAnalyticsActions,
    private routerService : RouterService) {
  }

  public initializeEpaas(): void {
    if(this.ePaasEnabled()){
      epaas.api.addEventListener(cookiePolicyPageClickEvent, () => this.navigateToCookiePolicyPage());
      epaas.api.addEventListener(notAvailableEvent, () => this.cannotAuthenticate());
      this.store.dispatch(this.isEpassInitializedActions.setIsEpassInitialized(true));
      this.setAdobeAnalyticsEnabled();
      this.setLanguage();
    }
  }

  public ePaasEnabled() : boolean{
    if(typeof epaas != "undefined"){
      return true;
    }
    else
      return false;
  }

  public setLanguage() {
    this.epaasService.changeLanguage(this.getEpaasLocale);
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.epaasService.changeLanguage(this.getEpaasLocale);
    });
  }

  get getEpaasLocale(): string {
    let epaasLocale: string;
    epaasLocale = this.translateService.instant("epaas.locale");
    return epaasLocale;
  }
  
  public getItem(key: string): string|null {
    return this.epaasService.getItem(key);
  }

  public setItem(key: string, data: string) {
    this.epaasService.setItem(key, data);
  }

  public removeItem(key: string): void {
    this.epaasService.removeItem(key);
  }

  public setAdobeAnalyticsEnabled() {
    this.isUsageAllowed('AdobeAnalytics').subscribe(res => {
      this.store.dispatch(this.adobeAnalyticsActions.setAdobeAnalyticsEnabled(res ?? false));
    });
  }

  public isUsageAllowed(cookieCategory: string): Observable<boolean|undefined> {
    return this.store.select(state=>state.IsEpassInitialized).pipe(map((res) => {
      if (res?.booleanValue)
        return this.epaasService.isUsageAllowed(cookieCategory);

      else{
        return undefined;
      }
    }));
  }
  
  navigateToCookiePolicyPage() {
    this.routerService.navigateTo(routes.cookiePolicy);
  }

  cannotAuthenticate(){
    this.store.dispatch(this.cannotAuthenticateActions.setCannotAuthenticate(true));
  }
}