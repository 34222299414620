import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { CommonModule } from '@angular/common';
import {SubNavHeaderModule} from '../../../../_shared/components/subnav-header/subnav-header.module'

@NgModule({
  imports: [CommonModule, SubNavHeaderModule],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
  
})

export class HeaderModule {

}
