import { mergeMap, catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpResponseBase, HttpErrorResponse } from '@angular/common/http';
import { API_BASE_URL, blobToText, getCleanApiUrl, throwException } from '../helper/api-helper';
import { Constants } from 'projects/_shared/shared/constants';
import { GetContactByGcidClientIdRequest, GetContactByGcidClientIdResponse } from './auth-service.api';

export interface IPartnerContactServiceClient {
  /**
   * @return OK
   */
  updateNAUserDetails(request: UpdateNAUserDetailsRequest): Observable<UpdateNAUserDetailsResponse>;
}


@Injectable()
export class PartnerContactServiceClient implements IPartnerContactServiceClient {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.http = http;
    this.baseUrl = baseUrl!;
  }

  /**
   * @return OK
   */
   updateNAUserDetails(request: UpdateNAUserDetailsRequest): Observable<UpdateNAUserDetailsResponse> {
    let url_ = this.baseUrl + "/api/PartnerContactService/UpdateNAUserDetails";
    url_ = getCleanApiUrl(url_);
    const content_ = JSON.stringify(request);
    let options_: any = {
      body: content_,
      observe: Constants.response,
      responseType: Constants.responseTypeBlob,
      headers: new HttpHeaders({
        "Content-Type": Constants.headerContentType,
        "Accept": Constants.headerAccept
      })
    };
    return this.http.request("post", url_, options_).pipe(mergeMap((response_: any) => {
      return this.processUpdateNAUserDetails(response_);
    }),
    catchError((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processUpdateNAUserDetails(<any>response_);
        } catch (e) {
          return <Observable<UpdateNAUserDetailsResponse>><any>throwError(()=>e);
        }
      } else
        return <Observable<UpdateNAUserDetailsResponse>><any>throwError(()=>response_);
    }));
  }
  protected processUpdateNAUserDetails(response: HttpResponseBase): Observable<UpdateNAUserDetailsResponse> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;
    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = UpdateNAUserDetailsResponse.fromJS(resultData200);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of<UpdateNAUserDetailsResponse>(<any>null);
  }

   /**
   * @return OK
   */
    getContactByGcidClientId(request: GetContactByGcidClientIdRequest): Observable<GetContactByGcidClientIdResponse> {
      let url_ = this.baseUrl + "/api/PartnerContactService/GetContactByGcidClientId";
      url_ = url_.replace(/[?&]$/, "");
  
      const content_ = JSON.stringify(request);
  
      let options_: any = {
        body: content_,
        observe: "response",
        responseType: "blob",
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Accept": "application/json"
        })
      };
  
      return this.http.request("post", url_, options_).pipe(mergeMap((response_: any) => {
        return this.processGetContactByGcidClientId(response_);
      }),catchError((response_: any) => {
        if (response_ instanceof HttpResponseBase) {
          try {
            return this.processGetContactByGcidClientId(<any>response_);
          } catch (e) {
            return <Observable<GetContactByGcidClientIdResponse>><any>throwError(()=>e);
          }
        } else
          return <Observable<GetContactByGcidClientIdResponse>><any>throwError(()=>response_);
      }));
    }
  
    protected processGetContactByGcidClientId(response: HttpResponseBase): Observable<GetContactByGcidClientIdResponse> {
      const status = response.status;
      const responseBlob =
        response instanceof HttpResponse ? response.body :
          (<any>response).error instanceof Blob ? (<any>response).error : undefined;
  
      let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
      if (status === 200) {
        return blobToText(responseBlob).pipe(mergeMap(_responseText => {
          let result200: any = null;
          let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
          result200 = resultData200 ? GetContactByGcidClientIdResponse.fromJS(resultData200) : new GetContactByGcidClientIdResponse();
          return of(result200);
        }));
      } else if (status !== 200 && status !== 204) {
        return blobToText(responseBlob).pipe(mergeMap(_responseText => {
          return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }));
      }
      return of<GetContactByGcidClientIdResponse>(<any>null);
    }
  

}



export class UpdateNAUserDetailsRequest implements IUpdateNAUserDetailsRequest {
  gCID?: string | undefined;
  title?: string | undefined;
  firstName?: string | undefined;
  lastname?: string | undefined;
  middleName?: string | undefined;
  suffix?: string | undefined;
  addressCheck?: boolean | undefined;
  homePhNumber?: string | undefined;
  workPhNumber?: string | undefined;
  cellPhNumber?: string | undefined;
  street?: string | undefined;
  suite?: string | undefined;
  city?: string | undefined;
  county?: string | undefined;
  state?: string | undefined;
  country?: string | undefined;
  zip?: string | undefined;
  communicationEmail?: string | undefined;
  constructor(data?: IUpdateNAUserDetailsRequest) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
  init(data?: any) {
    if (data) {
      this.gCID = data["GCID"];
      this.title = data["Title"];
      this.firstName = data["FirstName"];
      this.lastname = data["Lastname"];
      this.middleName = data["MiddleName"];
      this.suffix = data["Suffix"];
      this.addressCheck = data["AddressCheck"];
      this.homePhNumber = data["HomePhNumber"];
      this.workPhNumber = data["WorkPhNumber"];
      this.cellPhNumber = data["CellPhNumber"];
      this.street = data["Street"];
      this.suite = data["Suite"];
      this.city = data["City"];
      this.county = data["County"];
      this.state = data["State"];
      this.country = data["Country"];
      this.zip = data["Zip"];
      this.communicationEmail = data["CommunicationEmail"];
    }
  }
  static fromJS(data: any): UpdateNAUserDetailsRequest {
    data = typeof data === 'object' ? data : {};
    let result = new UpdateNAUserDetailsRequest();
    result.init(data);
    return result;
  }
  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["GCID"] = this.gCID;
    data["Title"] = this.title;
    data["FirstName"] = this.firstName;
    data["Lastname"] = this.lastname;
    data["MiddleName"] = this.middleName;
    data["Suffix"] = this.suffix;
    data["AddressCheck"] = this.addressCheck;
    data["HomePhNumber"] = this.homePhNumber;
    data["WorkPhNumber"] = this.workPhNumber;
    data["CellPhNumber"] = this.cellPhNumber;
    data["Street"] = this.street;
    data["Suite"] = this.suite;
    data["City"] = this.city;
    data["County"] = this.county;
    data["State"] = this.state;
    data["Country"] = this.country;
    data["Zip"] = this.zip;
    data["CommunicationEmail"] = this.communicationEmail;
    return data;
  }
}
export interface IUpdateNAUserDetailsRequest {
  gCID?: string | undefined;
  title?: string | undefined;
  firstName?: string | undefined;
  lastname?: string | undefined;
  middleName?: string | undefined;
  suffix?: string | undefined;
  addressCheck?: boolean | undefined;
  homePhNumber?: string | undefined;
  workPhNumber?: string | undefined;
  cellPhNumber?: string | undefined;
  street?: string | undefined;
  suite?: string | undefined;
  city?: string | undefined;
  county?: string | undefined;
  state?: string | undefined;
  country?: string | undefined;
  zip?: string | undefined;
  communicationEmail?: string | undefined;
}
export class UpdateNAUserDetailsResponse implements IUpdateNAUserDetailsResponse {
  responseContent?: ResponseContentDTO | undefined;
  dataContent?: DataContentDTO | undefined;
  constructor(data?: IUpdateNAUserDetailsResponse) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
  init(data?: any) {
    if (data) {
      this.responseContent = data["ResponseContent"] ? ResponseContentDTO.fromJS(data["ResponseContent"]) : <any>undefined;
      this.dataContent = data["DataContent"] ? DataContentDTO.fromJS(data["DataContent"]) : <any>undefined;
    }
  }
  static fromJS(data: any): UpdateNAUserDetailsResponse {
    data = typeof data === 'object' ? data : {};
    let result = new UpdateNAUserDetailsResponse();
    result.init(data);
    return result;
  }
  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["ResponseContent"] = this.responseContent ? this.responseContent.toJSON() : <any>undefined;
    data["DataContent"] = this.dataContent ? this.dataContent.toJSON() : <any>undefined;
    return data;
  }
}
export interface IUpdateNAUserDetailsResponse {
  responseContent?: ResponseContentDTO | undefined;
  dataContent?: DataContentDTO | undefined;
}
export class ResponseContentDTO implements IResponseContentDTO {
  responseMessage?: string | undefined;
  responseCode?: ResponseContentDTOResponseCode | undefined;
  constructor(data?: IResponseContentDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
  init(data?: any) {
    if (data) {
      this.responseMessage = data["ResponseMessage"];
      this.responseCode = data["ResponseCode"];
    }
  }
  static fromJS(data: any): ResponseContentDTO {
    data = typeof data === 'object' ? data : {};
    let result = new ResponseContentDTO();
    result.init(data);
    return result;
  }
  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["ResponseMessage"] = this.responseMessage;
    data["ResponseCode"] = this.responseCode;
    return data;
  }
}
export interface IResponseContentDTO {
  responseMessage?: string | undefined;
  responseCode?: ResponseContentDTOResponseCode | undefined;
}
export class DataContentDTO implements IDataContentDTO {
  suggestedAddress?: SuggestedAddressDTO | undefined;
  constructor(data?: IDataContentDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
  init(data?: any) {
    if (data) {
      this.suggestedAddress = data["SuggestedAddress"] ? SuggestedAddressDTO.fromJS(data["SuggestedAddress"]) : <any>undefined;
    }
  }
  static fromJS(data: any): DataContentDTO {
    data = typeof data === 'object' ? data : {};
    let result = new DataContentDTO();
    result.init(data);
    return result;
  }
  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["SuggestedAddress"] = this.suggestedAddress ? this.suggestedAddress.toJSON() : <any>undefined;
    return data;
  }
}
export interface IDataContentDTO {
  suggestedAddress?: SuggestedAddressDTO | undefined;
}
export class SuggestedAddressDTO implements ISuggestedAddressDTO {
  street?: string | undefined;
  suite?: string | undefined;
  city?: string | undefined;
  county?: string | undefined;
  state?: string | undefined;
  country?: string | undefined;
  zip?: string | undefined;
  constructor(data?: ISuggestedAddressDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
  init(data?: any) {
    if (data) {
      this.street = data["Street"];
      this.suite = data["Suite"];
      this.city = data["City"];
      this.county = data["County"];
      this.state = data["State"];
      this.country = data["Country"];
      this.zip = data["Zip"];
    }
  }
  static fromJS(data: any): SuggestedAddressDTO {
    data = typeof data === 'object' ? data : {};
    let result = new SuggestedAddressDTO();
    result.init(data);
    return result;
  }
  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["Street"] = this.street;
    data["Suite"] = this.suite;
    data["City"] = this.city;
    data["County"] = this.county;
    data["State"] = this.state;
    data["Country"] = this.country;
    data["Zip"] = this.zip;
    return data;
  }
}
export interface ISuggestedAddressDTO {
  street?: string | undefined;
  suite?: string | undefined;
  city?: string | undefined;
  county?: string | undefined;
  state?: string | undefined;
  country?: string | undefined;
  zip?: string | undefined;
}

export enum ResponseContentDTOResponseCode {
  Success = <any>"Success",
  No_Matching_GCID = <any>"No_Matching_GCID",
  GCID_Missing_Incorrectly_Spelled = <any>"GCID_Missing_Incorrectly_Spelled",
  Trillium_Service_exception = <any>"Trillium_Service_exception",
  GCDM_Service_Exception = <any>"GCDM_Service_Exception",
  Invalid_Requested_Address_Profile_Not_Updated = <any>"Invalid_Requested_Address_Profile_Not_Updated",
  Address_Suggestion_Found_Profile_Not_Updated = <any>"Address_Suggestion_Found_Profile_Not_Updated",
}
