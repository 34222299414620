import { Environment } from "../enums";
import { AppTileModel } from "../app-tile.model"

export class EnvironmentConfig {
    ENV!: Environment;
    PRODUCTION!: boolean;
    GATEWAY_API_BASE_URL!: string;
    CONSOLE_LOGGING_ENABLED!: boolean;
    APP_LOGGING_ENABLED!: boolean;
    SSO_REDIRECT_URL!: string;
    LOGIN_URL!: string;
    LOGOUT_URL!: string;
    BASE_URL!: string;
    PROFILE_URL!: string;
    IDLE_SESSION!: number;
    TIME_OUT_SESSION!: number;
    GLOBAL_NAV_DOMAIN_URL!: string;
    PRIVACY_POLICY_URL!: string;
    MY_DOMAIN_URL!: string;
    CONTACT_US!: string;
    CLIENT_CONFIG!: AppTileModel[];
    ANALYTICS_CONFIG?: AnalyticsConfig;
    EPAAS_SCRIPT_URL?: string;
    TOP_HEADER_NAVIGATION_CONFIG?: TopHeaderNavigationConfig;
    FOOTER_NAVIGATION_CONFIG?: FooterNavigationConfig;
    DEVICE_TOKEN_URL!: string;
    MYPROFILE_URL!: string;
}

export interface AnalyticsConfig {
    BASE_DOMAIN: string;
    CUSTOM_LINK_TRACKING: boolean;
    DATALAYER_NAME: string;
    DEBUG: boolean;
    EMBED_SCRIPT_URL: string;
    IS_MOBILE?: boolean;
    SUB_DOMAIN: string;
    SITE_NAME: string;
}

export class TopHeaderNavigationConfig {
    HOME_URL!: string;
    MY_GARAGE_URL!: string;
    MY_ACCOUNT_URL!: string;
    MY_PROFILE_URL!: string;
    LOG_OUT_MY_BMW_URL!: string;
  }
  
  export class FooterNavigationConfig {
    TERMS_URL!: string;
    FAQ_URL!: string;
  }