import { Injectable } from '@angular/core';
import { FSA } from 'flux-standard-action';
import { BooleanModel } from 'projects/_shared/models/boolean.model';
import { Reducer } from 'redux';
import { tassign } from 'tassign';


export class AdobeAnalyticsActions
{
  static readonly SET_ADOBE_ANALYTICS_ENABLED: string = 'SET_ADOBE_ANALYTICS_ENABLED';


  setAdobeAnalyticsEnabled(enabled: boolean): FSA<string, boolean, null>{
        return {
          type: AdobeAnalyticsActions.SET_ADOBE_ANALYTICS_ENABLED,
          payload: enabled,
            meta: null
        };
    }

}

@Injectable()
export class AdobeAnalyticsReducers
{
  static readonly AdobeAnalyticsEnabledReducer: Reducer<BooleanModel> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
    if(action.type == AdobeAnalyticsActions.SET_ADOBE_ANALYTICS_ENABLED)
      return tassign(state, new BooleanModel(action.payload ?? false));

    else
      return state;
    }
}