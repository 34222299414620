import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { FSA, isError, isFSA } from 'flux-standard-action';
import { tassign } from 'tassign';
import { BooleanModel } from 'projects/_shared/models/boolean.model';

export class DataLoadedActions
{
    static SET_DATALOADED: string = 'SET_DATALOADED';

    setDataLoaded(dataLoaded: boolean): FSA<string, boolean, null>{
        return {
            type: DataLoadedActions.SET_DATALOADED,
            payload: dataLoaded,
            meta: null
        };
    }
}

@Injectable()
export class DataLoadedReducers
{
    static DataLoadedReducer: Reducer<BooleanModel | undefined, FSA<string, boolean,void>> = (state: BooleanModel = new BooleanModel(true), action: FSA<string, boolean, void>) => {
        switch(action.type){
            case DataLoadedActions.SET_DATALOADED:
                return tassign(state, new BooleanModel(action.payload as boolean));
            default:
                return state;
        }
    }
}