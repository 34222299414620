import { mergeMap, catchError } from 'rxjs/operators';

import { Observable, of, throwError } from 'rxjs';
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { API_BASE_URL, blobToText, throwException } from '../helper/api-helper';

export enum Anonymous {
  LogAlways = <any>"LogAlways",
  Critical = <any>"Critical",
  Error = <any>"Error",
  Warning = <any>"Warning",
  Informational = <any>"Informational",
  Verbose = <any>"Verbose",
}

export enum ClientLogRequestLevel {
  LogAlways = <any>"LogAlways",
  Critical = <any>"Critical",
  Error = <any>"Error",
  Warning = <any>"Warning",
  Informational = <any>"Informational",
  Verbose = <any>"Verbose",
}

export interface IClientLogWriterClient {
  /**
   * @return OK
   */
  beginNewActivity(request: BeginActivityRequest): Observable<string>;
  /**
   * @return No Content
   */
  log(request: ClientLogRequest): Observable<void>;
  /**
   * @return OK
   */
  currentLevel(): Observable<Anonymous>;
}

export interface IBeginActivityRequest {
  name: string | undefined;
  activityId: string | undefined;
  relatedActivityId: string | undefined;
  seqNo: number | undefined;
  service: string | undefined;
  url: string | undefined;
}

export class BeginActivityRequest implements IBeginActivityRequest {
  name: string | undefined;
  activityId: string | undefined;
  relatedActivityId: string | undefined;
  seqNo: number | undefined;
  service: string | undefined;
  url: string | undefined;

  constructor(data?: IBeginActivityRequest) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data["Name"];
      this.activityId = data["ActivityId"];
      this.relatedActivityId = data["RelatedActivityId"];
      this.seqNo = data["SeqNo"];
      this.service = data["Service"];
      this.url = data["Url"];
    }
  }

  static fromJS(data: any): BeginActivityRequest {
    data = typeof data === 'object' ? data : {};
    let result = new BeginActivityRequest();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["Name"] = this.name;
    data["ActivityId"] = this.activityId;
    data["RelatedActivityId"] = this.relatedActivityId;
    data["SeqNo"] = this.seqNo;
    data["Service"] = this.service;
    data["Url"] = this.url;
    return data;
  }
}

@Injectable()
export class ClientLogWriterClient implements IClientLogWriterClient {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : "https:///GatewayApiServices";
  }

  /**
   * @return OK
   */
  beginNewActivity(request: BeginActivityRequest): Observable<string> {
    let url_ = this.baseUrl + "/api/ClientLogWriter/BeginNewActivity";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(request);

    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };

    return this.http.request("post", url_, options_).pipe(mergeMap((response_: any) => {
      return this.processBeginNewActivity(response_);
    }),
      catchError((response_: any) => {
        if (response_ instanceof HttpResponseBase) {
          try {
            return this.processBeginNewActivity(<any>response_);
          } catch (e) {
            return <Observable<string>><any>throwError(()=>e);
          }
        } else {
          return <Observable<string>><any>throwError(()=>response_);
        }
      }));
  }

  protected processBeginNewActivity(response: HttpResponseBase): Observable<string> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = resultData200 !== undefined ? resultData200 : <any>null;
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of<string>(<any>null);
  }

  /**
   * @return No Content
   */
  log(request: ClientLogRequest): Observable<void> {
    let url_ = this.baseUrl + "/api/ClientLogWriter/Log";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(request);

    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      })
    };

    return this.http.request("post", url_, options_).pipe(mergeMap((response_: any) => {
      return this.processLog(response_);
    }), catchError((response_: any) => {
        if (response_ instanceof HttpResponseBase) {
          try {
            return this.processLog(<any>response_);
          } catch (e) {
            return <Observable<void>><any>throwError(()=>e);
          }
        } else {
          return <Observable<void>><any>throwError(()=>response_);
        }
    }));
  }

  protected processLog(response: HttpResponseBase): Observable<void> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
    if (status === 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return of<void>(<any>null);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of<void>(<any>null);
  }

  /**
   * @return OK
   */
  currentLevel(): Observable<Anonymous> {
    let url_ = this.baseUrl + "/api/ClientLogWriter/CurrentLevel";
    url_ = url_.replace(/[?&]$/, "");

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };

    return this.http.request("get", url_, options_).pipe(mergeMap((response_: any) => {
      return this.processCurrentLevel(response_);
    }), catchError((response_: any) => {
        if (response_ instanceof HttpResponseBase) {
          try {
            return this.processCurrentLevel(<any>response_);
          } catch (e) {
            return <Observable<Anonymous>><any>throwError(()=>e);
          }
        } else {
          return <Observable<Anonymous>><any>throwError(()=>response_);
        }
    }));
  }

  protected processCurrentLevel(response: HttpResponseBase): Observable<Anonymous> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = resultData200 !== undefined ? resultData200 : <any>null;
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of<Anonymous>(<any>null);
  }
}

export class ClientLogRequest implements IClientLogRequest {
  level: ClientLogRequestLevel | undefined;
  activityId: string | undefined;
  seqNo: number | undefined;
  service: string | undefined;
  url: string | undefined;
  arguments: any[] | undefined;

  constructor(data?: IClientLogRequest) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.level = data["Level"];
      this.activityId = data["ActivityId"];
      this.seqNo = data["SeqNo"];
      this.service = data["Service"];
      this.url = data["Url"];
      if (data["Arguments"] && data["Arguments"].constructor === Array) {
        this.arguments = [];
        for (let item of data["Arguments"])
          this.arguments.push(item);
      }
    }
  }

  static fromJS(data: any): ClientLogRequest {
    data = typeof data === 'object' ? data : {};
    let result = new ClientLogRequest();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["Level"] = this.level;
    data["ActivityId"] = this.activityId;
    data["SeqNo"] = this.seqNo;
    data["Service"] = this.service;
    data["Url"] = this.url;
    if (this.arguments && this.arguments.constructor === Array) {
      data["Arguments"] = [];
      for (let item of this.arguments)
        data["Arguments"].push(item);
    }
    return data;
  }
}

export interface IClientLogRequest {
  level: ClientLogRequestLevel | undefined;
  activityId: string | undefined;
  seqNo: number | undefined;
  service: string | undefined;
  url: string | undefined;
  arguments: any[] | undefined;
}
