import { Component, OnDestroy, OnInit } from '@angular/core';
import { applicationConfig } from '../environments/application';
import { environment } from '../environments/environment';
import { UserService } from '../../../_shared/shared/helper-service/user.service';
import { Router, NavigationEnd } from '@angular/router';
import { IAppState } from '../../../_shared/shared/store/app.store';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { SessionTimeoutModel } from 'projects/_shared/shared/models/sessionTimeout.model';
import { Configuration } from 'projects/_shared/shared/models/configuration.model';
import { SessionTimeoutService } from 'projects/_shared/shared/helper-service/session-timeout.service';
import { AnalyticsService, TimeoutService } from 'customerdigital-service-lib';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  sessionTimeoutConfig!:SessionTimeoutModel;
  idleTime!:number;
  timeoutTime!:number;
  configuration!: Configuration;
  previousUrl: string = "";
  currentUrl: string = "";
  routerEventSubscribe: any;
  dataloaded: boolean = false;
  dataloadedSubScription: Subscription = new Subscription();

  constructor(private userService: UserService,
    private timeoutService: TimeoutService,
    private sessionTimeoutService:SessionTimeoutService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private store: Store<IAppState>) {
    this.dataloadedSubScription = this.store.select(state => state.DataLoaded)
      .subscribe(state => {
        this.dataloaded = state!;
      });
  }

  public brand = applicationConfig.BRAND;
  public country = applicationConfig.COUNTRY;
  public environment = environment.ENV;
  public title = applicationConfig.TITLE;

  ngOnInit(): void {
    this.store.select(x=> x.EnvironmentConfig?.IDLE_SESSION).subscribe(s=> this.idleTime = s ?? 0)
    this.store.select(x=> x.EnvironmentConfig?.TIME_OUT_SESSION).subscribe(s=> this.timeoutTime = s ?? 0)
    this.sessionTimeoutService.handleIdleSessionTimeOut();
    // Code To start ngIdle service after sso authentication completed
    this.routerEventSubscribe = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {

        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        if (this.userService.isAuthenticated()) {
          this.timeoutService.initIdle(this.idleTime * 60,this.timeoutTime * 60);
          this.routerEventSubscribe.unsubscribe();
        }
      }
    });

    //Initialize the Analytics Service Methods
    this.analyticsService.init(this.router, true);
  }

  public get isAuthenticated(): boolean {
    return this.userService.isAuthenticated();
  }

  ngOnDestroy(): void {
    this.dataloadedSubScription.unsubscribe();
  }
}
