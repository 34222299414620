import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from 'projects/_shared/shared/helper-service/user.service';

@Injectable()
export class BearerTokenInterceptor implements HttpInterceptor {
  constructor(private userService: UserService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

     if (this.userService.fsTokenExists()) {
          request = request.clone({
            setHeaders: {
              'X-BMW-BEARER-TOKEN':  this.userService.getFsToken()
            }
          });
     }
    
    return next.handle(request);
  }
}