import { Component, OnInit, OnDestroy, Inject,  ViewChild, HostListener, ViewEncapsulation} from '@angular/core';
import { UserService } from '../../../../_shared/shared/helper-service/user.service';
import { GlobalNavigationService } from '../../../../_shared/shared/helper-service/global-navigation.service';
import { AuthService } from 'projects/_shared/web-service/auth.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { DOCUMENT } from '@angular/common';
import {LoginService} from 'customerdigital-service-lib';

@Component({
  selector: 'app-header',
  templateUrl: "./header.component.html",
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HeaderComponent implements OnInit, OnDestroy {
  public navIsFixed!: boolean;
  private hasLoggedInStateSubscription! : any;
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;  

  constructor(private userService: UserService,
    private globalNavigationService: GlobalNavigationService,
    private authService: AuthService,
    private loginService : LoginService,
    @Inject(DOCUMENT) private document: Document) {

  }

  ngOnInit() {
    this.globalNavigationService.initializeGlobalNavigation();
    this.hasLoggedInStateSubscription = this.loginService.hasLoggedInState.subscribe( () => {
          this.globalNavigationService.initializeGlobalNavigationAfterLogin();
      });
  }

  ngOnDestroy(){
    if(this.hasLoggedInStateSubscription){
      this.hasLoggedInStateSubscription.unsubscribe();
    }
  }

  @HostListener("window:scroll", ['$event'])
  onWindowScroll() {
    if (this.trigger) {
      this.trigger.closeMenu();
    }
    var navbar = document.getElementById("navbar");
    if (navbar) {
      var sticky = navbar.offsetTop;

      let position = window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
      if (position > sticky) {
        this.navIsFixed = true;
      } else {
        this.navIsFixed = false;
      }
    }
  }
   
}
