import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterService } from '../shared/helper-service/router.service';
import { LanguageService } from './helper-service/language.service';
import { GuardService } from './helper-service/guard.service';
import { LoggerHelperService } from './helper-service/logger-helper.service';
import { SessionTimeoutDialogService } from './helper-service/session-timout-dialog.service';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerComponent } from '../components/spinner/spinner.component';
import { WindowHelperService } from './helper-service/window-helper.service';
import { EpaasHelperService } from './helper-service/epaas-helper.service';
import { FeatureIndicatorActions } from './store/reducers/feature-indicator.reducers';
import { FeatureIndicatorService } from '../web-service/feature-indicator.service';
import { FeatureIndicatorServiceClient } from '../core/gatewayapi/feature-indicator-service.api';
import { PartnerIntegrationService } from '../web-service/partner-integration.service';
import { PartnerIntegrationServiceClient } from '../core/gatewayapi/partner-integration.service.api';
import { ErrorComponent } from '../components/error/error.component';
import { SharedRoutingModule } from './shared-routing.module';
import { EpaasErrorComponent } from '../components/epaas-error/epaas-error.component';
import { AdobeAnalyticsActions } from './store/reducers/adobe-analytics.reducer';

@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    SharedRoutingModule
  ],
  exports:[
    SpinnerComponent,
    ErrorComponent,
    EpaasErrorComponent
  ],
  declarations:[
    SpinnerComponent,
    ErrorComponent,
    EpaasErrorComponent    
  ],
  providers: [
    RouterService,
    LanguageService,
    GuardService,
    LoggerHelperService,
    SessionTimeoutDialogService,
    WindowHelperService,
    AdobeAnalyticsActions,
    EpaasHelperService,
    FeatureIndicatorActions,
    FeatureIndicatorService,
    FeatureIndicatorServiceClient,
    PartnerIntegrationService,
    PartnerIntegrationServiceClient 
  ]

})

export class SharedModule {

}
