import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { FSA, isError, isFSA } from 'flux-standard-action';
import { tassign } from 'tassign';
import { FAQModel } from 'projects/_shared/models/faq.model';

export class FaqActions
{
  static ADD_FAQ: string = 'ADD_FAQ';
  static CLEAR_FAQ: string = 'CLEAR_FAQ';

  addFAQ(faq: FAQModel): FSA<string, FAQModel, null>{
    return {
      type: FaqActions.ADD_FAQ,
      payload: faq,
      meta: null
    };
  }

  clearFAQ(): FSA<string, FAQModel, null> {
      return {
          type: FaqActions.CLEAR_FAQ,
          payload: new FAQModel([], false),
          meta: null
      };
  }
}

@Injectable()
export class FaqReducers
{
  static FaqReducer: Reducer<FAQModel | undefined, FSA<string, FAQModel, void>> = (state: FAQModel=new FAQModel([], false), action: FSA<string, FAQModel, void>) => {
      switch(action.type){
    case FaqActions.ADD_FAQ:
      return tassign(state,action.payload);
    case FaqActions.CLEAR_FAQ:
      return tassign(undefined);
    default:
      return state;
    }
  }
}
