import { Injectable } from '@angular/core';
import { AccessibilityHelperService, ISessionTimeoutDialog } from 'customerdigital-service-lib';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DialogModel, DialogService, DialogResponseModel } from 'customerdigital-ui-lib';
import { UserIsTimedOutActions } from '../store/reducers/user-is-timed-out.reducer';
import { UserInactivityDialogCloseActions } from '../store/reducers/user-inactivity-dialog-close.reducer';
import { IAppState } from '../store/app.store';
import { RouterService } from './router.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';


@Injectable()
export class SessionTimeoutDialogService implements ISessionTimeoutDialog {

  constructor(private dialogService: DialogService,
    private userIsTimedOutActions: UserIsTimedOutActions,
    private userInactivityDialogCloseActions: UserInactivityDialogCloseActions,
    private store: Store<IAppState>,
    private routerService: RouterService,
    private translateService: TranslateService,
    private a11yHelperService: AccessibilityHelperService,
  ) {

  }

  dialogModel: DialogModel = new DialogModel();
  dialogRef: any = null;
  timeoutWarningDialogIsShown: boolean = false;
  public stayLoggedInSubj = new BehaviorSubject<boolean>(false);

  public closeDialogBeforeTimeout(): void {
    this.dialogService.closeDialogsBeforeTimeout()
  }
  public initializedTimeOutDialog(isTimeout: boolean): void {
    this.store.dispatch(this.userInactivityDialogCloseActions.setUserInactivityDialogCloseActions(false));
  }
  public getUserInactivityDialogClose(): Observable<boolean> {
    return this.stayLoggedInSubj;    
  }

  public dispatchTimeOutAction(isTimeout: boolean): void {
    this.store.dispatch(this.userIsTimedOutActions.setUserIsTimedOut(isTimeout))
  }

  private setTimeOutDialogModel() {
    this.translateService.get('time-out.title').subscribe(res => {
      this.dialogModel.title = res;
    })
    this.translateService.get('time-out.body').subscribe(res => {
      this.dialogModel.body = res;
    })
    this.translateService.get('time-out.confirmBtnText').subscribe(res => {
      this.dialogModel.confirmBtnText = res;
    })
    this.translateService.get('time-out.cancelBtnText').subscribe(res => {
      this.dialogModel.cancelBtnText = res;
    })
    this.translateService.get('time-out.aria-label').subscribe(res => {
      this.dialogModel.ariaLabel = res;
    })
    this.translateService.get('time-out.confirm-aria-label').subscribe(res => {
      this.dialogModel.confirmAriaLabel = res;
    })
    this.translateService.get('time-out.cancel-aria-label').subscribe(res => {
      this.dialogModel.cancelAriaLabel = res;
    })
    this.dialogModel.confirmBtnEnabled = true;
    this.dialogModel.confirmBtnPrimary = true;
    this.dialogModel.id = "sessionTimeOutDialog";
  }
  
  public toggleWarningIdleDialog(showDialog: boolean) {
    if (showDialog) {
      this.setTimeOutDialogModel();
      this.dialogRef = this.dialogService.openDialog(this.dialogModel);
      this.dialogRef.afterOpened().subscribe(() => {
      this.a11yHelperService.clearAriaHiddenFromBackground('app-root');
      this.a11yHelperService.clearAriaHiddenFromBackground('div.cdk-visually-hidden');
        
      });
      this.dialogRef.afterClosed().subscribe((value: DialogResponseModel) => {
        if (value.confirmed !== true) {
          this.routerService.navigateToSsoLogout();
        }
        this.store.dispatch(this.userInactivityDialogCloseActions.setUserInactivityDialogCloseActions(value.confirmed));
        this.stayLoggedInSubj.next(value.confirmed);      
  });
    }
    else {
      this.dialogService.closeDialog(new DialogResponseModel(false));
    }
  }
}
