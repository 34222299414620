import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { RouterService } from 'projects/_shared/shared/helper-service/router.service';
import { TranslateService } from '@ngx-translate/core';
import { IAppState } from "projects/_shared/shared/store/app.store";
import { GlobalErrorActions } from 'projects/_shared/shared/store/reducers/global-error-message.reducer';
import { Store } from '@ngrx/store';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

    constructor(private injector: Injector,
        private routerService: RouterService,
        private store: Store<IAppState>,
        private globalErrorActions: GlobalErrorActions,
        private translateService: TranslateService) {
        super();
    }

    public handleError(error: any): void {
        if (this.translateService == undefined) { this.translateService }
        if (this.routerService == undefined) { this.routerService }
        console.log(error);

        this.store.dispatch(this.globalErrorActions.setGlobalError(""));
        if (error instanceof UnknownException) {
            // Call the camber service for logging.
            this.translateService.get("global.technical-error").subscribe((res: string) => {
                this.store.dispatch(this.globalErrorActions.setGlobalError(res));
            });
            this.routerService.navigateToErrorPage();
        }
        else {
            // Call the camber service for logging.
            this.store.dispatch(this.globalErrorActions.setGlobalError(`${error.message} \n ${error.stack}`));
            this.routerService.navigateToErrorPage();
        }
    }
}

export class UnknownException {

}
