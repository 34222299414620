import { StringModel } from '../../models/string.model';
import { LanguageReducers } from './reducers/language.reducer';
import { ApplicationConfig } from 'projects/_shared/models/configuration/application.config';
import { EnvironmentConfig } from 'projects/_shared/models/configuration/environment.config';
import { ApplicationConfigReducers } from './reducers/application-config.reducer';
import { EnvironmentConfigReducers } from './reducers/environment-config.reducer';
import { ParentActivityIdReducers } from './reducers/parent-activity-id.reducer';
import { UserActivityIdReducers } from './reducers/user-activityid.reducer';
import { CustomerNaContactReducers } from './reducers/contact-na.reducer';
import { CustomerContact } from '../../models/customer-data.model';
import { FaqReducers } from './reducers/faq.reducers';
import { FAQModel } from 'projects/_shared/models/faq.model';
import { UserInactivityDialogCloseReducers } from './reducers/user-inactivity-dialog-close.reducer';
import { UserIsTimedOutReducers } from './reducers/user-is-timed-out.reducer';
import { IndicatorReducers } from './reducers/indicator.reducer';
import { IsEpassInitializedReducers } from './reducers/is-epaas-initialized.reducer';
import { FeatureIndicatorReducers } from './reducers/feature-indicator.reducers';
import { CannotAuthenticateReducers } from './reducers/cannot-authenticate.reducer';
import { BooleanModel } from 'projects/_shared/models/boolean.model';
import { ContactDTO, ContractAccountDetailDTO } from 'projects/_shared/core/gatewayapi/auth-service.api';
import { ContractAccountDetailsReducers } from './reducers/contract-account-details.reducers';
import { ContractAccountDetailReducers } from './reducers/contract-account-detail.reducers';
import { ContactReducers } from './reducers/contact.reducers';
import { GlobalErrorReducers } from './reducers/global-error-message.reducer';
import { ActionReducerMap } from '@ngrx/store';

export interface IAppState {
  EnvironmentConfig: EnvironmentConfig | undefined;
  ApplicationConfig: ApplicationConfig | undefined;
  Language: StringModel | undefined;
  ParentActivityId: StringModel | undefined;
  UserActivityId: StringModel | undefined;
  CustomerContact: CustomerContact | undefined;
  FAQ: FAQModel | undefined;
  UserInactivityDialogClose: boolean | undefined;
  UserIsTimedOut: boolean | undefined;
  DataLoaded: boolean | undefined;
  IsEpassInitialized: BooleanModel | undefined,
  EPaasEnabled: BooleanModel | undefined,
  CannotAuthenticate: BooleanModel | undefined
  ContractAccountDetails: ContractAccountDetailDTO[] | undefined;
  ContractAccountDetail: ContractAccountDetailDTO | undefined;
  Contact: ContactDTO | undefined;
  GlobalError: StringModel | undefined;
}

export const INITIAL_STATE: IAppState = {
  EnvironmentConfig: undefined,
  ApplicationConfig: undefined,
  Language: undefined,
  ParentActivityId: undefined,
  UserActivityId: undefined,
  FAQ: undefined,
  UserInactivityDialogClose: false,
  UserIsTimedOut: false,
  CustomerContact: undefined,
  DataLoaded: true,
  IsEpassInitialized: undefined,
  EPaasEnabled: undefined,
  CannotAuthenticate: undefined,
  ContractAccountDetails: undefined,
  ContractAccountDetail: undefined,
  Contact: undefined,
  GlobalError: undefined
}

export const rootReducer : ActionReducerMap<IAppState> ={
  EnvironmentConfig: EnvironmentConfigReducers.EnvironmentConfigReducer,
  ApplicationConfig: ApplicationConfigReducers.ApplicationConfigReducer,
  Language: LanguageReducers.LanguageReducer,
  ParentActivityId: ParentActivityIdReducers.ParentActivityIdReducer,
  UserActivityId: UserActivityIdReducers.UserActivityIdReducers,
  FAQ: FaqReducers.FaqReducer,
  UserInactivityDialogClose: UserInactivityDialogCloseReducers.UserInactivityDialogCloseReducer,
  UserIsTimedOut: UserIsTimedOutReducers.UserIsTimedOutReducer,
  CustomerContact: CustomerNaContactReducers.CustomerNaContactReducer,
  DataLoaded: IndicatorReducers.DataLoadedReducer,
  IsEpassInitialized: IsEpassInitializedReducers.IsEpassInitializedReducer,
  EPaasEnabled: FeatureIndicatorReducers.EPaasEnabledReducer,
  CannotAuthenticate: CannotAuthenticateReducers.CannotAuthenticateReducer,
  ContractAccountDetails: ContractAccountDetailsReducers.ContractAccountDetailsReducer,
  ContractAccountDetail: ContractAccountDetailReducers.ContractAccountDetailReducer,
  Contact: ContactReducers.ContactReducer,
  GlobalError: GlobalErrorReducers.GlobalErrorReducer
};
