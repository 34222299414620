import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SsoComponent, SsoLogoutComponent } from 'customerdigital-service-lib';
import { route } from 'projects/_shared/shared/helper-service/router.service';
import { TermsAcceptedGuard } from 'projects/_shared/_guards/terms-accepted.guard';
import { AuthGuard } from 'projects/_shared/_guards/auth.guard';

const routes: Routes = [
  { path: "", pathMatch: 'full', redirectTo: route.home },
  {
    path: route.home, loadChildren: () => import('../../../_shared/components/home/home.module').then(m => m.HomeModule),
    canActivate: [TermsAcceptedGuard, AuthGuard]
  },
  {
    path: route.sso, component:SsoComponent
  },
  {
    path: route.ssoLogout, component: SsoLogoutComponent
  },
  {
    path: route.faq, loadChildren: () => import('../app/faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: route.myprofile, loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfileModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })
  
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
