import { Injectable } from '@angular/core';
import { StorageService, StorageType } from 'customerdigital-service-lib';
import { ApplicationConfigActions } from '../store/reducers/application-config.reducer';
import { EnvironmentConfigActions } from '../store/reducers/environment-config.reducer';
import { EnvironmentConfig } from 'projects/_shared/models/configuration/environment.config';
import { ApplicationConfig } from 'projects/_shared/models/configuration/application.config';
import { IAppState } from '../store/app.store';
import { Constants } from '../constants';
import { GcdmLinkedAccountDTO } from 'customerdigital-service-lib/lib/_core/login-service-gateway-api';
import { LinkedAccount } from 'projects/_shared/models/user.model';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';


@Injectable({ providedIn: 'root' })
export class UserService {
  private currentUser: any | null | undefined;

  constructor(private storageService: StorageService,
    private applicationConfigActions: ApplicationConfigActions,
    private environmentConfigActions: EnvironmentConfigActions,
    private store: Store<IAppState>) { }

  public getUserFromSession(): any | null {
    if (this.store.select(state => state.ApplicationConfig)) {
      let storageType: number | undefined;
      this.store.select(x=> x.ApplicationConfig?.STORAGE_TYPE).subscribe(s=> storageType = s)

      let sessionValue = this.storageService.getItem(Constants.User_Data, storageType!);

      if (sessionValue) {

        let sessionUser: any = JSON.parse(sessionValue);

        if (sessionUser) {
          // logged in so return true
          return sessionUser;
        }
      }
      return null;
    }
      return null;
    }

  public getFsToken(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser && this.currentUser.fsToken;
    } else
      return "";
  }

  public setCustomerNumber(customerNumber: number): void {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      this.currentUser.customerNumber = customerNumber;
      this.setCurrentUser(this.currentUser);
    }
  }

  public setAccountNumbers(accountNumbers: string[]): void {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      this.currentUser.accountNumbers = accountNumbers;
      this.setCurrentUser(this.currentUser);
    }
  }
  public getUserBearerToken(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return (this.currentUser.bearerToken);
    }
    else
      return "";
  }

  public getUserBearerTokenExpireTime(): number {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return (this.currentUser.tokenExpiresIn);
    }
    else
      return 0;
  }

  public getUserRefreshToken(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return (this.currentUser.refreshToken);
    }
    else
      return "";
  }

  public getGcid(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.gcid;
    }
    else
      return "";
  }

  public getDeviceToken(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.deviceToken;
    }
    else
      return "";
  }

  public getUserName(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.userName;
    }
    else
      return "";
  }

  public getFirstName(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.firstName;
    }
    else
      return "";
  }

  public getLastName(): string {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.lastName;
    }
    else
      return "";
  }

  isAuthenticated(): boolean {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      return this.currentUser.isAuthenticatedUser;
    }
    else
      return false;
  }

  public setEnvironmentConfig(_environmentConfig: EnvironmentConfig) {
   this.store.dispatch(this.environmentConfigActions.setEnvironmentConfig(_environmentConfig));
  }

  public setApplicationConfig(_applicationConfig: ApplicationConfig) {
    this.store.dispatch(this.applicationConfigActions.setApplicationConfig(_applicationConfig));
  }

  public resetBearerToken(accessToken: string, tokenExpiresIn: number, refreshToken: string): void {
    this.currentUser = this.getUserFromSession();
    this.currentUser!.bearerToken = accessToken;
    this.currentUser!.tokenExpiresIn = tokenExpiresIn;
    this.currentUser!.refreshToken = refreshToken;
    this.setCurrentUser(this.currentUser);
  }

  setCurrentUser(data: any | null | undefined) {
    this.currentUser = data;
    let storageType: number | undefined;
    this.store.select(x=> x.ApplicationConfig?.STORAGE_TYPE).subscribe(s=> storageType = s)
    this.storageService.setItem(Constants.User_Data, JSON.stringify(this.currentUser), storageType!);
  }

  public setTermsAccepted(accepted: boolean): void {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      this.currentUser.isTermsAccepted = accepted;
      this.setCurrentUser(this.currentUser);
    }
  }

  clearSession() {
    this.currentUser = null;
    this.storageService.removeItem(Constants.USER_DATA, StorageType.session);
    this.storageService.removeItem(Constants.SESSION_ID, StorageType.session);
  }

  isFsLogin(): boolean {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return (this.currentUser.isFsLogin && this.currentUser.isAuthenticatedUser);
    } else
      return false;
  }

  isTermsAccepted(): boolean {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.isTermsAccepted;
    }
    else
      return false;
  }

  public isHomeAccess(): boolean {
    return this.isAuthenticated();
  }
  public isGroupIdExists(): boolean {
    this.currentUser = this.getUserFromSession();
    return (this.currentUser && this.currentUser.linkedAccounts != undefined && this.currentUser.linkedAccounts.length > 0);
  }

  public setLinkedAccount(linkedAccounts: GcdmLinkedAccountDTO[]) {
    if (linkedAccounts && linkedAccounts.length) {
      this.currentUser = this.getUserFromSession();
      this.currentUser.linkedAccounts = [];

      _.forEach(linkedAccounts, (item: GcdmLinkedAccountDTO) => {
        var linkedAccount = new LinkedAccount();
        linkedAccount.groupId = item!.accountId!;
        linkedAccount.groupIdType = item.accountType!;
        this.currentUser.linkedAccounts.push(linkedAccount);
      })

      this.setCurrentUser(this.currentUser);
    }
  }


  public getGroupId(): string | undefined {

    this.currentUser = this.getUserFromSession();

    if (this.currentUser && this.currentUser.linkedAccounts) {

      var accounts = this.currentUser.linkedAccounts ? this.currentUser.linkedAccounts.filter((item: LinkedAccount) => item.groupIdType === Constants.TID) : [];
      var account = accounts.length < 1 ? null : accounts[0];
      if (account != null) {
        if (account.groupId.substring(0, 2).toUpperCase() === "US")
          return account.groupId.substring(2);
      }
      return undefined;
    }
    else
      return "";
  }

  public getCustomerNumber(): number | null {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.customerNumber;
    } else
      return null;
  }

  public fsTokenExists(): boolean {
    this.currentUser = this.getUserFromSession();
    return this.currentUser && this.currentUser.fsToken !== "" && this.currentUser.fsToken !== undefined && this.currentUser.fsToken !== null;
  }

  public getAccountNumbers(): string[] {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.accountNumbers;
    } else {
      return [];
    }
  }

  public setDeviceTokenChecked(checked: boolean, deviceToken: string): void {
    this.currentUser = this.getUserFromSession();
    this.currentUser.deviceTokenChecked = checked;
    this.currentUser.deviceToken = deviceToken;
    this.setCurrentUser(this.currentUser);
  }

  public customerDeviceTokenExists(): boolean {
    this.currentUser = this.getUserFromSession();

    if (this.currentUser) {
      return this.currentUser.deviceTokenChecked;
    } else {
      return false;
    }
  }

  public setFs(fsToken: string): void {
    this.currentUser = this.getUserFromSession();
    if (this.currentUser) {
      this.currentUser.fsToken = fsToken;
      this.setCurrentUser(this.currentUser);
    }
  }

}
