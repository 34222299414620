import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { FSA, isError, isFSA } from 'flux-standard-action';

import { tassign } from 'tassign';


export class UserIsTimedOutActions
{
    static SET_USER_IS_TIMED_OUT: string = 'SET_USER_IS_TIMED_OUT';

    setUserIsTimedOut(userIsTimedOut: boolean): FSA<string, boolean, null>{
        return {
            type: UserIsTimedOutActions.SET_USER_IS_TIMED_OUT,
            payload: userIsTimedOut,
            meta: null
        };
    }

}

@Injectable()
export class UserIsTimedOutReducers
{
  static UserIsTimedOutReducer: Reducer<boolean | undefined, FSA<string, boolean, void>> = (state: boolean = false, action: FSA<string, boolean, void>) => {
        switch(action.type){
            case UserIsTimedOutActions.SET_USER_IS_TIMED_OUT:
                return tassign(state, action.payload);
            default:
                return state;
        }
    }
}
