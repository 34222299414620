
export class PageItem {
  text: string;
  isActive: boolean;

  constructor(text?: string, isActive?: boolean) {
    this.text = text ? text : "";
    this.isActive = isActive ? isActive : false;
  }
}

