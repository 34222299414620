<div class="page-path-not-found">
  <div class="header-image"></div>
  <div class="path-not-found-container">
      <h1>
        {{'page-not-found.not-available-msg' | translate}}
      </h1>
      <div class="try-again"><p>{{'page-not-found.try-again-msg' | translate}}</p></div>
      <div>
          <a class="return-home" id="goHomeBtn" (click)="navigateToBaseURL()">{{'page-not-found.btn-home' | translate}}</a>
      </div>
    </div>
  </div>