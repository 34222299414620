<div>
  <div class="sub-head-title">
    <div class="title">{{'global.title' | translate}}</div>
    <div class="mobile-sub-head-menu no-print" *ngIf="isHomeAccess()">
      <button mat-button [matMenuTriggerFor]="menu">
        <span *ngIf="currentPage">{{currentPage.text}}</span>
        <span class="material-icons">expand_more</span>
      </button>
      <mat-menu #menu="matMenu" [overlapTrigger]="false" >
        <div *ngFor="let page of pages; let i = index">
          <button mat-menu-item (click)="setActivePage(i)">
            <span>{{page.text}}</span>
          </button>
        </div> 
      </mat-menu>
    </div>
  </div>
  <div>
    <ul class="nav no-print" *ngIf="isHomeAccess()">
      <li [ngClass]="{'active': isHomeActive}">
        <a (click)="navigateToMyHome()" id="homeBtn">{{'shared.sub-nav-header.lbl-my-home' | translate}}</a>
      </li>
      <li [ngClass]="{'active': isGarageActive}">
        <a (click)="navigateToMyGarage()" id="dashboardBtn">{{'shared.sub-nav-header.lbl-my-garage' | translate}}</a>
      </li>
      <li [ngClass]="{'active': isAccountActive}">
        <a (click)="navigateToMyAccount()" id="accountsBtn">{{'shared.sub-nav-header.lbl-my-account' | translate}}</a>
      </li>
      <li [ngClass]="{'active': isProfileActive}">
        <a (click)="navigateToMyProfile()" id="profileBtn">{{'shared.sub-nav-header.lbl-my-profile' | translate}}</a>
      </li>
      <li [ngClass]="{'active': isBMWLoginActive}">
        <a (click)="navigateToDashboard()" id="bmwloginBtn">{{'shared.sub-nav-header.lbl-bmw-login' | translate}}</a>
      </li>
    </ul>
    <a class="moveup-btn" (click)="scrollToTop()">
      <span _ngcontent-jlu-c133="" id="moveUpBtn" class="material-icons">arrow_upward</span>
    </a>
  </div>
</div>