export class GenerateFsTokenStatus {
  error: boolean | undefined;
  errortype: GenerateFsTokenStatusErrorType | undefined;
}

export enum GenerateFsTokenStatusErrorType {
  DataValidation,
  InvalidToken,
  TokenExpired,
  UserNotFound,
  UserAccountPasswordLocked,
  GenericServerError,
}