import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { FSA, isError, isFSA } from 'flux-standard-action';
import { EnvironmentConfig } from '../../../models/configuration/environment.config';

import { tassign } from 'tassign';


export class EnvironmentConfigActions {
  static SET_ENVIRONMENT_CONFIG: string = 'SET_ENVIRONMENT_CONFIG';

  setEnvironmentConfig(environmentConfig: EnvironmentConfig): FSA<string, EnvironmentConfig, null> {
    return {
      type: EnvironmentConfigActions.SET_ENVIRONMENT_CONFIG,
      payload: environmentConfig,
      meta: null
    };
  }

}

@Injectable()
export class EnvironmentConfigReducers {
    static EnvironmentConfigReducer: Reducer<EnvironmentConfig|undefined, FSA<string, EnvironmentConfig, null>> = (state: EnvironmentConfig = new EnvironmentConfig(), action: FSA<string, EnvironmentConfig, null>) => {
    switch (action.type) {
      case EnvironmentConfigActions.SET_ENVIRONMENT_CONFIG:
        return tassign(state, action.payload);
      default:
        return state;
    }
  }
}
