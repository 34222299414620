import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn, Router } from '@angular/router';
import { GuardService } from '../shared/helper-service/guard.service';
import { route } from '../shared/helper-service/router.service';
import { UserService } from '../shared/helper-service/user.service';

export const NotTermsGuard: CanActivateFn =  (activeRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      const userService = inject(UserService);
      const guardService = inject(GuardService);
      const router = inject(Router);

      guardService.setLanguage(state);

      if (!userService.isTermsAccepted())
            return guardService.guardSucceded(); 
      else 
            return guardService.guardFailed(route.baseUrl, false, activeRoute.params);
}