import { Injectable } from '@angular/core';

@Injectable()
export class WindowHelperService {

    constructor() { }

    public scrollToTop(): void {
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }

}