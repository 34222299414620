export interface IPartnerIntegrationServiceClient {
  /**
   * @return OK
   */
  getPartnerTokenByCustomerNumber(request: GetPartnerTokenByCustomerNumberRequest): Observable<GetPartnerTokenByCustomerNumberResponse>;
  /**
   * @return OK
   */
  getPartnerTokenByGcIdClientId(request: GetPartnerTokenByGcIdClientIdRequest): Observable<GetPartnerTokenByGcIdClientIdResponse>;
}
import { mergeMap, catchError } from 'rxjs/operators';

import { Observable, of, throwError } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpResponseBase, HttpErrorResponse } from '@angular/common/http';
import { API_BASE_URL, blobToText, throwException } from '../helper/api-helper';
import { Constants } from 'projects/_shared/shared/constants';


@Injectable()
export class PartnerIntegrationServiceClient implements IPartnerIntegrationServiceClient {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : "/GatewayApiServices";
  }

  /**
   * @return OK
   */
  getPartnerTokenByCustomerNumber(request: GetPartnerTokenByCustomerNumberRequest): Observable<GetPartnerTokenByCustomerNumberResponse> {
    let url_ = this.baseUrl + "/api/PartnerIntegrationService/GetPartnerTokenByCustomerNumber";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(request);

    let options_: any = {
      body: content_,
      observe: Constants.response,
      responseType: Constants.responseTypeBlob,
      headers: new HttpHeaders({
        "Content-Type": Constants.headerContentType,
        "Accept": Constants.headerAccept
      })
    };

    return this.http.request("post", url_, options_).pipe(mergeMap((response_: any) => {
      return this.processGetPartnerTokenByCustomerNumber(response_);
    }),catchError((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetPartnerTokenByCustomerNumber(<any>response_);
        } catch (e) {
          return <Observable<GetPartnerTokenByCustomerNumberResponse>><any>throwError(()=>e);
        }
      } else
        return <Observable<GetPartnerTokenByCustomerNumberResponse>><any>throwError(()=>response_);
    }));
  }

  protected processGetPartnerTokenByCustomerNumber(response: HttpResponseBase): Observable<GetPartnerTokenByCustomerNumberResponse> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = GetPartnerTokenByCustomerNumberResponse.fromJS(resultData200);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of<GetPartnerTokenByCustomerNumberResponse>(<any>null);
  }

  getPartnerTokenByGcIdClientId(request: GetPartnerTokenByGcIdClientIdRequest): Observable<GetPartnerTokenByGcIdClientIdResponse> {
    let url_ = this.baseUrl + "/api/PartnerIntegrationService/GetPartnerTokenByGcIdClientId";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(request);

    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };

    return this.http.request("post", url_, options_).pipe(mergeMap((response_: any) => {
      return this.processGetPartnerTokenByGcIdClientId(response_);
    }),catchError((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetPartnerTokenByGcIdClientId(<any>response_);
        } catch (e) {
          return <Observable<GetPartnerTokenByGcIdClientIdResponse>><any>throwError(()=>e)
        }
      } else
        return <Observable<GetPartnerTokenByGcIdClientIdResponse>><any>throwError(()=>response_);
    }));
  }

  protected processGetPartnerTokenByGcIdClientId(response: HttpResponseBase): Observable<GetPartnerTokenByGcIdClientIdResponse> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = GetPartnerTokenByGcIdClientIdResponse.fromJS(resultData200);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of<GetPartnerTokenByGcIdClientIdResponse>(<any>null);
  }
}

export class GetPartnerTokenByCustomerNumberRequest implements IGetPartnerTokenByCustomerNumberRequest {
  customerNumber?: number | undefined;
  integrationProduct?: GetPartnerTokenByCustomerNumberRequestIntegrationProduct | undefined;
  integrationProvider?: GetPartnerTokenByCustomerNumberRequestIntegrationProvider | undefined;
  region?: string | undefined;

  constructor(data?: IGetPartnerTokenByCustomerNumberRequest) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.customerNumber = _data["CustomerNumber"];
      this.integrationProduct = _data["IntegrationProduct"];
      this.integrationProvider = _data["IntegrationProvider"];
      this.region = _data["Region"];
    }
  }

  static fromJS(data: any): GetPartnerTokenByCustomerNumberRequest {
    data = typeof data === 'object' ? data : {};
    let result = new GetPartnerTokenByCustomerNumberRequest();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["CustomerNumber"] = this.customerNumber;
    data["IntegrationProduct"] = this.integrationProduct;
    data["IntegrationProvider"] = this.integrationProvider;
    data["Region"] = this.region;
    return data;
  }
}

export interface IGetPartnerTokenByCustomerNumberRequest {
  customerNumber?: number | undefined;
  integrationProduct?: GetPartnerTokenByCustomerNumberRequestIntegrationProduct | undefined;
  integrationProvider?: GetPartnerTokenByCustomerNumberRequestIntegrationProvider | undefined;
  region?: string | undefined;
}

export class GetPartnerTokenByCustomerNumberResponse implements IGetPartnerTokenByCustomerNumberResponse {
  integrationToken?: string | undefined;

  constructor(data?: IGetPartnerTokenByCustomerNumberResponse) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.integrationToken = _data["IntegrationToken"];
    }
  }

  static fromJS(data: any): GetPartnerTokenByCustomerNumberResponse {
    data = typeof data === 'object' ? data : {};
    let result = new GetPartnerTokenByCustomerNumberResponse();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["IntegrationToken"] = this.integrationToken;
    return data;
  }
}

export interface IGetPartnerTokenByCustomerNumberResponse {
  integrationToken?: string | undefined;
}

export class GetPartnerTokenByGcIdClientIdRequest implements IGetPartnerTokenByGcIdClientIdRequest {
  gcId?: string | undefined;
  clientId?: string | undefined;
  integrationProduct?: GetPartnerTokenByGcIdClientIdRequestIntegrationProduct | undefined;
  integrationProvider?: GetPartnerTokenByGcIdClientIdRequestIntegrationProvider | undefined;
  region?: string | undefined;

  constructor(data?: IGetPartnerTokenByGcIdClientIdRequest) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.gcId = _data["GcId"];
      this.clientId = _data["ClientId"];
      this.integrationProduct = _data["IntegrationProduct"];
      this.integrationProvider = _data["IntegrationProvider"];
      this.region = _data["Region"];
    }
  }

  static fromJS(data: any): GetPartnerTokenByGcIdClientIdRequest {
    data = typeof data === 'object' ? data : {};
    let result = new GetPartnerTokenByGcIdClientIdRequest();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["GcId"] = this.gcId;
    data["ClientId"] = this.clientId;
    data["IntegrationProduct"] = this.integrationProduct;
    data["IntegrationProvider"] = this.integrationProvider;
    data["Region"] = this.region;
    return data;
  }
}

export interface IGetPartnerTokenByGcIdClientIdRequest {
  gcId?: string | undefined;
  clientId?: string | undefined;
  integrationProduct?: GetPartnerTokenByGcIdClientIdRequestIntegrationProduct | undefined;
  integrationProvider?: GetPartnerTokenByGcIdClientIdRequestIntegrationProvider | undefined;
  region?: string | undefined;
}

export class GetPartnerTokenByGcIdClientIdResponse implements IGetPartnerTokenByGcIdClientIdResponse {
  integrationToken?: string | undefined;

  constructor(data?: IGetPartnerTokenByGcIdClientIdResponse) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.integrationToken = _data["IntegrationToken"];
    }
  }

  static fromJS(data: any): GetPartnerTokenByGcIdClientIdResponse {
    data = typeof data === 'object' ? data : {};
    let result = new GetPartnerTokenByGcIdClientIdResponse();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["IntegrationToken"] = this.integrationToken;
    return data;
  }
}

export interface IGetPartnerTokenByGcIdClientIdResponse {
  integrationToken?: string | undefined;
}

export enum GetPartnerTokenByCustomerNumberRequestIntegrationProduct {
  Bmw = <any>"Bmw",
  Mini = <any>"Mini",
  Motorad = <any>"Motorad",
  RollRoyceFuture = <any>"RollRoyceFuture",
}

export enum GetPartnerTokenByCustomerNumberRequestIntegrationProvider {
  SamlResponse = <any>"SamlResponse",
  JwToken = <any>"JwToken",
}

export enum GetPartnerTokenByGcIdClientIdRequestIntegrationProduct {
  Bmw = <any>"Bmw",
  Mini = <any>"Mini",
  Motorad = <any>"Motorad",
  RollRoyceFuture = <any>"RollRoyceFuture",
}

export enum GetPartnerTokenByGcIdClientIdRequestIntegrationProvider {
  SamlResponse = <any>"SamlResponse",
  JwToken = <any>"JwToken",
}
