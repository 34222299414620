import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { mergeMap, catchError } from 'rxjs/operators';
import { UserService } from './user.service';
import { IAppState } from '../store/app.store';
import { CustomerData, UpdateCustomerData } from 'projects/_shared/models/customer-data.model';
import { PartnerContactServiceClient, UpdateNAUserDetailsRequest } from 'projects/_shared/core/gatewayapi/partner-contact-service.api';
import { GenerateFsTokenStatus } from 'projects/_shared/models/generate-fs-token-status.model';
import { FSTokenRequest, FSTokenServiceClient } from 'projects/_shared/core/gatewayapi/fs-token-api';
import { GetContactByGcidClientIdRequest, GetContactByGcidClientIdRequestAppId } from 'projects/_shared/core/gatewayapi/auth-service.api';
import { Store } from '@ngrx/store';

@Injectable()
export class PartnerContactService {

  constructor(
    private userService: UserService,
    private partnerContactService: PartnerContactServiceClient,
    private fsTokenServiceCleint: FSTokenServiceClient,
    private store: Store<IAppState>) { }

    public updateNAUserDetails(customerData: CustomerData, checkAddress: boolean): Observable<UpdateCustomerData> {
    return this.partnerContactService.updateNAUserDetails(this.getUpdateNAUserDetailsRequest(customerData, checkAddress))
      .pipe(mergeMap((response) => { return this.afterUpdateNAUserDetailsSuccess(response); })
        , catchError((error: any) => { return this.afterUpdateNAUserDetailsFailure(error); }));
  }

  public getContactByGcidClientId(): Observable<any> {
    let request = new GetContactByGcidClientIdRequest();
    let clientId: string | undefined;
    this.store.select(state => state.ApplicationConfig!.CLIENT_ID).subscribe(x=> clientId=x);
    request.clientId = clientId;
    request.gCID = this.userService.getGcid();
    request.appId = GetContactByGcidClientIdRequestAppId.WebUser;
    return this.partnerContactService.getContactByGcidClientId(request)
      .pipe(mergeMap((response) => { return this.afterGetContactByGcidSuccess(response); })
        , catchError((error: any) => { return this.afterGetContactByGcidFailure(error); }));
  }

  private afterGetContactByGcidSuccess(response: any): Observable<any> {
    return of(response);
  }

  private afterGetContactByGcidFailure(response: any): Observable<any> {
    return of(response);
  }

  private getUpdateNAUserDetailsRequest(customerData: CustomerData, checkAddress: boolean): UpdateNAUserDetailsRequest {
    let request = new UpdateNAUserDetailsRequest();
    request.gCID = this.userService.getGcid();
    request.title = undefined;
    request.firstName = customerData.customerContact.givenName;
    request.lastname = customerData.customerContact.surname;
    request.middleName = customerData.customerContact.middleName;
    request.suffix = undefined;
    request.addressCheck = checkAddress;
    request.homePhNumber = customerData.customerContact.homePhone;
    request.workPhNumber = customerData.customerContact.workPhone;
    request.cellPhNumber = customerData.customerContact.cellPhone;
    request.street = customerData.customerContact.mailAddress.address1;
    request.suite = customerData.customerContact.mailAddress.address2;
    request.city = customerData.customerContact.mailAddress.city;
    request.county = undefined;
    request.state = customerData.customerContact.mailAddress.regionCode;
    request.zip = customerData.customerContact.mailAddress.postalCode;
    request.country = customerData.customerContact.mailAddress.country;
    request.communicationEmail = customerData.customerContact.communicationEmailAddress;
    return request;
  }

  private afterUpdateNAUserDetailsSuccess(result: any): Observable<UpdateCustomerData> {
    let updateCustomerData = new UpdateCustomerData();
    updateCustomerData.updateNAUserDetailsData = result;
    updateCustomerData.error = false;
    return of(updateCustomerData);
  }

  private afterUpdateNAUserDetailsFailure(error: any): Observable<UpdateCustomerData> {
    let updateCustomerData = new UpdateCustomerData();
    updateCustomerData.error = true;
    return of(updateCustomerData);
  }

  public generateFsToken(transactionId: string, deviceToken: string): Observable<GenerateFsTokenStatus> {
    // Generate the FSToken along with Bearer Token
  
    let request = new FSTokenRequest();
    request.grant_type = "grant_type=password&username=" + transactionId + "&password=" + deviceToken;
    return this.fsTokenServiceCleint.getFSToken(request)
      .pipe(mergeMap((response) => { return this.getFsTokenSuccess(response); })
        );
  }

  private getFsTokenSuccess(result: any): Observable<GenerateFsTokenStatus> {
   
    this.userService.setFs(result.access_token);
    let generateFsTokenStatus = new GenerateFsTokenStatus();
    generateFsTokenStatus.error = false;
    return of(generateFsTokenStatus);
  }

}
