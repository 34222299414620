export enum HeaderMenuItemType {
    MyAccount = "My BMW Account",
    EditUsername = "Edit Username",
    Logout = "Logout"
}

export enum FooterMenuItemType {
    LanguageEnglish = "English",
    LanguageFrench = "French",
    PrivacyPolicy = "Privacy Policy",
    TermsAndConditions = "Terms of Use",
    FAQ = "FAQ",
    CookiePolicy = "Cookie Policy",
    ContactUs = "Contact Us",
    BMWCA = "BMW.ca"
}