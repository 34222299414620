import { Observable, of, interval } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  AuthServiceClient, RefreshBearerTokenRequest,
  ProviderKey,
  GetTermsPolicyDocumentRequest,
  GetTermsPolicyDocumentResponse,
  UserKey,
  EnableTermsAcceptedRequest,
  EnableTermsAcceptedRequestLanguage
} from '../core/gatewayapi/auth-service.api';
import { UserService } from '../shared/helper-service/user.service';
import { IAppState } from '../shared/store/app.store';
import { TermsDoc } from '../models/termsdoc.model';
import { LanguageService } from '../shared/helper-service/language.service';
import { mergeMap, catchError } from 'rxjs/operators';
import { RouterService } from '../shared/helper-service/router.service';
import { Store } from '@ngrx/store';

@Injectable()
export class AuthService {

  private subscription: any;

  constructor(private authServiceClient: AuthServiceClient,
    private userService: UserService,
    private languageService: LanguageService,
    private routerService: RouterService,
    private store: Store<IAppState>
  ) {

  }

  public handleTokenExpiryAfterInterval() {
    if (this.store.select(state =>state.ApplicationConfig)) {
      let Interval: number = 0;
      this.store.select(state =>state.ApplicationConfig!.REFERSH_BEARER_TOKEN_INTERVAL_IN_SEC).subscribe (s => Interval = s);
      let intervalTime = (this.userService.getUserBearerTokenExpireTime() - Interval) * 1000; //Interval Time in milliseconds
      if (intervalTime > 0) {
        this.subscription = interval(intervalTime).subscribe(() => this.refreshBearerToken());
      }
    }
  }

  private refreshBearerToken() {
    if (this.userService.isAuthenticated()) {
      let refreshBearerTokenRequest = new RefreshBearerTokenRequest();
      let providerKey = new ProviderKey();
      this.store.select(state =>state.ApplicationConfig!.CLIENT_ID).subscribe(s=> providerKey.providerId = s);

      refreshBearerTokenRequest.gCID = this.userService.getGcid();
      refreshBearerTokenRequest.providerKey = providerKey;
      refreshBearerTokenRequest.refreshToken = this.userService.getUserRefreshToken();

      this.authServiceClient.refreshBearerToken(refreshBearerTokenRequest)
        .subscribe(response => { this.postRefreshToken(response); },
          error => { this.postRefreshTokenFailure(error); });

    }
    else {
      this.subscription.unsubscribe();
    }
  }

  private postRefreshTokenFailure(error: any) {
    this.routerService.navigateToSsoLogout();
  }

  private postRefreshToken(result: any) {
    this.userService.resetBearerToken(result.accessToken, result.expiresIn, result.refreshToken);
  }

  //terms
  getTermsHtml(): Observable<TermsDoc> {
    let request = new GetTermsPolicyDocumentRequest();
    this.store.select(state =>state.ApplicationConfig!.POLICY_FORMAT).subscribe(s=> request.format = s);
    request.language = this.languageService.getLanguageFromStore().toUpperCase();
    this.store.select(state =>state.ApplicationConfig!.POLICY_ID).subscribe(s=> request.policyId = s);
    let provideKey = new ProviderKey();
    this.store.select(state =>state.ApplicationConfig!.CLIENT_ID).subscribe(s=> provideKey.providerId  = s);
    request.providerKey = provideKey;
    return this.authServiceClient.getTermsPolicyDocument(request)
      .pipe(mergeMap((response) => { return this.afterPostGetPolicyDocumentSuccess(response); })
        , catchError((error: any) => { return this.afterPostGetPolicyDocumentFailure(error); }));
  }


  private afterPostGetPolicyDocumentSuccess(response: any) {
    let doc = new TermsDoc();
    let getTermsPolicyDocumentResponse = new GetTermsPolicyDocumentResponse();

    if (response) {
      getTermsPolicyDocumentResponse = response;
      doc.success = true;
      doc.htmlDocument = getTermsPolicyDocumentResponse.policyDocument!;
    } else {
      doc.success = false;
    }

    return of(doc);
  }

  private afterPostGetPolicyDocumentFailure(error: any) {
    let doc = new TermsDoc();
    doc.success = false;
    return of(doc);
  }


  acceptTermsAndConditions(gcid: string): Observable<boolean> {
    //can be called after registration or login
    let userGcid = new UserKey();
    let request = new EnableTermsAcceptedRequest();
    let providerKey = new ProviderKey();
    userGcid.userId = gcid;
    request.userKey = userGcid;
    this.store.select(state =>state.ApplicationConfig!.CLIENT_ID).subscribe(s=> providerKey.providerId  = s);
    request.bearerToken = this.userService.getUserBearerToken();
    request.providerKey = providerKey;
    request.language = (EnableTermsAcceptedRequestLanguage as any)[this.languageService.getCurrentLanguage().toUpperCase()];
    return this.authServiceClient.enableTermsAccepted(request)
      .pipe(mergeMap((response) => { return of(true); })
        , catchError((error: any) => { return of(false); }));
  }

}
