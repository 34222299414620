export enum TileTypes {
  AllCustomer = "AllCustomer",
  CardHolder = "CardHolder",
  NonCardHolder = "NonCardHolder"
}

export enum FinanceProduct {
  Lease = 1,
  Loan = 2,
}

export enum AccountStatus {
  Active = 1,
  Terminated = 2
}


export enum FeatureIndicatorTypes {
  EnableTrichiryMenu = 127,
  CommunicationPreferences = 129,
  NGDCoBrandedCreditCardBanner = 132,
  CreditCardSsoEnabledFlag = 135
}

export enum Region {
  USA = "USA",
  Canada = "CANADA"
}

export enum Market {
  US = "us",
  Canada = "ca"
}

export enum FooterMenuItem {
  toggelToEnglish = "toggelToEnglish",
  toggelToFrench = "toggelToFrench",
  privacyLink = "privacyLink",
  UnsubscribeLink = "UnsubscribeLink",
  termsLink = "termsLink",
  faqlink = "faqlink",
  contactUsLink = "contactUsLink",
  cookiePolicyLink = "cookiePolicyLink",
  homeLink = "homeLink",
}
