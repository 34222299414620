import { Injectable } from '@angular/core';
import { IAppState } from "../store/app.store";
import { SpinnerService } from 'customerdigital-service-lib';
import { IndicatorActions } from '../store/reducers/indicator.reducer';
import { Store } from '@ngrx/store';


@Injectable()
export class SpinnerHelperService {

  constructor(private spinnerService: SpinnerService,
    private store: Store<IAppState>,
    private indicatorActions: IndicatorActions) { }


  public setDataLoaded(isDataLoaded: boolean) {
    if (isDataLoaded) {
      this.spinnerService.hideSpinner().then((state: boolean) => {
        this.updateState(state, isDataLoaded);
      });
    }
    else {
      this.spinnerService.showSpinner().then((state: boolean) => {
        this.updateState(state, isDataLoaded);
      });
    }
  }

  private updateState(state: boolean, isDataLoaded: boolean) {
    if (state) {
      this.store.dispatch(this.indicatorActions.dataLoaded(isDataLoaded));
    }
  }

}
