import { Observable, throwError } from 'rxjs';
import { InjectionToken } from '@angular/core';

export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');
export const MarketingPreferenceWebApi = "/exp-customermarketingpreference-web-api/v1/marketing?";

export function blobToText(blob: any): Observable<string> {
  return new Observable<string>((observer: any) => {
    if (!blob) {
      observer.next("");
      observer.complete();
    } else {
      let reader = new FileReader();
      reader.onload = function () {
        observer.next(this.result);
        observer.complete()
      }
      reader.readAsText(blob);
    }
  });
}

export function getCleanApiUrl(url: string): string {
  return url.replace(/[?&]$/, "");
}

export function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): Observable<any> {
  if (result !== null && result !== undefined)
    return throwError(()=>result);
  else
    return throwError(()=>new SwaggerException(message, status, response, headers, null));
}

class SwaggerException extends Error {
  message: string;
  status: number;
  response: string;
  headers: { [key: string]: any; };
  result: any;
  faultType!: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;
  constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
    super();

    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
    let data = JSON.parse(response, this.jsonParseReviver);

    if (data) {
      if (data["FaultType"]) {
        this.faultType = data["FaultType"];
      }
    }
  }

  protected isSwaggerException = true;

  static isSwaggerException(obj: any): obj is SwaggerException {
    return obj.isSwaggerException === true;
  }
}
