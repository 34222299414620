import { Topic, Question } from 'customerdigital-ui-lib';

export class FAQModel {
  faqTopics?: FAQTopic[];
  error?: boolean;

  constructor(faqTopics?: FAQTopic[], error?: boolean) {
    this.faqTopics = faqTopics;
    this.error = error;
  }
}

export class FAQTopic extends Topic {
  rankNumber!: number;
  constructor(name: string, questions: Question[], topicId: string, rankNumber: number) {
    super(name, questions, topicId);
    this.rankNumber = rankNumber;
  }
}
