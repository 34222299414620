import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { FinancialProductDTO } from 'projects/_shared/core/gatewayapi/auth-service.api';

import { FooterMenuItemType } from 'projects/my-profile-bmw-ca/src/app/shared/enum';
import { FooterMenuItemTypeForMini } from 'projects/my-profile-mini-ca/src/app/shared/enum';

@Injectable()
export class FilterService {
  public filterFooterMenu(footerMenuItems: any[], footerMenuItem?: FooterMenuItemType): any[] {
    let footerMenuList: any[] = [];
    if (footerMenuItem) {
      let menuItem = footerMenuItems.filter(item => {
        return item.eventName !== FooterMenuItemType.CookiePolicy;
      });
      menuItem.forEach(item => footerMenuList.push(item));
    }
    else {
      footerMenuItems.forEach(item => footerMenuList.push(item));
    }
    return footerMenuList;
  }

  public filterFooterMenuForMini(footerMenuItems: any[], footerMenuItem?: FooterMenuItemTypeForMini): any[] {
    let footerMenuList: any[] = [];
    if (footerMenuItem) {
      let menuItem = footerMenuItems.filter(item => {
        return item.eventName !== FooterMenuItemTypeForMini.CookiePolicy;
      });
      menuItem.forEach(item => footerMenuList.push(item));
    }
    else {
      footerMenuItems.forEach(item => footerMenuList.push(item));
    }
    return footerMenuList;
  }

  public getFilteredFinancialProducts(financialProducts: FinancialProductDTO[]): FinancialProductDTO[] {
    return this.filterFinancialProducts(financialProducts);
  }

  public getFilteredAccountNumbers(financialProducts: FinancialProductDTO[]): string[] {
    let filteredFinancialProducts = this.filterFinancialProducts(financialProducts);
    let accountNumbers = _.map(filteredFinancialProducts, 'financialProductId') as string[];
    return accountNumbers;
  }

  private filterFinancialProducts(financialProducts: FinancialProductDTO[]): FinancialProductDTO[] {
    let filteredFinancialProducts = _.filter(financialProducts, function (fp) {
      return fp.selfServiceAuthorized;
    });
    return filteredFinancialProducts as FinancialProductDTO[];
  }

}