import { Component} from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'projects/_shared/shared/store/app.store';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {  
    loader_img_src!: string;
    constructor(
      private store: Store<IAppState>) {
        this.store.select(state => state.ApplicationConfig!.LOADER_IMG_SRC).subscribe(x => this.loader_img_src = x);
    }


}
