import { UserData } from "customerdigital-service-lib";

export class User extends UserData {
  linkedAccounts: LinkedAccount[];

  constructor() {
    super();
    this.linkedAccounts = [];
  }
}

export class LinkedAccount {
  groupId: string;
  groupIdType: string;

  constructor() {
    this.groupId = "";
    this.groupIdType = "";
  }

}