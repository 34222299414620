import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IAppState } from "../store/app.store";
import { LanguageActions } from '../store/reducers/language.reducer';
import { Constants } from '../constants';
import { FaqActions } from '../store/reducers/faq.reducers';
import { Store } from '@ngrx/store';


@Injectable()
export class LanguageService {

  constructor(private translateService: TranslateService,
    private store: Store<IAppState>,
    private languageAction: LanguageActions,
    private faqActions: FaqActions
    ) { }
  
  public setCurrentLanguage(language: string): void {
    this.store.dispatch(this.languageAction.setLanguage(language));
    let brand: string | undefined;
    let market: string | undefined;
    this.store.select(state => state.ApplicationConfig!.BRAND).subscribe(x => brand = x );
    this.store.select(state => state.ApplicationConfig!.MARKET).subscribe(x => market = x );
    let lang = (Constants.MyProfile + "-" + brand + "-" + market + "-" + language).toLowerCase();
    if (this.translateService.langs.indexOf(lang) > -1) {
      this.clearCachedLanguageData();
      this.translateService.use(lang);
    }
  }

  public getLanguageFromStore(): string {
    let language: string | undefined;
    this.store.select(state => state.Language?.stringValue).subscribe(x => language =x);
    if (language == undefined || language == Constants.EMPTY) {
      language = this.getCurrentLanguage();
    }
    return language;
  }

  public getCurrentLanguage(): string {
    return this.translateService.currentLang.substring(this.translateService.currentLang.lastIndexOf(Constants.LanguageConcat) + 1);
  } 
  public getLanguageParameter(): string {
    return `${Constants.LanguageParam}${Constants.UrlParameterNameAndValueConcat}${this.getLanguageFromStore()}`;
  }

  private clearCachedLanguageData(): void {
    this.store.dispatch(this.faqActions.clearFAQ());
  }  

}
