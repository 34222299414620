import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { FSA } from 'flux-standard-action';
import { ApplicationConfig } from '../../../models/configuration/application.config';

import { tassign } from 'tassign';


export class ApplicationConfigActions {
  static SET_APPLICATION_CONFIG: string = 'SET_APPLICATION_CONFIG';

  setApplicationConfig(applicationConfig: ApplicationConfig): FSA<string, ApplicationConfig, null> {
    return {
      type: ApplicationConfigActions.SET_APPLICATION_CONFIG,
      payload: applicationConfig,
      meta: null
    };
  }

}

@Injectable()
export class ApplicationConfigReducers {
  static ApplicationConfigReducer: Reducer<ApplicationConfig | undefined, FSA<string, ApplicationConfig, null>> = (state: ApplicationConfig = new ApplicationConfig(), action: FSA<string, ApplicationConfig, null>) => {
    console.log("action.Type", action.type);
    switch (action.type) {
      case ApplicationConfigActions.SET_APPLICATION_CONFIG:
        return tassign(state, action.payload);
      default:
        console.log("reached default action.Type", action.type);

        return state;
    }
  }
}
