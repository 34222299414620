import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientLogWriterClient, BeginActivityRequest, ClientLogRequest } from "../core/gatewayapi/logger-service.api";

@Injectable()
export class ClientLogWriterService {

  constructor(private clientLogWriterClient: ClientLogWriterClient) {
  }

  public currentLevel(): Promise<any> {
    return this.clientLogWriterClient.currentLevel()
      .toPromise();
  }

  public beginNewActivity(beginActivityRequest: BeginActivityRequest): Promise<any> {
    return this.clientLogWriterClient.beginNewActivity(beginActivityRequest)
      .toPromise();
  }

  public log(clientLogRequest: ClientLogRequest) : Promise<void>
  {
    return this.clientLogWriterClient.log(clientLogRequest).toPromise();
  }
}
