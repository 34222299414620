import { mergeMap, catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpResponseBase, HttpErrorResponse } from '@angular/common/http';
import { API_BASE_URL, blobToText, getCleanApiUrl, throwException } from '../helper/api-helper';


export interface IGCDMServiceClient {
  /**
   * @return OK
   */
  getUserContactDetails(request: GetUserContactDetailsRequest): Observable<GetUserContactDetailsResponse>;
  /**
   * @return OK
   */
  getUserAccount(request: GetUserAccountRequest): Observable<GetUserAccountResponse>;
}
@Injectable()
export class GCDMServiceClient implements IGCDMServiceClient {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;
  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.http = http;
    this.baseUrl = baseUrl!;
  }
  /**
   * @return OK
   */
  getUserContactDetails(request: GetUserContactDetailsRequest): Observable<GetUserContactDetailsResponse> {
    let url_ = getCleanApiUrl(this.baseUrl + "/api/GCDMService/GetUserContactDetails");
    const content_ = JSON.stringify(request);
    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };

    return this.http.request("post", url_, options_).pipe(mergeMap((response_: any) => {
      return this.processGetUserContactDetails(response_);
    }),
      catchError((response_: any) => {
        if (response_ instanceof HttpResponseBase) {
          try {
            return this.processGetUserContactDetails(<any>response_);
          } catch (e) {
            return <Observable<GetUserContactDetailsResponse>><any>throwError(() => e);
          }
        }
        else
          return <Observable<GetUserContactDetailsResponse>><any>throwError(() => response_);
      }));
  }

  protected processGetUserContactDetails(response: HttpResponseBase): Observable<GetUserContactDetailsResponse> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = GetUserContactDetailsResponse.fromJS(resultData200);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of<GetUserContactDetailsResponse>(<any>null);
  }

  /**
     * @return OK
     */
  getUserAccount(request: GetUserAccountRequest): Observable<GetUserAccountResponse> {
    let url_ = getCleanApiUrl(this.baseUrl + "/api/GCDMService/GetUserAccount");

    const content_ = JSON.stringify(request);

    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };

    return this.http.request("post", url_, options_).pipe(mergeMap((response_: any) => {
      return this.processGetUserAccount(response_);
    }),
      catchError((response_: any) => {
        if (response_ instanceof HttpResponseBase) {
          try {
            return this.processGetUserAccount(<any>response_);
          } catch (e) {
            return <Observable<GetUserAccountResponse>><any>throwError(() => e);
          }
        }
        else
          return <Observable<GetUserAccountResponse>><any>throwError(() => response_);
      }));
  }

  protected processGetUserAccount(response: HttpResponseBase): Observable<GetUserAccountResponse> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = GetUserAccountResponse.fromJS(resultData200);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of<GetUserAccountResponse>(<any>null);
  }
}


export class GetUserContactDetailsRequest implements IGetUserContactDetailsRequest {
  gcId: string | undefined;
  clientId: string | undefined;
  constructor(data?: IGetUserContactDetailsRequest) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
  init(data?: any) {
    if (data) {
      this.gcId = data["GcId"];
      this.clientId = data["ClientId"];
    }
  }
  static fromJS(data: any): GetUserContactDetailsRequest {
    data = typeof data === 'object' ? data : {};
    let result = new GetUserContactDetailsRequest();
    result.init(data);
    return result;
  }
  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["GcId"] = this.gcId;
    data["ClientId"] = this.clientId;
    return data;
  }
}
export interface IGetUserContactDetailsRequest {
  gcId: string | undefined;
  clientId: string | undefined;
}
export class GetUserContactDetailsResponse implements IGetUserContactDetailsResponse {
  contact: UserContactDTO | undefined;
  constructor(data?: IGetUserContactDetailsResponse) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
  init(data?: any) {
    if (data) {
      this.contact = data["Contact"] ? UserContactDTO.fromJS(data["Contact"]) : <any>undefined;
    }
  }
  static fromJS(data: any): GetUserContactDetailsResponse {
    data = typeof data === 'object' ? data : {};
    let result = new GetUserContactDetailsResponse();
    result.init(data);
    return result;
  }
  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["Contact"] = this.contact ? this.contact.toJSON() : <any>undefined;
    return data;
  }
}
export interface IGetUserContactDetailsResponse {
  contact: UserContactDTO | undefined;
}
export class UserContactDTO implements IUserContactDTO {
  gcid: string | undefined;
  clientId: string | undefined;
  birthday: Date | undefined;
  gender: string | undefined;
  homeMarket: string | undefined;
  partnerCategory: string | undefined;
  surname: string | undefined;
  givenName: string | undefined;
  middleName: string | undefined;
  salutation: string | undefined;
  nameSuffixGeneration: string | undefined;
  emailAddress: string | undefined;
  communicationEmailAddress: string | undefined;
  crmContactId: string | undefined;
  mailAddress: AddressDTO2 | undefined;
  homePhone: string | undefined;
  workPhone: string | undefined;
  cellPhone: string | undefined;
  contactIdType: string | undefined;
  preferredLanguage: string | undefined;
  isNaLegitimized: boolean | undefined;

  constructor(data?: IUserContactDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
  init(data?: any) {
    if (data) {
      this.gcid = data["Gcid"];
      this.clientId = data["ClientId"];
      this.birthday = data["Birthday"] ? new Date(data["Birthday"].toString()) : <any>undefined;
      this.gender = data["Gender"];
      this.homeMarket = data["HomeMarket"];
      this.partnerCategory = data["PartnerCategory"];
      this.surname = data["Surname"];
      this.givenName = data["GivenName"];
      this.middleName = data["MiddleName"];
      this.salutation = data["Salutation"];
      this.nameSuffixGeneration = data["NameSuffixGeneration"];
      this.emailAddress = data["EmailAddress"];
      this.communicationEmailAddress = data["CommunicationEmailAddress"];
      this.crmContactId = data["CrmContactId"];
      this.mailAddress = data["MailAddress"] ? AddressDTO2.fromJS(data["MailAddress"]) : <any>undefined;
      this.homePhone = data["HomePhone"];
      this.workPhone = data["WorkPhone"];
      this.cellPhone = data["CellPhone"];
      this.contactIdType = data["ContactIdType"];
      this.preferredLanguage = data["PreferredLanguage"];
      this.isNaLegitimized = data["IsNaLegitimized"];
    }
  }
  static fromJS(data: any): UserContactDTO {
    data = typeof data === 'object' ? data : {};
    let result = new UserContactDTO();
    result.init(data);
    return result;
  }
  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["Gcid"] = this.gcid;
    data["ClientId"] = this.clientId;
    data["Birthday"] = this.birthday ? this.birthday.toISOString() : <any>undefined;
    data["Gender"] = this.gender;
    data["HomeMarket"] = this.homeMarket;
    data["PartnerCategory"] = this.partnerCategory;
    data["Surname"] = this.surname;
    data["GivenName"] = this.givenName;
    data["MiddleName"] = this.middleName;
    data["Salutation"] = this.salutation;
    data["NameSuffixGeneration"] = this.nameSuffixGeneration;
    data["EmailAddress"] = this.emailAddress;
    data["CommunicationEmailAddress"] = this.communicationEmailAddress;
    data["CrmContactId"] = this.crmContactId;
    data["MailAddress"] = this.mailAddress ? this.mailAddress.toJSON() : <any>undefined;
    data["HomePhone"] = this.homePhone;
    data["WorkPhone"] = this.workPhone;
    data["CellPhone"] = this.cellPhone;
    data["ContactIdType"] = this.contactIdType;
    data["PreferredLanguage"] = this.preferredLanguage;
    data["IsNaLegitimized"] = this.isNaLegitimized;
    return data;
  }
}
export interface IUserContactDTO {
  gcid: string | undefined;
  clientId: string | undefined;
  birthday: Date | undefined;
  gender: string | undefined;
  homeMarket: string | undefined;
  partnerCategory: string | undefined;
  surname: string | undefined;
  givenName: string | undefined;
  middleName: string | undefined;
  salutation: string | undefined;
  nameSuffixGeneration: string | undefined;
  emailAddress: string | undefined;
  communicationEmailAddress: string | undefined;
  crmContactId: string | undefined;
  mailAddress: AddressDTO2 | undefined;
  homePhone: string | undefined;
  workPhone: string | undefined;
  cellPhone: string | undefined;
  contactIdType: string | undefined;
  preferredLanguage: string | undefined;
  isNaLegitimized: boolean | undefined;
}
export class AddressDTO2 implements IAddressDTO2 {
  addressStatus: string | undefined;
  city: string | undefined;
  country: string | undefined;
  postalCode: string | undefined;
  regionCode: string | undefined;
  address1: string | undefined;
  address2: string | undefined;
  constructor(data?: IAddressDTO2) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
  init(data?: any) {
    if (data) {
      this.addressStatus = data["AddressStatus"];
      this.city = data["City"];
      this.country = data["Country"];
      this.postalCode = data["PostalCode"];
      this.regionCode = data["RegionCode"];
      this.address1 = data["Address1"];
      this.address2 = data["Address2"];
    }
  }
  static fromJS(data: any): AddressDTO2 {
    data = typeof data === 'object' ? data : {};
    let result = new AddressDTO2();
    result.init(data);
    return result;
  }
  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["AddressStatus"] = this.addressStatus;
    data["City"] = this.city;
    data["Country"] = this.country;
    data["PostalCode"] = this.postalCode;
    data["RegionCode"] = this.regionCode;
    data["Address1"] = this.address1;
    data["Address2"] = this.address2;
    return data;
  }
}
export interface IAddressDTO2 {
  addressStatus: string | undefined;
  city: string | undefined;
  country: string | undefined;
  postalCode: string | undefined;
  regionCode: string | undefined;
  address1: string | undefined;
  address2: string | undefined;
}


export class GetUserAccountRequest implements IGetUserAccountRequest {
  gCID: string | undefined;
  clientId: string | undefined;

  constructor(data?: IGetUserAccountRequest) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.gCID = _data["GCID"];
      this.clientId = _data["ClientId"];
    }
  }

  static fromJS(data: any): GetUserAccountRequest {
    data = typeof data === 'object' ? data : {};
    let result = new GetUserAccountRequest();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["GCID"] = this.gCID;
    data["ClientId"] = this.clientId;
    return data;
  }
}

export interface IGetUserAccountRequest {
  gCID: string | undefined;
  clientId: string | undefined;
}

export class GetUserAccountResponse implements IGetUserAccountResponse {
  alias: string | undefined;
  gCID: string | undefined;
  mail: string | undefined;
  mobile: string | undefined;
  passwordLockedOutDate: Date | undefined;
  pinLockedOutDate: Date | undefined;
  secretKnowledgeState: boolean | undefined;
  gCDMStatusType: GetUserAccountResponseGCDMStatusType | undefined;
  linkedAccounts: GcdmLinkedAccountDTO[] | undefined;

  constructor(data?: IGetUserAccountResponse) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.alias = _data["Alias"];
      this.gCID = _data["GCID"];
      this.mail = _data["Mail"];
      this.mobile = _data["Mobile"];
      this.passwordLockedOutDate = _data["PasswordLockedOutDate"] ? new Date(_data["PasswordLockedOutDate"].toString()) : <any>undefined;
      this.pinLockedOutDate = _data["PinLockedOutDate"] ? new Date(_data["PinLockedOutDate"].toString()) : <any>undefined;
      this.secretKnowledgeState = _data["SecretKnowledgeState"];
      this.gCDMStatusType = _data["GCDMStatusType"];
      if (Array.isArray(_data["LinkedAccounts"])) {
        this.linkedAccounts = [] as any;
        for (let item of _data["LinkedAccounts"])
          this.linkedAccounts!.push(GcdmLinkedAccountDTO.fromJS(item));
      }
    }
  }

  static fromJS(data: any): GetUserAccountResponse {
    data = typeof data === 'object' ? data : {};
    let result = new GetUserAccountResponse();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["Alias"] = this.alias;
    data["GCID"] = this.gCID;
    data["Mail"] = this.mail;
    data["Mobile"] = this.mobile;
    data["PasswordLockedOutDate"] = this.passwordLockedOutDate ? this.passwordLockedOutDate.toISOString() : <any>undefined;
    data["PinLockedOutDate"] = this.pinLockedOutDate ? this.pinLockedOutDate.toISOString() : <any>undefined;
    data["SecretKnowledgeState"] = this.secretKnowledgeState;
    data["GCDMStatusType"] = this.gCDMStatusType;
    if (Array.isArray(this.linkedAccounts)) {
      data["LinkedAccounts"] = [];
      for (let item of this.linkedAccounts)
        data["LinkedAccounts"].push(item.toJSON());
    }
    return data;
  }
}

export interface IGetUserAccountResponse {
  alias: string | undefined;
  gCID: string | undefined;
  mail: string | undefined;
  mobile: string | undefined;
  passwordLockedOutDate: Date | undefined;
  pinLockedOutDate: Date | undefined;
  secretKnowledgeState: boolean | undefined;
  gCDMStatusType: GetUserAccountResponseGCDMStatusType | undefined;
  linkedAccounts: GcdmLinkedAccountDTO[] | undefined;
}

export enum GetUserAccountResponseGCDMStatusType {
  NotActivated = <any>"NotActivated",
  Activated = <any>"Activated",
  Expired = <any>"Expired",
  Admin_Locked = <any>"Admin_Locked",
  External_Authenticated = <any>"External_Authenticated",
  Unconfirmed = <any>"Unconfirmed",
  Reminded = <any>"Reminded",
}

export class GcdmLinkedAccountDTO implements IGcdmLinkedAccountDTO {
  accountId: string | undefined;
  accountType: string | undefined;

  constructor(data?: IGcdmLinkedAccountDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.accountId = data["AccountId"];
      this.accountType = data["AccountType"];
    }
  }

  static fromJS(data: any): GcdmLinkedAccountDTO {
    data = typeof data === 'object' ? data : {};
    let result = new GcdmLinkedAccountDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["AccountId"] = this.accountId;
    data["AccountType"] = this.accountType;
    return data;
  }
}

export interface IGcdmLinkedAccountDTO {
  accountId: string | undefined;
  accountType: string | undefined;
}
