import { Translation } from '../../../_shared/models/enums';
import { createTranslateLoader } from '../../../_shared/services/translation-loader.service';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StorageService, SsoModule, AnalyticsService, UrlBuilderService, CustomerdigitalServiceLibModule, LibConfigurationProvider, TimeoutService } from 'customerdigital-service-lib';
import { RouterService } from '../../../_shared/shared/helper-service/router.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EnvironmentSpecificService } from './configuration/environment-specific-service';
import { UserService } from '../../../_shared/shared/helper-service/user.service';
import { IAppState, rootReducer } from '../../../_shared/shared/store/app.store';
import { ApplicationConfig } from '../../../_shared/models/configuration/application.config';
import { AuthService } from '../../../_shared/web-service/auth.service';
import { LanguageService } from '../../../_shared/shared/helper-service/language.service';
import { AuthServiceClient, CashAccountViewServiceClient } from '../../../_shared/core/gatewayapi/auth-service.api'
import { ClientLogWriterClient } from '../../../_shared/core/gatewayapi/logger-service.api';
import { API_BASE_URL } from '../../../_shared/core/helper/api-helper';
import { ApplicationConfigActions } from '../../../_shared/shared/store/reducers/application-config.reducer';
import { EnvironmentConfigActions } from '../../../_shared/shared/store/reducers/environment-config.reducer';
import { LanguageActions } from '../../../_shared/shared/store/reducers/language.reducer';
import { AuthorizationInterceptor } from '../../../_shared/core/interceptor/authorization.interceptor';
import { GuardService } from '../../../_shared/shared/helper-service/guard.service';
import { ActivityLoggingFactory } from '../../../_shared/core/logging/logging.factory';
import { LoggerHelperService } from '../../../_shared/shared/helper-service/logger-helper.service';
import { ClientLogWriterService } from '../../../_shared/web-service/client-log-writer.service';
import { ParentActivityIdActions } from '../../../_shared/shared/store/reducers/parent-activity-id.reducer';
import { UserActivityIdActions } from '../../../_shared/shared/store/reducers/user-activityid.reducer';
import { SharedModule } from '../../../_shared/shared/shared.module';
import { HelpServiceClient } from '../../../_shared/core/gatewayapi/help-service.api';
import { CustomerDigitalUiLibModule, DialogService, FsDialogModule } from 'customerdigital-ui-lib';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SessionTimeoutDialogService } from 'projects/_shared/shared/helper-service/session-timout-dialog.service';
import { UserInactivityDialogCloseActions } from 'projects/_shared/shared/store/reducers/user-inactivity-dialog-close.reducer';
import { UserIsTimedOutActions } from 'projects/_shared/shared/store/reducers/user-is-timed-out.reducer';
import { FaqActions } from '../../../_shared/shared/store/reducers/faq.reducers';
import { TermsModule } from './terms/terms.module';
import { HeaderModule } from 'projects/my-profile-bmw-us/src/app/header/header.module';
import { GlobalNavigationService } from 'projects/_shared/shared/helper-service/global-navigation.service';
import { MatMenuModule } from '@angular/material/menu';
import { CustomerNaContactActions } from 'projects/_shared/shared/store/reducers/contact-na.reducer';
import { BmwCardService } from 'projects/_shared/web-service/bmw-card.service';
import { FeatureIndicatorService } from 'projects/_shared/web-service/feature-indicator.service';
import { FeatureIndicatorServiceClient } from 'projects/_shared/core/gatewayapi/feature-indicator-service.api';
import { BmwCardServiceClient } from 'projects/_shared/core/gatewayapi/bmw-card.service.api';
import { GCDMService } from 'projects/_shared/shared/helper-service/gcdm-service.service';
import { GCDMServiceClient } from 'projects/_shared/core/gatewayapi/gcdm-service.api';
import { PartnerIntegrationServiceClient } from 'projects/_shared/core/gatewayapi/partner-integration.service.api';
import { PartnerIntegrationService } from 'projects/_shared/web-service/partner-integration.service';
import { IndicatorActions } from 'projects/_shared/shared/store/reducers/indicator.reducer';
import { SpinnerHelperService } from 'projects/_shared/shared/helper-service/spinner.service';
import { ContractAccountDetailActions, ContractAccountDetailReducers } from 'projects/_shared/shared/store/reducers/contract-account-detail.reducers';
import { ContractAccountDetailsActions, ContractAccountDetailsReducers } from 'projects/_shared/shared/store/reducers/contract-account-details.reducers';
import { ContactActions, ContactReducers } from 'projects/_shared/shared/store/reducers/contact.reducers';
import { GlobalErrorActions, GlobalErrorReducers } from 'projects/_shared/shared/store/reducers/global-error-message.reducer';
import { CashAccountViewService } from 'projects/_shared/web-service/cash-account-view.service';
import { ObjectService } from 'projects/_shared/shared/helper-service/object.service';
import { AccountInfoService } from 'projects/_shared/shared/helper-service/account-info.service';
import { FilterService } from 'projects/_shared/shared/helper-service/filter.service';
import { FSTokenServiceClient, FS_TOKEN_API_BASE_URL } from 'projects/_shared/core/gatewayapi/fs-token-api';
import { DataLoadedActions } from 'projects/_shared/shared/store/reducers/dataloaded.reducer';
import { BearerTokenInterceptor } from 'projects/_shared/core/interceptor/bearer-token.interceptor';
import { GlobalErrorHandler } from 'projects/_shared/errorhandler/global-error-handler'
import { Store, StoreModule } from '@ngrx/store';
import { LibConfiguration } from 'projects/_shared/shared/helper-service/lib-config.service';
import { SessionTimeoutService } from 'projects/_shared/shared/helper-service/session-timeout.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AuthenticatorHelperService } from 'projects/_shared/shared/helper-service/authenticator-helper.service';
import { PartnerContactService } from 'projects/_shared/shared/helper-service/partner-contact.service';
import { PartnerContactServiceClient } from 'projects/_shared/core/gatewayapi/partner-contact-service.api';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    MatMenuModule,
    BrowserAnimationsModule,
    NgIdleKeepaliveModule.forRoot(),
    FsDialogModule,
    SharedModule,
    CustomerDigitalUiLibModule,
    TermsModule,
    SsoModule,
    HeaderModule,
    CustomerdigitalServiceLibModule.forRoot({
      config: {
        provide: LibConfigurationProvider,
        useClass: LibConfiguration,
        deps: [Store]
      },
    }
      , RouterService, AuthService, LoggerHelperService, LanguageService, GuardService, SessionTimeoutDialogService),
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot (rootReducer, {
      runtimeChecks: {
      strictStateImmutability: true,
      strictActionImmutability: true
      }
  }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      isolate: false
    })
  ],
  providers: [
    BrowserAnimationsModule,
    AuthService,
    GlobalNavigationService,
    PartnerContactServiceClient,
    PartnerContactService,
    AuthenticatorHelperService,
    {
      provide: "RouterService",
      useFactory: (ServiceFactory),
      deps: [RouterService]
    },
    {
      provide: 'AuthService',
      useFactory: (ServiceFactory),
      deps: [AuthService]
    },
    {
      provide: 'LanguageService',
      useFactory: (ServiceFactory),
      deps: [LanguageService]
    },
    {
      provide: 'GuardService',
      useFactory: (ServiceFactory),
      deps: [GuardService]
    },
    {
      provide: 'LoggerService',
      useFactory: (ServiceFactory),
      deps: [LoggerHelperService]
    },
    ActivityLoggingFactory,
    UrlBuilderService,
    LanguageService,
    TranslateService,
    UrlBuilderService,
    StorageService,
    ClientLogWriterService,
    ClientLogWriterClient,
    ParentActivityIdActions,
    UserActivityIdActions,
    CustomerNaContactActions,
    EnvironmentSpecificService,
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializerLoaderFactory,
      deps: [EnvironmentSpecificService, UserService, Store],
      multi: true
    },
    {
      provide: API_BASE_URL,
      useFactory: BaseUrlLoaderFactory,
      deps: [Store],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerTokenInterceptor,
      multi: true
    },
    {
      provide: FS_TOKEN_API_BASE_URL,
      useFactory: BaseUrlLoaderFactory,
      deps: [Store],
    },
    ApplicationConfigActions,
    EnvironmentConfigActions,
    LanguageActions,
    AuthServiceClient,
    HelpServiceClient,
    FaqActions,
    UserInactivityDialogCloseActions,
    UserIsTimedOutActions,
    DialogService,
    SessionTimeoutService,
    TimeoutService,
    AnalyticsService,
    BmwCardService,
    FeatureIndicatorService,
    BmwCardServiceClient,
    FeatureIndicatorServiceClient,
    GCDMService,
    GCDMServiceClient,
    CashAccountViewService,
    CashAccountViewServiceClient,
    AccountInfoService,
    FilterService,
    PartnerIntegrationServiceClient,
    PartnerIntegrationService,
    IndicatorActions,
    SpinnerHelperService,
    ContractAccountDetailActions,
    ContractAccountDetailsActions,
    ContactReducers,
    GlobalErrorReducers,
    ContractAccountDetailsReducers,
    ContractAccountDetailReducers,
    ObjectService,
    GlobalErrorHandler, {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    GlobalErrorActions,
    FSTokenServiceClient,
    DataLoadedActions,
    ContactActions
  ],
  bootstrap: [
    AppComponent
  ],
  exports: [MatMenuModule
  ]
})
export class AppModule {
  constructor(
    translate: TranslateService
  ) {
    translate.addLangs([Translation.BMW_US_ENGLISH]);
    translate.setDefaultLang(Translation.BMW_US_ENGLISH);
    translate.use(Translation.BMW_US_ENGLISH);
  }


}

export function HttpLoaderFactory(http: HttpClient) {

  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function AppInitializerLoaderFactory(environmentSpecificService: EnvironmentSpecificService, userService: UserService, store: Store<IAppState>) {

  var appConfig: ApplicationConfig = environmentSpecificService.loadApplicationConfig();
  userService.setApplicationConfig(appConfig);
  return () => environmentSpecificService.loadEnvironment()
    .subscribe((response) => {
      userService.setEnvironmentConfig(response);
    }
    );
}

export function BaseUrlLoaderFactory(store: Store<IAppState>) {
  let baseUrl: string | undefined;
  store.select(state => state.EnvironmentConfig?.GATEWAY_API_BASE_URL).subscribe(x => baseUrl = x)
  return baseUrl;
}

export function ServiceFactory(injectedService: any) {
  return injectedService;
}
