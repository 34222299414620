import { mergeMap, catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpResponseBase, HttpErrorResponse } from '@angular/common/http';
import { API_BASE_URL, blobToText, throwException } from '../helper/api-helper';

export interface IHelpServiceClient {
  findHelpQuestionsByPresentationType(request: FindHelpQuestionsByPresentationTypeRequest): Observable<FindHelpQuestionsByPresentationTypeResponse>;
}

@Injectable()
export class HelpServiceClient implements IHelpServiceClient {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : "https:///GatewayApiServices";
  }

  /**
   * @return OK
   */
  findHelpQuestionsByPresentationType(request: FindHelpQuestionsByPresentationTypeRequest): Observable<FindHelpQuestionsByPresentationTypeResponse> {
    let url_ = this.baseUrl + "/api/HelpService/FindHelpQuestionsByPresentationType";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(request);

    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };

    return this.http.request("post", url_, options_).pipe(mergeMap((response_: any) => {
      return this.processFindHelpQuestionsByPresentationType(response_);
    }),
    catchError((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processFindHelpQuestionsByPresentationType(<any>response_);
        } catch (e) {
          return <Observable<FindHelpQuestionsByPresentationTypeResponse>><any>throwError(()=>e);
        }
      } else
        return <Observable<FindHelpQuestionsByPresentationTypeResponse>><any>throwError(()=>response_);
    }));
  }

  protected processFindHelpQuestionsByPresentationType(response: HttpResponseBase): Observable<FindHelpQuestionsByPresentationTypeResponse> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = resultData200 ? FindHelpQuestionsByPresentationTypeResponse.fromJS(resultData200) : new FindHelpQuestionsByPresentationTypeResponse();
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of<FindHelpQuestionsByPresentationTypeResponse>(<any>null);
  }
}


export class FindHelpQuestionsByPresentationTypeRequest implements IFindHelpQuestionsByPresentationTypeRequest {
  presentationTypeKey: PresentationTypeKey | undefined;

  constructor(data?: IFindHelpQuestionsByPresentationTypeRequest) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.presentationTypeKey = data["PresentationTypeKey"] ? PresentationTypeKey.fromJS(data["PresentationTypeKey"]) : <any>undefined;
    }
  }

  static fromJS(data: any): FindHelpQuestionsByPresentationTypeRequest {
    data = typeof data === 'object' ? data : {};
    let result = new FindHelpQuestionsByPresentationTypeRequest();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["PresentationTypeKey"] = this.presentationTypeKey ? this.presentationTypeKey.toJSON() : <any>undefined;
    return data;
  }
}

export interface IFindHelpQuestionsByPresentationTypeRequest {
  presentationTypeKey: PresentationTypeKey | undefined;
}

export class PresentationTypeKey implements IPresentationTypeKey {
  presentationTypeId: number | undefined;

  constructor(data?: IPresentationTypeKey) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.presentationTypeId = data["PresentationTypeId"];
    }
  }

  static fromJS(data: any): PresentationTypeKey {
    data = typeof data === 'object' ? data : {};
    let result = new PresentationTypeKey();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["PresentationTypeId"] = this.presentationTypeId;
    return data;
  }
}

export interface IPresentationTypeKey {
  presentationTypeId: number | undefined;
}

export class FindHelpQuestionsByPresentationTypeResponse implements IFindHelpQuestionsByPresentationTypeResponse {
  helpQuestionCollection: HelpQuestionEntity[] | undefined;

  constructor(data?: IFindHelpQuestionsByPresentationTypeResponse) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (data["HelpQuestionCollection"] && data["HelpQuestionCollection"].constructor === Array) {
        this.helpQuestionCollection = [];
        for (let item of data["HelpQuestionCollection"])
          this.helpQuestionCollection.push(HelpQuestionEntity.fromJS(item));
      }
    }
  }

  static fromJS(data: any): FindHelpQuestionsByPresentationTypeResponse {
    data = typeof data === 'object' ? data : {};
    let result = new FindHelpQuestionsByPresentationTypeResponse();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (this.helpQuestionCollection && this.helpQuestionCollection.constructor === Array) {
      data["HelpQuestionCollection"] = [];
      for (let item of this.helpQuestionCollection)
        data["HelpQuestionCollection"].push(item.toJSON());
    }
    return data;
  }
}

export interface IFindHelpQuestionsByPresentationTypeResponse {
  helpQuestionCollection: HelpQuestionEntity[] | undefined;
}

export class HelpQuestionEntity implements IHelpQuestionEntity {
  key: HelpQuestionKey | undefined;
  mutable: HelpQuestionData | undefined;

  constructor(data?: IHelpQuestionEntity) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.key = data["Key"] ? HelpQuestionKey.fromJS(data["Key"]) : <any>undefined;
      this.mutable = data["Mutable"] ? HelpQuestionData.fromJS(data["Mutable"]) : <any>undefined;
    }
  }

  static fromJS(data: any): HelpQuestionEntity {
    data = typeof data === 'object' ? data : {};
    let result = new HelpQuestionEntity();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["Key"] = this.key ? this.key.toJSON() : <any>undefined;
    data["Mutable"] = this.mutable ? this.mutable.toJSON() : <any>undefined;
    return data;
  }
}

export interface IHelpQuestionEntity {
  key: HelpQuestionKey | undefined;
  mutable: HelpQuestionData | undefined;
}

export class HelpQuestionKey implements IHelpQuestionKey {
  helpQuestionId: number | undefined;

  constructor(data?: IHelpQuestionKey) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.helpQuestionId = data["HelpQuestionId"];
    }
  }

  static fromJS(data: any): HelpQuestionKey {
    data = typeof data === 'object' ? data : {};
    let result = new HelpQuestionKey();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["HelpQuestionId"] = this.helpQuestionId;
    return data;
  }
}

export interface IHelpQuestionKey {
  helpQuestionId: number | undefined;
}

export class HelpQuestionData implements IHelpQuestionData {
  questionText: string | undefined;
  answerText: string | undefined;
  languageCode: number | undefined;
  rankNumber: number | undefined;
  topicTypeId: number | undefined;
  topicTypeDescription: string | undefined;
  topicRankNumber: number | undefined;
  helpQuestionCategoryCollection: HelpQuestionCategoryKeyedData[] | undefined;
  presentationTypeCollection: PresentationTypeKeyedData[] | undefined;

  constructor(data?: IHelpQuestionData) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.questionText = data["QuestionText"];
      this.answerText = data["AnswerText"];
      this.languageCode = data["LanguageCode"];
      this.rankNumber = data["RankNumber"];
      this.topicTypeId = data["TopicTypeId"];
      this.topicTypeDescription = data["TopicTypeDescription"];
      this.topicRankNumber = data["TopicRankNumber"];
      if (data["HelpQuestionCategoryCollection"] && data["HelpQuestionCategoryCollection"].constructor === Array) {
        this.helpQuestionCategoryCollection = [];
        for (let item of data["HelpQuestionCategoryCollection"])
          this.helpQuestionCategoryCollection.push(HelpQuestionCategoryKeyedData.fromJS(item));
      }
      if (data["PresentationTypeCollection"] && data["PresentationTypeCollection"].constructor === Array) {
        this.presentationTypeCollection = [];
        for (let item of data["PresentationTypeCollection"])
          this.presentationTypeCollection.push(PresentationTypeKeyedData.fromJS(item));
      }
    }
  }

  static fromJS(data: any): HelpQuestionData {
    data = typeof data === 'object' ? data : {};
    let result = new HelpQuestionData();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["QuestionText"] = this.questionText;
    data["AnswerText"] = this.answerText;
    data["LanguageCode"] = this.languageCode;
    data["RankNumber"] = this.rankNumber;
    data["TopicTypeId"] = this.topicTypeId;
    data["TopicTypeDescription"] = this.topicTypeDescription;
    data["TopicRankNumber"] = this.topicRankNumber;
    if (this.helpQuestionCategoryCollection && this.helpQuestionCategoryCollection.constructor === Array) {
      data["HelpQuestionCategoryCollection"] = [];
      for (let item of this.helpQuestionCategoryCollection)
        data["HelpQuestionCategoryCollection"].push(item.toJSON());
    }
    if (this.presentationTypeCollection && this.presentationTypeCollection.constructor === Array) {
      data["PresentationTypeCollection"] = [];
      for (let item of this.presentationTypeCollection)
        data["PresentationTypeCollection"].push(item.toJSON());
    }
    return data;
  }
}

export interface IHelpQuestionData {
  questionText: string | undefined;
  answerText: string | undefined;
  languageCode: number | undefined;
  rankNumber: number | undefined;
  topicTypeId: number | undefined;
  topicTypeDescription: string | undefined;
  topicRankNumber: number | undefined;
  helpQuestionCategoryCollection: HelpQuestionCategoryKeyedData[] | undefined;
  presentationTypeCollection: PresentationTypeKeyedData[] | undefined;
}

export class HelpQuestionCategoryKeyedData implements IHelpQuestionCategoryKeyedData {
  data: HelpQuestionCategoryData | undefined;
  key: HelpQuestionCategoryKey | undefined;

  constructor(data?: IHelpQuestionCategoryKeyedData) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.data = data["Data"] ? HelpQuestionCategoryData.fromJS(data["Data"]) : <any>undefined;
      this.key = data["Key"] ? HelpQuestionCategoryKey.fromJS(data["Key"]) : <any>undefined;
    }
  }

  static fromJS(data: any): HelpQuestionCategoryKeyedData {
    data = typeof data === 'object' ? data : {};
    let result = new HelpQuestionCategoryKeyedData();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["Data"] = this.data ? this.data.toJSON() : <any>undefined;
    data["Key"] = this.key ? this.key.toJSON() : <any>undefined;
    return data;
  }
}

export interface IHelpQuestionCategoryKeyedData {
  data: HelpQuestionCategoryData | undefined;
  key: HelpQuestionCategoryKey | undefined;
}

export class PresentationTypeKeyedData implements IPresentationTypeKeyedData {
  data: PresentationTypeData | undefined;
  key: PresentationTypeKey | undefined;

  constructor(data?: IPresentationTypeKeyedData) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.data = data["Data"] ? PresentationTypeData.fromJS(data["Data"]) : <any>undefined;
      this.key = data["Key"] ? PresentationTypeKey.fromJS(data["Key"]) : <any>undefined;
    }
  }

  static fromJS(data: any): PresentationTypeKeyedData {
    data = typeof data === 'object' ? data : {};
    let result = new PresentationTypeKeyedData();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["Data"] = this.data ? this.data.toJSON() : <any>undefined;
    data["Key"] = this.key ? this.key.toJSON() : <any>undefined;
    return data;
  }
}

export interface IPresentationTypeKeyedData {
  data: PresentationTypeData | undefined;
  key: PresentationTypeKey | undefined;
}

export class HelpQuestionCategoryData implements IHelpQuestionCategoryData {
  helpCategoryDescription: string | undefined;
  helpCategoryId: number | undefined;
  effectiveDate: Date | undefined;
  expirationDate: Date | undefined;

  constructor(data?: IHelpQuestionCategoryData) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.helpCategoryDescription = data["HelpCategoryDescription"];
      this.helpCategoryId = data["HelpCategoryId"];
      this.effectiveDate = data["EffectiveDate"] ? new Date(data["EffectiveDate"].toString()) : <any>undefined;
      this.expirationDate = data["ExpirationDate"] ? new Date(data["ExpirationDate"].toString()) : <any>undefined;
    }
  }

  static fromJS(data: any): HelpQuestionCategoryData {
    data = typeof data === 'object' ? data : {};
    let result = new HelpQuestionCategoryData();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["HelpCategoryDescription"] = this.helpCategoryDescription;
    data["HelpCategoryId"] = this.helpCategoryId;
    data["EffectiveDate"] = this.effectiveDate ? this.effectiveDate.toISOString() : <any>undefined;
    data["ExpirationDate"] = this.expirationDate ? this.expirationDate.toISOString() : <any>undefined;
    return data;
  }
}

export interface IHelpQuestionCategoryData {
  helpCategoryDescription: string | undefined;
  helpCategoryId: number | undefined;
  effectiveDate: Date | undefined;
  expirationDate: Date | undefined;
}

export class HelpQuestionCategoryKey implements IHelpQuestionCategoryKey {
  helpQuestionCategoryId: number | undefined;

  constructor(data?: IHelpQuestionCategoryKey) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.helpQuestionCategoryId = data["HelpQuestionCategoryId"];
    }
  }

  static fromJS(data: any): HelpQuestionCategoryKey {
    data = typeof data === 'object' ? data : {};
    let result = new HelpQuestionCategoryKey();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["HelpQuestionCategoryId"] = this.helpQuestionCategoryId;
    return data;
  }
}

export interface IHelpQuestionCategoryKey {
  helpQuestionCategoryId: number | undefined;
}

export class PresentationTypeData implements IPresentationTypeData {
  presentationTypeDescription: string | undefined;

  constructor(data?: IPresentationTypeData) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.presentationTypeDescription = data["PresentationTypeDescription"];
    }
  }

  static fromJS(data: any): PresentationTypeData {
    data = typeof data === 'object' ? data : {};
    let result = new PresentationTypeData();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["PresentationTypeDescription"] = this.presentationTypeDescription;
    return data;
  }
}

export interface IPresentationTypeData {
  presentationTypeDescription: string | undefined;
}

