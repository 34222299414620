import { mergeMap, catchError } from 'rxjs/operators';
import { Observable,throwError,of } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpResponseBase, HttpErrorResponse } from '@angular/common/http';
import { API_BASE_URL, blobToText, throwException } from '../helper/api-helper';
import { Constants } from 'projects/_shared/shared/constants';

export interface IFeatureIndicatorServiceClient {
  getFeatureIndicator(request: GetFeatureIndicatorRequest): Observable<GetFeatureIndicatorResponse>;
  getFeatureIndicatorByClientId(request: GetFeatureIndicatorByClientIdRequest): Observable<GetFeatureIndicatorByClientIdResponse>;
  getFeatureClientId(request: GetFeatureClientIdRequest): Observable<GetFeatureClientIdResponse>;

}


@Injectable()
export class FeatureIndicatorServiceClient implements IFeatureIndicatorServiceClient {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : "https:///GatewayApiServices";
  }

  /**
   * @return OK
   */
  getFeatureIndicator(request: GetFeatureIndicatorRequest): Observable<GetFeatureIndicatorResponse> {
    let url_ = this.baseUrl + "/api/FeatureIndicatorService/GetFeatureIndicator";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(request);

    let options_: any = {
      body: content_,
      observe: Constants.response,
      responseType: Constants.responseTypeBlob,
      headers: new HttpHeaders({
        "Content-Type": Constants.headerContentType,
        "Accept": Constants.headerAccept
      })
    };

    return this.http.request("post", url_, options_).pipe(mergeMap((response_: any) => {
      return this.processGetFeatureIndicator(response_);
    }), catchError((response_: any) => {
      if (response_ instanceof Response) {
        try {
          return this.processGetFeatureIndicator(<any>response_);
        } catch (e) {
         return <Observable<GetFeatureIndicatorResponse>><any>throwError(()=>e);
          //return <Observable<GetFeatureIndicatorResponse>><any>Observable.throw(e);
        }
      } else
        return <Observable<GetFeatureIndicatorResponse>><any>throwError(()=>response_);
    }));
  }

  protected processGetFeatureIndicator(response: HttpResponseBase): Observable<GetFeatureIndicatorResponse> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = resultData200 ? GetFeatureIndicatorResponse.fromJS(resultData200) : new GetFeatureIndicatorResponse();
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of<GetFeatureIndicatorResponse>(<any>null);
  }

/**
 * @return OK
 */
getFeatureIndicatorByClientId(request: GetFeatureIndicatorByClientIdRequest): Observable<GetFeatureIndicatorByClientIdResponse> {
  let url_ = this.baseUrl + "/api/FeatureIndicatorService/GetFeatureIndicatorByClientId";
  url_ = url_.replace(/[?&]$/, "");

  const content_ = JSON.stringify(request);

  let options_: any = {
    body: content_,
    observe: "response",
    responseType: "blob",
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json"
    })
  };

  return this.http.request("post", url_, options_).pipe(mergeMap((response_: any) => {
    return this.processGetFeatureIndicatorByClientId(response_);
  }), catchError((response_: any) => {
    if (response_ instanceof HttpResponseBase) {
      try {
        return this.processGetFeatureIndicatorByClientId(<any>response_);
      } catch (e) {
        return <Observable<GetFeatureIndicatorByClientIdResponse>><any>throwError(()=>e);;
      }
    } else
      return <Observable<GetFeatureIndicatorByClientIdResponse>><any>throwError(()=>response_);;
  }));
}

protected processGetFeatureIndicatorByClientId(response: HttpResponseBase): Observable<GetFeatureIndicatorByClientIdResponse> {
  const status = response.status;
  const responseBlob =
    response instanceof HttpResponse ? response.body :
      (<any>response).error instanceof Blob ? (<any>response).error : undefined;

  let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
  if (status === 200) {
    return blobToText(responseBlob).pipe(mergeMap(_responseText => {
      let result200: any = null;
      let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
      result200 = resultData200 ? GetFeatureIndicatorByClientIdResponse.fromJS(resultData200) : new GetFeatureIndicatorByClientIdResponse();
      return of(result200);
    }));
  } else if (status !== 200 && status !== 204) {
    return blobToText(responseBlob).pipe(mergeMap(_responseText => {
      return throwException("An unexpected server error occurred.", status, _responseText, _headers);
    }));
  }
  return of<GetFeatureIndicatorByClientIdResponse>(<any>null);
}
  getFeatureClientId(request: GetFeatureClientIdRequest): Observable<GetFeatureClientIdResponse> {
    let url_ = this.baseUrl + "/api/FeatureIndicatorService/GetFeatureClientId";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(request);

    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };
    return this.http.request("post", url_, options_).pipe(mergeMap((response_: any) => {
      return this.processGetFeatureClientId(response_);
    }),catchError((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetFeatureClientId(<any>response_);
        } catch (e) {
          return <Observable<GetFeatureClientIdResponse>><any>throwError(()=>e);
        }
      } else
        return <Observable<GetFeatureClientIdResponse>><any>throwError(()=>response_);
    }));
  }

  protected processGetFeatureClientId(response: HttpResponseBase): Observable<GetFeatureClientIdResponse> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = resultData200 ? GetFeatureClientIdResponse.fromJS(resultData200) : new GetFeatureClientIdResponse();
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of<GetFeatureClientIdResponse>(<any>null);
  }
}

export class GetFeatureIndicatorRequest implements IGetFeatureIndicatorRequest {
  featureIndicatorKey?: FeatureIndicatorKey | undefined;

  constructor(data?: IGetFeatureIndicatorRequest) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.featureIndicatorKey = data["FeatureIndicatorKey"] ? FeatureIndicatorKey.fromJS(data["FeatureIndicatorKey"]) : <any>undefined;
    }
  }

  static fromJS(data: any): GetFeatureIndicatorRequest {
    data = typeof data === 'object' ? data : {};
    let result = new GetFeatureIndicatorRequest();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["FeatureIndicatorKey"] = this.featureIndicatorKey ? this.featureIndicatorKey.toJSON() : <any>undefined;
    return data;
  }
}

export interface IGetFeatureIndicatorRequest {
  featureIndicatorKey?: FeatureIndicatorKey | undefined;
}

export class FeatureIndicatorKey implements IFeatureIndicatorKey {
  featureIndicatorId?: number | undefined;

  constructor(data?: IFeatureIndicatorKey) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.featureIndicatorId = data["FeatureIndicatorId"];
    }
  }

  static fromJS(data: any): FeatureIndicatorKey {
    data = typeof data === 'object' ? data : {};
    let result = new FeatureIndicatorKey();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["FeatureIndicatorId"] = this.featureIndicatorId;
    return data;
  }
}

export interface IFeatureIndicatorKey {
  featureIndicatorId?: number | undefined;
}

export class GetFeatureIndicatorResponse implements IGetFeatureIndicatorResponse {
  featureIndicator?: FeatureIndicatorEntity | undefined;

  constructor(data?: IGetFeatureIndicatorResponse) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.featureIndicator = data["FeatureIndicator"] ? FeatureIndicatorEntity.fromJS(data["FeatureIndicator"]) : <any>undefined;
    }
  }

  static fromJS(data: any): GetFeatureIndicatorResponse {
    data = typeof data === 'object' ? data : {};
    let result = new GetFeatureIndicatorResponse();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["FeatureIndicator"] = this.featureIndicator ? this.featureIndicator.toJSON() : <any>undefined;
    return data;
  }
}

export interface IGetFeatureIndicatorResponse {
  featureIndicator?: FeatureIndicatorEntity | undefined;
}



export class GetFeatureIndicatorByClientIdRequest implements IGetFeatureIndicatorByClientIdRequest {
  featureName?: string | undefined;
  featureClientId?: number | undefined;

  constructor(data?: IGetFeatureIndicatorByClientIdRequest) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.featureName = data["FeatureName"];
      this.featureClientId = data["FeatureClientId"];
    }
  }

  static fromJS(data: any): GetFeatureIndicatorByClientIdRequest {
    data = typeof data === 'object' ? data : {};
    let result = new GetFeatureIndicatorByClientIdRequest();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["FeatureName"] = this.featureName;
    data["FeatureClientId"] = this.featureClientId;
    return data;
  }
}

export interface IGetFeatureIndicatorByClientIdRequest {
  featureName?: string | undefined;
  featureClientId?: number | undefined;
}

export class GetFeatureIndicatorByClientIdResponse implements IGetFeatureIndicatorByClientIdResponse {
  enabled?: boolean | undefined;

  constructor(data?: IGetFeatureIndicatorByClientIdResponse) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.enabled = data["Enabled"];
    }
  }

  static fromJS(data: any): GetFeatureIndicatorByClientIdResponse {
    data = typeof data === 'object' ? data : {};
    let result = new GetFeatureIndicatorByClientIdResponse();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["Enabled"] = this.enabled;
    return data;
  }
}

export interface IGetFeatureIndicatorByClientIdResponse {
  enabled?: boolean | undefined;
}

export class GetFeatureClientIdRequest implements IGetFeatureClientIdRequest {
  market?: string | undefined;
  product?: string | undefined;
  brand?: string | undefined;

  constructor(data?: IGetFeatureClientIdRequest) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.market = data["Market"];
      this.product = data["Product"];
      this.brand = data["Brand"];
    }
  }

  static fromJS(data: any): GetFeatureClientIdRequest {
    data = typeof data === 'object' ? data : {};
    let result = new GetFeatureClientIdRequest();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["Market"] = this.market;
    data["Product"] = this.product;
    data["Brand"] = this.brand;
    return data;
  }
}

export interface IGetFeatureClientIdRequest {
  market?: string | undefined;
  product?: string | undefined;
  brand?: string | undefined;
}

export class GetFeatureClientIdResponse implements IGetFeatureClientIdResponse {
  featureClientId?: number | undefined;

  constructor(data?: IGetFeatureClientIdResponse) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.featureClientId = data["FeatureClientId"];
    }
  }

  static fromJS(data: any): GetFeatureClientIdResponse {
    data = typeof data === 'object' ? data : {};
    let result = new GetFeatureClientIdResponse();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["FeatureClientId"] = this.featureClientId;
    return data;
  }
}

export interface IGetFeatureClientIdResponse {
  featureClientId?: number | undefined;
}

export interface IGetFeatureIndicatorRequest {
  featureIndicatorKey?: FeatureIndicatorKey | undefined;
}

export interface IFeatureIndicatorKey {
  featureIndicatorId?: number | undefined;
}


export interface IGetFeatureIndicatorResponse {
  featureIndicator?: FeatureIndicatorEntity | undefined;
}


export class FeatureIndicatorEntity implements IFeatureIndicatorEntity {
  key?: FeatureIndicatorKey | undefined;
  mutable?: FeatureIndicatorData | undefined;
  featureIndicatorCategoryCollection?: FeatureIndicatorCategoryEntity[] | undefined;
  lastChangedBy?: MemberEntity | undefined;

  constructor(data?: IFeatureIndicatorEntity) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.key = data["Key"] ? FeatureIndicatorKey.fromJS(data["Key"]) : <any>undefined;
      this.mutable = data["Mutable"] ? FeatureIndicatorData.fromJS(data["Mutable"]) : <any>undefined;
      if (data["FeatureIndicatorCategoryCollection"] && data["FeatureIndicatorCategoryCollection"].constructor === Array) {
        this.featureIndicatorCategoryCollection = [];
        for (let item of data["FeatureIndicatorCategoryCollection"])
          this.featureIndicatorCategoryCollection.push(FeatureIndicatorCategoryEntity.fromJS(item));
      }
      this.lastChangedBy = data["LastChangedBy"] ? MemberEntity.fromJS(data["LastChangedBy"]) : <any>undefined;
    }
  }

  static fromJS(data: any): FeatureIndicatorEntity {
    data = typeof data === 'object' ? data : {};
    let result = new FeatureIndicatorEntity();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["Key"] = this.key ? this.key.toJSON() : <any>undefined;
    data["Mutable"] = this.mutable ? this.mutable.toJSON() : <any>undefined;
    if (this.featureIndicatorCategoryCollection && this.featureIndicatorCategoryCollection.constructor === Array) {
      data["FeatureIndicatorCategoryCollection"] = [];
      for (let item of this.featureIndicatorCategoryCollection)
        data["FeatureIndicatorCategoryCollection"].push(item.toJSON());
    }
    data["LastChangedBy"] = this.lastChangedBy ? this.lastChangedBy.toJSON() : <any>undefined;
    return data;
  }
}

export interface IFeatureIndicatorEntity {
  key?: FeatureIndicatorKey | undefined;
  mutable?: FeatureIndicatorData | undefined;
  featureIndicatorCategoryCollection?: FeatureIndicatorCategoryEntity[] | undefined;
  lastChangedBy?: MemberEntity | undefined;
}

export class FeatureIndicatorData implements IFeatureIndicatorData {
  isAvailable?: boolean | undefined;
  description?: string | undefined;

  constructor(data?: IFeatureIndicatorData) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.isAvailable = data["IsAvailable"];
      this.description = data["Description"];
    }
  }

  static fromJS(data: any): FeatureIndicatorData {
    data = typeof data === 'object' ? data : {};
    let result = new FeatureIndicatorData();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["IsAvailable"] = this.isAvailable;
    data["Description"] = this.description;
    return data;
  }
}

export interface IFeatureIndicatorData {
  isAvailable?: boolean | undefined;
  description?: string | undefined;
}

export class FeatureIndicatorCategoryEntity implements IFeatureIndicatorCategoryEntity {
  key?: FeatureIndicatorCategoryKey | undefined;
  mutable?: FeatureIndicatorCategoryData | undefined;

  constructor(data?: IFeatureIndicatorCategoryEntity) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.key = data["Key"] ? FeatureIndicatorCategoryKey.fromJS(data["Key"]) : <any>undefined;
      this.mutable = data["Mutable"] ? FeatureIndicatorCategoryData.fromJS(data["Mutable"]) : <any>undefined;
    }
  }

  static fromJS(data: any): FeatureIndicatorCategoryEntity {
    data = typeof data === 'object' ? data : {};
    let result = new FeatureIndicatorCategoryEntity();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["Key"] = this.key ? this.key.toJSON() : <any>undefined;
    data["Mutable"] = this.mutable ? this.mutable.toJSON() : <any>undefined;
    return data;
  }
}

export interface IFeatureIndicatorCategoryEntity {
  key?: FeatureIndicatorCategoryKey | undefined;
  mutable?: FeatureIndicatorCategoryData | undefined;
}

export class FeatureIndicatorCategoryKey implements IFeatureIndicatorCategoryKey {
  featureIndicatorCategoryId?: number | undefined;

  constructor(data?: IFeatureIndicatorCategoryKey) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.featureIndicatorCategoryId = data["FeatureIndicatorCategoryId"];
    }
  }

  static fromJS(data: any): FeatureIndicatorCategoryKey {
    data = typeof data === 'object' ? data : {};
    let result = new FeatureIndicatorCategoryKey();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["FeatureIndicatorCategoryId"] = this.featureIndicatorCategoryId;
    return data;
  }
}

export interface IFeatureIndicatorCategoryKey {
  featureIndicatorCategoryId?: number | undefined;
}

export class FeatureIndicatorCategoryData implements IFeatureIndicatorCategoryData {
  description?: string | undefined;

  constructor(data?: IFeatureIndicatorCategoryData) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.description = data["Description"];
    }
  }

  static fromJS(data: any): FeatureIndicatorCategoryData {
    data = typeof data === 'object' ? data : {};
    let result = new FeatureIndicatorCategoryData();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["Description"] = this.description;
    return data;
  }
}

export interface IFeatureIndicatorCategoryData {
  description?: string | undefined;
}

export class MemberEntity implements IMemberEntity {
  key?: MemberKey | undefined;
  mutable?: MemberData | undefined;

  constructor(data?: IMemberEntity) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.key = data["Key"] ? MemberKey.fromJS(data["Key"]) : <any>undefined;
      this.mutable = data["Mutable"] ? MemberData.fromJS(data["Mutable"]) : <any>undefined;
    }
  }

  static fromJS(data: any): MemberEntity {
    data = typeof data === 'object' ? data : {};
    let result = new MemberEntity();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["Key"] = this.key ? this.key.toJSON() : <any>undefined;
    data["Mutable"] = this.mutable ? this.mutable.toJSON() : <any>undefined;
    return data;
  }
}

export interface IMemberEntity {
  key?: MemberKey | undefined;
  mutable?: MemberData | undefined;
}

export class MemberKey implements IMemberKey {
  memberId?: string | undefined;

  constructor(data?: IMemberKey) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.memberId = data["MemberId"];
    }
  }

  static fromJS(data: any): MemberKey {
    data = typeof data === 'object' ? data : {};
    let result = new MemberKey();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["MemberId"] = this.memberId;
    return data;
  }
}

export interface IMemberKey {
  memberId?: string | undefined;
}

export class MemberData implements IMemberData {
  userName?: string | undefined;
  password?: string | undefined;
  signupTypeCode?: string | undefined;
  temporaryPasswordIndicator?: boolean | undefined;
  termsAcceptanceIndicator?: boolean | undefined;
  secretQuestionResetIndicator?: boolean | undefined;
  updateUserId?: string | undefined;
  locked?: boolean | undefined;
  hasFinancedVehicle?: boolean | undefined;

  constructor(data?: IMemberData) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.userName = data["UserName"];
      this.password = data["Password"];
      this.signupTypeCode = data["SignupTypeCode"];
      this.temporaryPasswordIndicator = data["TemporaryPasswordIndicator"];
      this.termsAcceptanceIndicator = data["TermsAcceptanceIndicator"];
      this.secretQuestionResetIndicator = data["SecretQuestionResetIndicator"];
      this.updateUserId = data["UpdateUserId"];
      this.locked = data["Locked"];
      this.hasFinancedVehicle = data["HasFinancedVehicle"];
    }
  }

  static fromJS(data: any): MemberData {
    data = typeof data === 'object' ? data : {};
    let result = new MemberData();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["UserName"] = this.userName;
    data["Password"] = this.password;
    data["SignupTypeCode"] = this.signupTypeCode;
    data["TemporaryPasswordIndicator"] = this.temporaryPasswordIndicator;
    data["TermsAcceptanceIndicator"] = this.termsAcceptanceIndicator;
    data["SecretQuestionResetIndicator"] = this.secretQuestionResetIndicator;
    data["UpdateUserId"] = this.updateUserId;
    data["Locked"] = this.locked;
    data["HasFinancedVehicle"] = this.hasFinancedVehicle;
    return data;
  }
}

export interface IMemberData {
  userName?: string | undefined;
  password?: string | undefined;
  signupTypeCode?: string | undefined;
  temporaryPasswordIndicator?: boolean | undefined;
  termsAcceptanceIndicator?: boolean | undefined;
  secretQuestionResetIndicator?: boolean | undefined;
  updateUserId?: string | undefined;
  locked?: boolean | undefined;
  hasFinancedVehicle?: boolean | undefined;
}
