import { Component, OnDestroy, OnInit } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../../../../_shared/shared/helper-service/user.service';
import { AuthService } from '../../../../../_shared/web-service/auth.service';
import { route, RouterService } from '../../../../../_shared/shared/helper-service/router.service';
import { LoggerHelperService } from 'projects/_shared/shared/helper-service/logger-helper.service';
import { SpinnerHelperService } from 'projects/_shared/shared/helper-service/spinner.service';
import { IndicatorActions } from 'projects/_shared/shared/store/reducers/indicator.reducer';
import { IAppState } from 'projects/_shared/shared/store/app.store';
import { Subscription } from 'rxjs';
import { AnalyticsService, AnalyticsSiteToolName, AnalyticsSiteToolStep } from 'customerdigital-service-lib';
import { Store } from '@ngrx/store';



@Component({
  selector: 'app-terms-accept',
  templateUrl: "./terms-accept.component.html",
  styleUrls: ['./terms-accept.component.scss']
})

export class TermsAcceptComponent implements OnInit, OnDestroy {

  termsContentHtml !: SafeHtml;
  termsLoadFailed = false;
  dataLoaded! : boolean;
  dataLoadedSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private routerService: RouterService,
    private spinnerService: SpinnerHelperService,
    private sanitizer: DomSanitizer,
    private loggerHelperService: LoggerHelperService,
    private analytics: AnalyticsService,
    private store: Store<IAppState>
  ) { 
   
    this.dataLoadedSubscription = this.store.select(state => state.DataLoaded)
      .subscribe(loaded => this.dataLoaded = loaded!);
   }

  ngOnInit() {
    this.spinnerService.setDataLoaded(false);
    this.authService.getTermsHtml().subscribe(data => {
      this.displayTermsCondition(data.success, data.htmlDocument);
    },
      data => {
        this.displayTermsCondition(false, "");
      });

  }
  ngOnDestroy() {
    this.dataLoadedSubscription.unsubscribe();
  }

  displayTermsCondition(success: boolean, terms: string) {
    if (success) {
      this.termsContentHtml = this.sanitizer.bypassSecurityTrustHtml(terms);
    }
    this.termsLoadFailed = !success;    
    this.spinnerService.setDataLoaded(true);
  }

  cancelTerms() {
    this.routerService.navigateToSSOLogout();
  }

  acceptTerms() {
    this.spinnerService.setDataLoaded(false);
    let gcid = this.userService.getGcid();
    this.authService.acceptTermsAndConditions(gcid)
      .subscribe(response => { this.postAcceptTerms(response); });
  }

  private postAcceptTerms(response: boolean) {
    if (response) {
      this.analytics.pushSiteToolEvent({
        siteTool: AnalyticsSiteToolName.TERMS_AND_CONDITIONS,
        siteToolStep: AnalyticsSiteToolStep.COMPLETE
      });
      this.userService.setTermsAccepted(response);
      this.routerService.navigateToBaseUrl();
    } else {
      this.loggerHelperService.logCritical('Error occurred in TermsAcceptComponent, Gcid:' +
        this.userService.getGcid() + ', authService.acceptTermsAndConditions().');
      this.cancelTerms();
    }
    this.spinnerService.setDataLoaded(true);    
  }
  termsView() {
    window.open(route.termsview);
  }

}
