import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { FSA, isError, isFSA } from 'flux-standard-action';

import { tassign } from 'tassign';


export class UserInactivityDialogCloseActions
{
  static SET_USER_INACTIVITY_DIALOG_CLOSE: string = 'SET_USER_INACTIVITY_DIALOG_CLOSE';

  setUserInactivityDialogCloseActions(userInactivityDialogClose: boolean): FSA<string, boolean, null>{
        return {
          type: UserInactivityDialogCloseActions.SET_USER_INACTIVITY_DIALOG_CLOSE,
          payload: userInactivityDialogClose,
          meta: null
        };
    }

}

@Injectable()
export class UserInactivityDialogCloseReducers
{
  static UserInactivityDialogCloseReducer: Reducer<boolean | undefined, FSA<string, boolean, void>> = (state: boolean = false, action: FSA<string, boolean, void>) => {
        switch(action.type){
          case UserInactivityDialogCloseActions.SET_USER_INACTIVITY_DIALOG_CLOSE:
                return tassign(state, action.payload);
            default:
                return state;
        }
    }
}
