import { UpdateNAUserDetailsResponse } from "../core/gatewayapi/partner-contact-service.api";

export class CustomerData {
  public customerContact: any;
  public error: boolean;

  constructor(userContactDTO?: any, error?: boolean) {
    this.customerContact = userContactDTO;
    this.error = error!;
  }
}

export class CustomerContact {
  public firstName!: string;
  public lastName!: string;
  public middleName!: string;
  public fullName!: string;
  public address1!: string;
  public address2!: string;
  public city!: string;
  public state!: string;
  public country!: string;
  public postalCode!: string;
  public emailAddress!: string;
  public communicationEmailAddress!: string;
  public cellPhone!: string;
  public workPhone!: string;
  public homePhone!: string;
  public error!: boolean;
  public isNaLegitimized!: boolean;
  public customerFSAccessStatus!: CustomerFSAccessStatus;
}

export class CustomerFSAccessStatus {
  public error!: boolean;
  public isFSCustomer!: boolean;
  public isDeviceTokenExists!: boolean;
}

export class UpdateCustomerData {
  public updateNAUserDetailsData: UpdateNAUserDetailsResponse;
  public error: boolean;

  constructor(updateNAUserDetailsData?: UpdateNAUserDetailsResponse, error?: boolean) {
    this.updateNAUserDetailsData = updateNAUserDetailsData!;
    this.error = error!;
  }
}


class ProfileType {
  value!: string;
  maxLength?: number;
}

export class ProfileData {
  addressLine1!: ProfileType;
  addressLine2!: ProfileType;
  city!: ProfileType;
  zip!: ProfileType;
  email!: ProfileType;
  cellNumber!: ProfileType;
  homeNumber!: ProfileType;
  workNumber!: ProfileType;
}

