export class FooterModel {
    privacy?: string;
    terms?: string;
    faq?: string;
    contactus?: string;
    myBMW?: string;
    
    constructor(privacy?: string,
      terms?: string,
      faq?: string,
      contactus?: string,
      myBMW?: string,
      ) {
      this.privacy = privacy;
      this.terms = terms;
      this.faq = faq;
      this.contactus = contactus;
      this.myBMW = myBMW;
    }
  }
  
  