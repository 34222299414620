import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn, Router } from '@angular/router';
import { Constants } from '../shared/constants';
import { GuardService } from '../shared/helper-service/guard.service';
import { RouterService } from '../shared/helper-service/router.service';
import { UserService } from '../shared/helper-service/user.service';


export const TermsAcceptedGuard: CanActivateFn =  (activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
     const userService = inject(UserService);
     const guardService = inject(GuardService);
     const router = inject(Router);
     const routerService = inject(RouterService);

     if (userService.isTermsAccepted())
          return guardService.guardSucceded();
     else {
          routerService.navigateToTermsAccept(activatedRoute.queryParams[Constants.LanguageParam]);
          return false;
     }
}