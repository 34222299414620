import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RouterService, TimeoutDialogSubject, routes, TimeoutService } from 'customerdigital-service-lib';
import { DialogService, ConfirmDialogModel, DialogResponseModel } from 'customerdigital-ui-lib';
import { first, forkJoin } from 'rxjs';
import { Constants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class SessionTimeoutService {
  
  constructor(private dialogService: DialogService,
    private timeoutService: TimeoutService,
    private translateService: TranslateService,
    private routerService: RouterService) { }

handleIdleSessionTimeOut(){
    TimeoutDialogSubject.subscribe((result) => {
      let isTimeoutWarning:boolean = result;
      this.toggleWarningIdleDialog(isTimeoutWarning);
    });
}

   setDefaultStateDialogModal(dialogModel:ConfirmDialogModel):ConfirmDialogModel{
    dialogModel.name=Constants.TimeoutModalIcon;
    dialogModel.isIconEnable=true;
    dialogModel.disableClose=true;
    dialogModel.isDarkTheme=false;
    return dialogModel;
  }

 getTimeOutDialogModel():ConfirmDialogModel {
    const id = 'time-out.id';
    const title = 'time-out.title';
    const dialogBody = 'time-out.body';
    const secondaryButton = 'time-out.secondaryButton';
    const primaryButton = 'time-out.primaryButton';
    let dialogModel=new ConfirmDialogModel();
    this.translateService.get([id,title,dialogBody, secondaryButton,primaryButton]).subscribe(res => {
      dialogModel.title=res[title];
      dialogModel.dialogBody = res[dialogBody];
      dialogModel.primaryButton=res[primaryButton];
      dialogModel.secondaryButton=res[secondaryButton];  
      dialogModel.id= res[id];         
    });
    dialogModel=this.setDefaultStateDialogModal(dialogModel);   
    return dialogModel;   
  }

  public toggleWarningIdleDialog(showDialog: boolean) {
    if (showDialog) {
      let before = [
        this.dialogService.closeAllDialogs().pipe(first()),
      ];
      forkJoin(before).subscribe(() => {
        this.dialogService.openConfirmationDialog(this.getTimeOutDialogModel()).
        afterClosed().subscribe((result: DialogResponseModel) => {
          TimeoutDialogSubject.next(false)
          if (result.confirmed) {
            this.dialogService.closeConfirmationDialog(new DialogResponseModel(result.confirmed));
            this.timeoutService.reset();
          } else {
            this.dialogService.closeConfirmationDialog(new DialogResponseModel(result.checked));
            this.routerService.navigateToRoot(routes.ssoLogout);
          }
        });
      });
    }
    else {
      this.dialogService.closeConfirmationDialog(new DialogResponseModel(false, false));
    }
  }
}