
import { mergeMap, catchError } from 'rxjs/operators';

import { Observable, of, throwError } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpResponseBase, HttpErrorResponse } from '@angular/common/http';
import { API_BASE_URL, blobToText, throwException } from '../helper/api-helper';
import { UserService } from 'projects/_shared/shared/helper-service/user.service';

export interface IAuthServiceClient {
  refreshBearerToken(request: RefreshBearerTokenRequest): Observable<RefreshBearerTokenResponse>;
  /**
   * @return OK
   */
  getTermsPolicyDocument(request: GetTermsPolicyDocumentRequest): Observable<GetTermsPolicyDocumentResponse>;

}

@Injectable()
export class AuthServiceClient implements IAuthServiceClient {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : "https:///GatewayApiServices";
  }

  refreshBearerToken(request: RefreshBearerTokenRequest): Observable<RefreshBearerTokenResponse> {
    let url_ = this.baseUrl + "/api/AuthService/RefreshBearerToken";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(request);

    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };

    return this.http.request("post", url_, options_).pipe(mergeMap((response_: any) => {
      return this.processRefreshBearerToken(response_);
    }),
      catchError((response_: any) => {
        if (response_ instanceof HttpResponseBase) {
          try {
            return this.processRefreshBearerToken(<any>response_);
          } catch (e) {
            return <Observable<RefreshBearerTokenResponse>><any>throwError(()=>e);
          }
        } 
        else
          return <Observable<RefreshBearerTokenResponse>><any>throwError(()=>response_);
      }));
  }

  protected processRefreshBearerToken(response: HttpResponseBase): Observable<RefreshBearerTokenResponse> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = resultData200 ? RefreshBearerTokenResponse.fromJS(resultData200) : new RefreshBearerTokenResponse();
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of<RefreshBearerTokenResponse>(<any>null);
  }

  /**
   * @return OK
   */
  getTermsPolicyDocument(request: GetTermsPolicyDocumentRequest): Observable<GetTermsPolicyDocumentResponse> {
    let url_ = this.baseUrl + "/api/AuthService/GetTermsPolicyDocument";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(request);

    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };

    return this.http.request("post", url_, options_).pipe(mergeMap((response_: any) => {
      return this.processGetTermsPolicyDocument(response_);
    }),
      catchError((response_: any) => {
        if (response_ instanceof HttpResponseBase) {
          try {
            return this.processGetTermsPolicyDocument(<any>response_);
          } catch (e) {
            return <Observable<GetTermsPolicyDocumentResponse>><any>throwError(()=>e);
          }
        } 
        else
          return <Observable<GetTermsPolicyDocumentResponse>><any>throwError(()=>response_);
      }));
  }

  protected processGetTermsPolicyDocument(response: HttpResponseBase): Observable<GetTermsPolicyDocumentResponse> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = resultData200 ? GetTermsPolicyDocumentResponse.fromJS(resultData200) : new GetTermsPolicyDocumentResponse();
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of<GetTermsPolicyDocumentResponse>(<any>null);
  }

  /**
     * @return No Content
     */
  enableTermsAccepted(request: EnableTermsAcceptedRequest): Observable<void> {
    let url_ = this.baseUrl + "/api/AuthService/EnableTermsAccepted";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(request);

    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      })
    };

    return this.http.request("post", url_, options_).pipe(mergeMap((response_: any) => {
      return this.processEnableTermsAccepted(response_);
    }),
    catchError((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processEnableTermsAccepted(<any>response_);
        } catch (e) {
          return <Observable<void>><any>throwError(()=>e);
        }
      } 
      else
        return <Observable<void>><any>throwError(()=>response_);
    }));
  }

  protected processEnableTermsAccepted(response: HttpResponseBase): Observable<void> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
    if (status === 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return of<void>(<any>null);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of<void>(<any>null);
  }
}

export class RefreshBearerTokenRequest implements IRefreshBearerTokenRequest {
  gCID?: string | undefined;
  refreshToken?: string | undefined;
  providerKey?: ProviderKey | undefined;

  constructor(data?: IRefreshBearerTokenRequest) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.gCID = data["GCID"];
      this.refreshToken = data["RefrshToken"];
      this.providerKey = data["ProviderKey"] ? ProviderKey.fromJS(data["ProviderKey"]) : <any>undefined;
    }
  }

  static fromJS(data: any): RefreshBearerTokenRequest {
    data = typeof data === 'object' ? data : {};
    let result = new RefreshBearerTokenRequest();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["GCID"] = this.gCID;
    data["RefrshToken"] = this.refreshToken;
    data["ProviderKey"] = this.providerKey ? this.providerKey.toJSON() : <any>undefined;
    return data;
  }
}

export interface IRefreshBearerTokenRequest {
  gCID?: string | undefined;
  refreshToken?: string | undefined;
  providerKey?: ProviderKey | undefined;
}

export class RefreshBearerTokenResponse implements IRefreshBearerTokenResponse {
  accessToken?: string | undefined;
  tokenType?: string | undefined;
  expiresIn?: number | undefined;
  refreshToken?: string | undefined;

  constructor(data?: IRefreshBearerTokenResponse) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.accessToken = data["AccessToken"];
      this.tokenType = data["TokenType"];
      this.expiresIn = data["ExpiresIn"];
      this.refreshToken = data["RefreshToken"];
    }
  }

  static fromJS(data: any): RefreshBearerTokenResponse {
    data = typeof data === 'object' ? data : {};
    let result = new RefreshBearerTokenResponse();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["AccessToken"] = this.accessToken;
    data["TokenType"] = this.tokenType;
    data["ExpiresIn"] = this.expiresIn;
    data["RefreshToken"] = this.refreshToken;
    return data;
  }
}

export interface IRefreshBearerTokenResponse {
  accessToken?: string | undefined;
  tokenType?: string | undefined;
  expiresIn?: number | undefined;
  refreshToken?: string | undefined;
}

export class GetTermsPolicyDocumentRequest implements IGetTermsPolicyDocumentRequest {
  providerKey: ProviderKey | undefined;
  format: string | undefined;
  language: string | undefined;
  policyId: string | undefined;

  constructor(data?: IGetTermsPolicyDocumentRequest) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.providerKey = data["ProviderKey"] ? ProviderKey.fromJS(data["ProviderKey"]) : <any>undefined;
      this.format = data["Format"];
      this.language = data["Language"];
      this.policyId = data["PolicyId"];
    }
  }

  static fromJS(data: any): GetTermsPolicyDocumentRequest {
    data = typeof data === 'object' ? data : {};
    let result = new GetTermsPolicyDocumentRequest();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["ProviderKey"] = this.providerKey ? this.providerKey.toJSON() : <any>undefined;
    data["Format"] = this.format;
    data["Language"] = this.language;
    data["PolicyId"] = this.policyId;
    return data;
  }
}

export interface IGetTermsPolicyDocumentRequest {
  providerKey: ProviderKey | undefined;
  format: string | undefined;
  language: string | undefined;
  policyId: string | undefined;
}

export class GetTermsPolicyDocumentResponse implements IGetTermsPolicyDocumentResponse {
  policyDocument: string | undefined;

  constructor(data?: IGetTermsPolicyDocumentResponse) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.policyDocument = data["PolicyDocument"];
    }
  }

  static fromJS(data: any): GetTermsPolicyDocumentResponse {
    data = typeof data === 'object' ? data : {};
    let result = new GetTermsPolicyDocumentResponse();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["PolicyDocument"] = this.policyDocument;
    return data;
  }
}

export interface IGetTermsPolicyDocumentResponse {
  policyDocument: string | undefined;
}

export class EnableTermsAcceptedRequest implements IEnableTermsAcceptedRequest {
  userKey: UserKey | undefined;
  providerKey: ProviderKey | undefined;
  securityGuid: string | undefined;
  bearerToken: string | undefined;
  language: EnableTermsAcceptedRequestLanguage | undefined;

  constructor(data?: IEnableTermsAcceptedRequest) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.userKey = data["UserKey"] ? UserKey.fromJS(data["UserKey"]) : <any>undefined;
      this.providerKey = data["ProviderKey"] ? ProviderKey.fromJS(data["ProviderKey"]) : <any>undefined;
      this.securityGuid = data["SecurityGuid"];
      this.bearerToken = data["BearerToken"];
      this.language = data["Language"];
    }
  }

  static fromJS(data: any): EnableTermsAcceptedRequest {
    data = typeof data === 'object' ? data : {};
    let result = new EnableTermsAcceptedRequest();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["UserKey"] = this.userKey ? this.userKey.toJSON() : <any>undefined;
    data["ProviderKey"] = this.providerKey ? this.providerKey.toJSON() : <any>undefined;
    data["SecurityGuid"] = this.securityGuid;
    data["BearerToken"] = this.bearerToken;
    data["Language"] = this.language;
    return data;
  }
}

export interface IEnableTermsAcceptedRequest {
  userKey: UserKey | undefined;
  providerKey: ProviderKey | undefined;
  securityGuid: string | undefined;
  bearerToken: string | undefined;
  language: EnableTermsAcceptedRequestLanguage | undefined;
}

export class UserKey implements IUserKey {
  userId: string | undefined;

  constructor(data?: IUserKey) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.userId = data["UserId"];
    }
  }

  static fromJS(data: any): UserKey {
    data = typeof data === 'object' ? data : {};
    let result = new UserKey();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["UserId"] = this.userId;
    return data;
  }
}

export interface IUserKey {
  userId: string | undefined;
}

export enum EnableTermsAcceptedRequestLanguage {
  EN = <any>"EN",
  FR = <any>"FR",
}

export enum ContractAccountDetailDTOCustomerRoleType {
  Primary = <any>"1",
  Secondary = <any>"2",
  Driver = <any>"3",
  Payor = <any>"4",
  Assumee = <any>"5",
  OriginalPrimaryCustomerAfterAssumption = <any>"6",
  OriginalOtherCustomerAfterAssumption = <any>"7",
  PreviouslyLinkedCustomer = <any>"8",
  CoAssumee = <any>"9",
}

export enum ContractAccountDetailDTOPortfolioCategoryCode {
  Lease = <any>"1",
  Loan = <any>"2",
}


export enum PhoneNumberDTO2PhoneNumberType {
  Alternate = <any>"Alternate",
  Assistant = <any>"Assistant",
  Cell = <any>"Cell",
  Fax = <any>"Fax",
  Home = <any>"Home",
  Work = <any>"Work",
  WorkFax = <any>"WorkFax",
  Cell2 = <any>"Cell2",
}


export class PhoneNumberDTO2 implements IPhoneNumberDTO2 {
  phoneNumber?: string | undefined;
  phoneNumberType?: PhoneNumberDTO2PhoneNumberType | undefined;
  isPrevious?: boolean | undefined;
  phoneConsentStatus?: PhoneNumberDTO2PhoneConsentStatus | undefined;
  lastConsentModifiedBy?: string | undefined;
  consentSource?: PhoneNumberDTO2ConsentSource | undefined;

  constructor(data?: IPhoneNumberDTO2) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.phoneNumber = data["PhoneNumber"];
      this.phoneNumberType = data["PhoneNumberType"];
      this.isPrevious = data["IsPrevious"];
      this.phoneConsentStatus = data["PhoneConsentStatus"];
      this.lastConsentModifiedBy = data["LastConsentModifiedBy"];
      this.consentSource = data["ConsentSource"];
    }
  }

  static fromJS(data: any): PhoneNumberDTO2 {
    data = typeof data === 'object' ? data : {};
    let result = new PhoneNumberDTO2();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["PhoneNumber"] = this.phoneNumber;
    data["PhoneNumberType"] = this.phoneNumberType;
    data["IsPrevious"] = this.isPrevious;
    data["PhoneConsentStatus"] = this.phoneConsentStatus;
    data["LastConsentModifiedBy"] = this.lastConsentModifiedBy;
    data["ConsentSource"] = this.consentSource;
    return data;
  }
}

export interface IPhoneNumberDTO2 {
  phoneNumber?: string | undefined;
  phoneNumberType?: PhoneNumberDTO2PhoneNumberType | undefined;
  isPrevious?: boolean | undefined;
  phoneConsentStatus?: PhoneNumberDTO2PhoneConsentStatus | undefined;
  lastConsentModifiedBy?: string | undefined;
  consentSource?: PhoneNumberDTO2ConsentSource | undefined;
}
export enum PhoneNumberDTO2PhoneConsentStatus {
  Revoked = <any>"Revoked",
  Granted = <any>"Granted",
}

export enum PhoneNumberDTO2ConsentSource {
  CreditApp = <any>"CreditApp",
  CFA = <any>"CFA",
  Agent = <any>"Agent",
}

export class ContractAccountDetailDTO implements IContractAccountDetailDTO {
  accountNumber?: string | undefined;
  fSAccountId?: number | undefined;
  vIN?: string | undefined;
  year?: string | undefined;
  make?: string | undefined;
  model?: string | undefined;
  contractType?: ContractAccountDetailDTOContractType | undefined;
  interestRate?: string | undefined;
  residualAmount?: string | undefined;
  downPaymentAmount?: number | undefined;
  totalContractMileage?: number | undefined;
  fSCompanyId?: string | undefined;
  portfolioId?: string | undefined;
  amortizationCode?: string | undefined;
  paymentMethod?: ContractAccountDetailDTOPaymentMethod | undefined;
  portfolioCategoryCode?: ContractAccountDetailDTOPortfolioCategoryCode | undefined;
  accountStatus?: ContractAccountDetailDTOAccountStatus | undefined;
  statusCategoryCode?: ContractAccountDetailDTOStatusCategoryCode | undefined;
  countryCode?: CountryCode | undefined;
  bookDate?: Date | undefined;
  originalContractEndDate?: Date | undefined;
  currentContractEndDate?: Date | undefined;
  paymentDueDay?: number | undefined;
  originalTerm?: number | undefined;
  currentTerm?: number | undefined;
  monthlyBasePaymentAmount?: number | undefined;
  monthlySalesTaxAmount?: number | undefined;
  monthlyTotalPaymentAmount?: number | undefined;
  lastPaymentAmount?: number | undefined;
  lastPaymentDate?: Date | undefined;
  nextPaymentDueAmount?: number | undefined;
  nextPaymentDueDate?: Date | undefined;
  originatingDealerName?: string | undefined;
  originatingFSDealerId?: number | undefined;
  originatingDealerNumber?: string | undefined;
  originatingDealerPhoneNumber?: string | undefined;
  customerRoleType?: ContractAccountDetailDTOCustomerRoleType | undefined;
  addresses?: AddressDTO[] | undefined;
  phoneNumbers?: AccountPhoneNumberDTO[] | undefined;
  contractStartDate?: Date | undefined;
  balloonAmount?: number | undefined;
  totalAmountFinanced?: number | undefined;
  interestPaidYearToDate?: number | undefined;
  annualMileage?: number | undefined;
  excessChargePerMile?: number | undefined;
  dispositionFee?: number | undefined;
  additionalPurchasedMiles?: number | undefined;
  milesAtInception?: number | undefined;
  totalAmountDue?: number | undefined;
  currentBalance?: number | undefined;
  adjustedNextPaymentDueDate?: Date | undefined;
  aGBrand?: string | undefined;
  nAModelCode?: string | undefined;
  numberOfBillings?: number | undefined;
  principalAmount?: number | undefined;
  purchaseAmount?: number | undefined;
  assignFeeAmount?: number | undefined;
  pastDueAmount?: number | undefined;
  maxDaysOver?: number | undefined;

  constructor(data?: IContractAccountDetailDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.accountNumber = data["AccountNumber"];
      this.fSAccountId = data["FSAccountId"];
      this.vIN = data["VIN"];
      this.year = data["Year"];
      this.make = data["Make"];
      this.model = data["Model"];
      this.contractType = data["ContractType"];
      this.interestRate = data["InterestRate"];
      this.residualAmount = data["ResidualAmount"];
      this.downPaymentAmount = data["DownPaymentAmount"];
      this.totalContractMileage = data["TotalContractMileage"];
      this.fSCompanyId = data["FSCompanyId"];
      this.portfolioId = data["PortfolioId"];
      this.amortizationCode = data["AmortizationCode"];
      this.paymentMethod = data["PaymentMethod"];
      this.portfolioCategoryCode = data["PortfolioCategoryCode"];
      this.accountStatus = data["AccountStatus"];
      this.statusCategoryCode = data["StatusCategoryCode"];
      this.countryCode = data["CountryCode"] ? CountryCode.fromJS(data["CountryCode"]) : <any>undefined;
      this.bookDate = data["BookDate"] ? new Date(data["BookDate"].toString()) : <any>undefined;
      this.originalContractEndDate = data["OriginalContractEndDate"] ? new Date(data["OriginalContractEndDate"].toString()) : <any>undefined;
      this.currentContractEndDate = data["CurrentContractEndDate"] ? new Date(data["CurrentContractEndDate"].toString()) : <any>undefined;
      this.paymentDueDay = data["PaymentDueDay"];
      this.originalTerm = data["OriginalTerm"];
      this.currentTerm = data["CurrentTerm"];
      this.monthlyBasePaymentAmount = data["MonthlyBasePaymentAmount"];
      this.monthlySalesTaxAmount = data["MonthlySalesTaxAmount"];
      this.monthlyTotalPaymentAmount = data["MonthlyTotalPaymentAmount"];
      this.lastPaymentAmount = data["LastPaymentAmount"];
      this.lastPaymentDate = data["LastPaymentDate"] ? new Date(data["LastPaymentDate"].toString().replace('T', ' ').replace(/-/g, "/")) : <any>undefined;
      this.nextPaymentDueAmount = data["NextPaymentDueAmount"];
      this.nextPaymentDueDate = data["NextPaymentDueDate"] ? new Date(data["NextPaymentDueDate"].toString().replace('T', ' ').replace(/-/g, "/")) : <any>undefined;
      this.originatingDealerName = data["OriginatingDealerName"];
      this.originatingFSDealerId = data["OriginatingFSDealerId"];
      this.originatingDealerNumber = data["OriginatingDealerNumber"];
      this.originatingDealerPhoneNumber = data["OriginatingDealerPhoneNumber"];
      this.customerRoleType = data["CustomerRoleType"];
      if (data["Addresses"] && data["Addresses"].constructor === Array) {
        this.addresses = [];
        for (let item of data["Addresses"])
          this.addresses.push(AddressDTO.fromJS(item));
      }
      if (data["PhoneNumbers"] && data["PhoneNumbers"].constructor === Array) {
        this.phoneNumbers = [];
        for (let item of data["PhoneNumbers"])
          this.phoneNumbers.push(AccountPhoneNumberDTO.fromJS(item));
      }
      this.contractStartDate = data["ContractStartDate"] ? new Date(data["ContractStartDate"].toString().replace('T', ' ').replace(/-/g, "/")) : <any>undefined;
      this.balloonAmount = data["BalloonAmount"];
      this.totalAmountFinanced = data["TotalAmountFinanced"];
      this.interestPaidYearToDate = data["InterestPaidYearToDate"];
      this.annualMileage = data["AnnualMileage"];
      this.excessChargePerMile = data["ExcessChargePerMile"];
      this.dispositionFee = data["DispositionFee"];
      this.additionalPurchasedMiles = data["AdditionalPurchasedMiles"];
      this.milesAtInception = data["MilesAtInception"];
      this.totalAmountDue = data["TotalAmountDue"];
      this.currentBalance = data["CurrentBalance"];
      this.adjustedNextPaymentDueDate = data["AdjustedNextPaymentDueDate"] ? new Date(data["AdjustedNextPaymentDueDate"].toString().replace('T', ' ').replace(/-/g, "/")) : <any>undefined;
      this.aGBrand = data["AGBrand"];
      this.nAModelCode = data["NAModelCode"];
      this.numberOfBillings = data["NumberOfBillings"];
      this.principalAmount = data["PrincipalAmount"];
      this.purchaseAmount = data["PurchaseAmount"];
      this.assignFeeAmount = data["AssignFeeAmount"];
      this.pastDueAmount = data["PastDueAmount"];
      this.maxDaysOver = data["MaxDaysOver"];
    }
  }

  static fromJS(data: any): ContractAccountDetailDTO {
    data = typeof data === 'object' ? data : {};
    let result = new ContractAccountDetailDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["AccountNumber"] = this.accountNumber;
    data["FSAccountId"] = this.fSAccountId;
    data["VIN"] = this.vIN;
    data["Year"] = this.year;
    data["Make"] = this.make;
    data["Model"] = this.model;
    data["ContractType"] = this.contractType;
    data["InterestRate"] = this.interestRate;
    data["ResidualAmount"] = this.residualAmount;
    data["DownPaymentAmount"] = this.downPaymentAmount;
    data["TotalContractMileage"] = this.totalContractMileage;
    data["FSCompanyId"] = this.fSCompanyId;
    data["PortfolioId"] = this.portfolioId;
    data["AmortizationCode"] = this.amortizationCode;
    data["PaymentMethod"] = this.paymentMethod;
    data["PortfolioCategoryCode"] = this.portfolioCategoryCode;
    data["AccountStatus"] = this.accountStatus;
    data["StatusCategoryCode"] = this.statusCategoryCode;
    data["CountryCode"] = this.countryCode ? this.countryCode.toJSON() : <any>undefined;
    data["BookDate"] = this.bookDate ? this.bookDate.toISOString() : <any>undefined;
    data["OriginalContractEndDate"] = this.originalContractEndDate ? this.originalContractEndDate.toISOString() : <any>undefined;
    data["CurrentContractEndDate"] = this.currentContractEndDate ? this.currentContractEndDate.toISOString() : <any>undefined;
    data["PaymentDueDay"] = this.paymentDueDay;
    data["OriginalTerm"] = this.originalTerm;
    data["CurrentTerm"] = this.currentTerm;
    data["MonthlyBasePaymentAmount"] = this.monthlyBasePaymentAmount;
    data["MonthlySalesTaxAmount"] = this.monthlySalesTaxAmount;
    data["MonthlyTotalPaymentAmount"] = this.monthlyTotalPaymentAmount;
    data["LastPaymentAmount"] = this.lastPaymentAmount;
    data["LastPaymentDate"] = this.lastPaymentDate ? this.lastPaymentDate.toISOString() : <any>undefined;
    data["NextPaymentDueAmount"] = this.nextPaymentDueAmount;
    data["NextPaymentDueDate"] = this.nextPaymentDueDate ? this.nextPaymentDueDate.toISOString() : <any>undefined;
    data["OriginatingDealerName"] = this.originatingDealerName;
    data["OriginatingFSDealerId"] = this.originatingFSDealerId;
    data["OriginatingDealerNumber"] = this.originatingDealerNumber;
    data["OriginatingDealerPhoneNumber"] = this.originatingDealerPhoneNumber;
    data["CustomerRoleType"] = this.customerRoleType;
    if (this.addresses && this.addresses.constructor === Array) {
      data["Addresses"] = [];
      for (let item of this.addresses)
        data["Addresses"].push(item.toJSON());
    }
    if (this.phoneNumbers && this.phoneNumbers.constructor === Array) {
      data["PhoneNumbers"] = [];
      for (let item of this.phoneNumbers)
        data["PhoneNumbers"].push(item.toJSON());
    }
    data["ContractStartDate"] = this.contractStartDate ? this.contractStartDate.toISOString() : <any>undefined;
    data["BalloonAmount"] = this.balloonAmount;
    data["TotalAmountFinanced"] = this.totalAmountFinanced;
    data["InterestPaidYearToDate"] = this.interestPaidYearToDate;
    data["AnnualMileage"] = this.annualMileage;
    data["ExcessChargePerMile"] = this.excessChargePerMile;
    data["DispositionFee"] = this.dispositionFee;
    data["AdditionalPurchasedMiles"] = this.additionalPurchasedMiles;
    data["MilesAtInception"] = this.milesAtInception;
    data["TotalAmountDue"] = this.totalAmountDue;
    data["CurrentBalance"] = this.currentBalance;
    data["AdjustedNextPaymentDueDate"] = this.adjustedNextPaymentDueDate ? this.adjustedNextPaymentDueDate.toISOString() : <any>undefined;
    data["AGBrand"] = this.aGBrand;
    data["NAModelCode"] = this.nAModelCode;
    data["NumberOfBillings"] = this.numberOfBillings;
    data["PrincipalAmount"] = this.principalAmount;
    data["PurchaseAmount"] = this.purchaseAmount;
    data["AssignFeeAmount"] = this.assignFeeAmount;
    data["PastDueAmount"] = this.pastDueAmount;
    data["MaxDaysOver"] = this.maxDaysOver;
    return data;
  }
}

export interface IContractAccountDetailDTO {
  accountNumber?: string | undefined;
  fSAccountId?: number | undefined;
  vIN?: string | undefined;
  year?: string | undefined;
  make?: string | undefined;
  model?: string | undefined;
  contractType?: ContractAccountDetailDTOContractType | undefined;
  interestRate?: string | undefined;
  residualAmount?: string | undefined;
  downPaymentAmount?: number | undefined;
  totalContractMileage?: number | undefined;
  fSCompanyId?: string | undefined;
  portfolioId?: string | undefined;
  amortizationCode?: string | undefined;
  paymentMethod?: ContractAccountDetailDTOPaymentMethod | undefined;
  portfolioCategoryCode?: ContractAccountDetailDTOPortfolioCategoryCode | undefined;
  accountStatus?: ContractAccountDetailDTOAccountStatus | undefined;
  statusCategoryCode?: ContractAccountDetailDTOStatusCategoryCode | undefined;
  countryCode?: CountryCode | undefined;
  bookDate?: Date | undefined;
  originalContractEndDate?: Date | undefined;
  currentContractEndDate?: Date | undefined;
  paymentDueDay?: number | undefined;
  originalTerm?: number | undefined;
  currentTerm?: number | undefined;
  monthlyBasePaymentAmount?: number | undefined;
  monthlySalesTaxAmount?: number | undefined;
  monthlyTotalPaymentAmount?: number | undefined;
  lastPaymentAmount?: number | undefined;
  lastPaymentDate?: Date | undefined;
  nextPaymentDueAmount?: number | undefined;
  nextPaymentDueDate?: Date | undefined;
  originatingDealerName?: string | undefined;
  originatingFSDealerId?: number | undefined;
  originatingDealerNumber?: string | undefined;
  originatingDealerPhoneNumber?: string | undefined;
  customerRoleType?: ContractAccountDetailDTOCustomerRoleType | undefined;
  addresses?: AddressDTO[] | undefined;
  phoneNumbers?: AccountPhoneNumberDTO[] | undefined;
  contractStartDate?: Date | undefined;
  balloonAmount?: number | undefined;
  totalAmountFinanced?: number | undefined;
  interestPaidYearToDate?: number | undefined;
  annualMileage?: number | undefined;
  excessChargePerMile?: number | undefined;
  dispositionFee?: number | undefined;
  additionalPurchasedMiles?: number | undefined;
  milesAtInception?: number | undefined;
  totalAmountDue?: number | undefined;
  currentBalance?: number | undefined;
  adjustedNextPaymentDueDate?: Date | undefined;
  aGBrand?: string | undefined;
  nAModelCode?: string | undefined;
  numberOfBillings?: number | undefined;
  principalAmount?: number | undefined;
  purchaseAmount?: number | undefined;
  assignFeeAmount?: number | undefined;
  pastDueAmount?: number | undefined;
  maxDaysOver?: number | undefined;
}

export class ContactDTO implements IContactDTO {
  contactId?: string | undefined;
  eDBCustomerNumber?: number | undefined;
  firstName?: string;
  middleName?: string | undefined;
  surname?: string | undefined;
  lastName?: string | undefined;
  isFsCustomer?: boolean | undefined;
  suffix?: string | undefined;
  salutation?: string | undefined;
  nickname?: string | undefined;
  gender?: string | undefined;
  preferredName?: string | undefined;
  disableNameCleansing?: boolean | undefined;
  companyIndicator?: string | undefined;
  status?: string | undefined;
  dateOfBirth?: Date | undefined;
  driversLicense?: string | undefined;
  driversLicenseExpiryDate?: Date | undefined;
  driversLicenseState?: string | undefined;
  employer?: string | undefined;
  income?: string | undefined;
  occupation?: string | undefined;
  jobTitle?: string | undefined;
  mealPreference?: string | undefined;
  allergyDescription?: string | undefined;
  magazineSubscription?: MagazineSubscriptionDTO[] | undefined;
  gCDMRelationships?: GCDMDTO[] | undefined;
  vehicles?: VehicleDTO[] | undefined;
  productionNumbers?: string[] | undefined;
  selfReportedVins?: string[] | undefined;
  financialProducts?: FinancialProductDTO[] | undefined;
  salesCompanyAddresses?: AddressDTO[] | undefined;
  phoneNumbers?: PhoneNumberDTO2[] | undefined;
  emailAddresses?: EmailAddressDTO[] | undefined;
  organizations?: Organizations[] | undefined;
  privacyAttributes?: PrivacyAttributeDTO[] | undefined;
  canadaVehicleRecalls?: VehicleRecallDTO[] | undefined;
  preferredRetailers?: PreferredRetailerDTO[] | undefined;
  purchaseIntents?: PurchaseIntentDTO[] | undefined;
  modelsOfInteres?: ModelOfInterestDTO[] | undefined;
  primaryOrganization?: ContactDTOPrimaryOrganization | undefined;
  relatedRetailers?: ContactRetailerDTO[] | undefined;
  lastUpdatedBy?: string | undefined;
  lastUpdatedBySystem?: string | undefined;
  lastUpdatedByDate?: Date | undefined;
  preferredLanguage?: string | undefined;

  constructor(data?: IContactDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.contactId = data["ContactId"];
      this.eDBCustomerNumber = data["EDBCustomerNumber"];
      this.firstName = data["FirstName"];
      this.middleName = data["MiddleName"];
      this.surname = data["Surname"];
      this.lastName = data["LastName"];
      this.isFsCustomer = data["IsFsCustomer"];
      this.suffix = data["Suffix"];
      this.salutation = data["Salutation"];
      this.nickname = data["Nickname"];
      this.gender = data["Gender"];
      this.preferredName = data["PreferredName"];
      this.disableNameCleansing = data["DisableNameCleansing"];
      this.companyIndicator = data["CompanyIndicator"];
      this.status = data["Status"];
      this.dateOfBirth = data["DateOfBirth"] ? new Date(data["DateOfBirth"].toString()) : <any>undefined;
      this.driversLicense = data["DriversLicense"];
      this.driversLicenseExpiryDate = data["DriversLicenseExpiryDate"] ? new Date(data["DriversLicenseExpiryDate"].toString()) : <any>undefined;
      this.driversLicenseState = data["DriversLicenseState"];
      this.employer = data["Employer"];
      this.income = data["Income"];
      this.occupation = data["Occupation"];
      this.jobTitle = data["JobTitle"];
      this.mealPreference = data["MealPreference"];
      this.allergyDescription = data["AllergyDescription"];
      if (data["MagazineSubscription"] && data["MagazineSubscription"].constructor === Array) {
        this.magazineSubscription = [];
        for (let item of data["MagazineSubscription"])
          this.magazineSubscription.push(MagazineSubscriptionDTO.fromJS(item));
      }
      if (data["GCDMRelationships"] && data["GCDMRelationships"].constructor === Array) {
        this.gCDMRelationships = [];
        for (let item of data["GCDMRelationships"])
          this.gCDMRelationships.push(GCDMDTO.fromJS(item));
      }
      if (data["Vehicles"] && data["Vehicles"].constructor === Array) {
        this.vehicles = [];
        for (let item of data["Vehicles"])
          this.vehicles.push(VehicleDTO.fromJS(item));
      }
      if (data["ProductionNumbers"] && data["ProductionNumbers"].constructor === Array) {
        this.productionNumbers = [];
        for (let item of data["ProductionNumbers"])
          this.productionNumbers.push(item);
      }
      if (data["SelfReportedVins"] && data["SelfReportedVins"].constructor === Array) {
        this.selfReportedVins = [];
        for (let item of data["SelfReportedVins"])
          this.selfReportedVins.push(item);
      }
      if (data["FinancialProducts"] && data["FinancialProducts"].constructor === Array) {
        this.financialProducts = [];
        for (let item of data["FinancialProducts"])
          this.financialProducts.push(FinancialProductDTO.fromJS(item));
      }
      if (data["SalesCompanyAddresses"] && data["SalesCompanyAddresses"].constructor === Array) {
        this.salesCompanyAddresses = [];
        for (let item of data["SalesCompanyAddresses"])
          this.salesCompanyAddresses.push(AddressDTO.fromJS(item));
      }
      if (data["PhoneNumbers"] && data["PhoneNumbers"].constructor === Array) {
        this.phoneNumbers = [];
        for (let item of data["PhoneNumbers"])
          this.phoneNumbers.push(PhoneNumberDTO2.fromJS(item));
      }
      if (data["EmailAddresses"] && data["EmailAddresses"].constructor === Array) {
        this.emailAddresses = [];
        for (let item of data["EmailAddresses"])
          this.emailAddresses.push(EmailAddressDTO.fromJS(item));
      }
      if (data["Organizations"] && data["Organizations"].constructor === Array) {
        this.organizations = [];
        for (let item of data["Organizations"])
          this.organizations.push(item);
      }
      if (data["PrivacyAttributes"] && data["PrivacyAttributes"].constructor === Array) {
        this.privacyAttributes = [];
        for (let item of data["PrivacyAttributes"])
          this.privacyAttributes.push(PrivacyAttributeDTO.fromJS(item));
      }
      if (data["CanadaVehicleRecalls"] && data["CanadaVehicleRecalls"].constructor === Array) {
        this.canadaVehicleRecalls = [];
        for (let item of data["CanadaVehicleRecalls"])
          this.canadaVehicleRecalls.push(VehicleRecallDTO.fromJS(item));
      }
      if (data["PreferredRetailers"] && data["PreferredRetailers"].constructor === Array) {
        this.preferredRetailers = [];
        for (let item of data["PreferredRetailers"])
          this.preferredRetailers.push(PreferredRetailerDTO.fromJS(item));
      }
      if (data["PurchaseIntents"] && data["PurchaseIntents"].constructor === Array) {
        this.purchaseIntents = [];
        for (let item of data["PurchaseIntents"])
          this.purchaseIntents.push(PurchaseIntentDTO.fromJS(item));
      }
      if (data["ModelsOfInteres"] && data["ModelsOfInteres"].constructor === Array) {
        this.modelsOfInteres = [];
        for (let item of data["ModelsOfInteres"])
          this.modelsOfInteres.push(ModelOfInterestDTO.fromJS(item));
      }
      this.primaryOrganization = data["PrimaryOrganization"];
      if (data["RelatedRetailers"] && data["RelatedRetailers"].constructor === Array) {
        this.relatedRetailers = [];
        for (let item of data["RelatedRetailers"])
          this.relatedRetailers.push(ContactRetailerDTO.fromJS(item));
      }
      this.lastUpdatedBy = data["LastUpdatedBy"];
      this.lastUpdatedBySystem = data["LastUpdatedBySystem"];
      this.lastUpdatedByDate = data["LastUpdatedByDate"] ? new Date(data["LastUpdatedByDate"].toString()) : <any>undefined;
      this.preferredLanguage = data["PreferredLanguage"];
    }
  }

  static fromJS(data: any): ContactDTO {
    data = typeof data === 'object' ? data : {};
    let result = new ContactDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["ContactId"] = this.contactId;
    data["EDBCustomerNumber"] = this.eDBCustomerNumber;
    data["FirstName"] = this.firstName;
    data["MiddleName"] = this.middleName;
    data["Surname"] = this.surname;
    data["LastName"] = this.lastName;
    data["IsFsCustomer"] = this.isFsCustomer;
    data["Suffix"] = this.suffix;
    data["Salutation"] = this.salutation;
    data["Nickname"] = this.nickname;
    data["Gender"] = this.gender;
    data["PreferredName"] = this.preferredName;
    data["DisableNameCleansing"] = this.disableNameCleansing;
    data["CompanyIndicator"] = this.companyIndicator;
    data["Status"] = this.status;
    data["DateOfBirth"] = this.dateOfBirth ? this.dateOfBirth.toISOString() : <any>undefined;
    data["DriversLicense"] = this.driversLicense;
    data["DriversLicenseExpiryDate"] = this.driversLicenseExpiryDate ? this.driversLicenseExpiryDate.toISOString() : <any>undefined;
    data["DriversLicenseState"] = this.driversLicenseState;
    data["Employer"] = this.employer;
    data["Income"] = this.income;
    data["Occupation"] = this.occupation;
    data["JobTitle"] = this.jobTitle;
    data["MealPreference"] = this.mealPreference;
    data["AllergyDescription"] = this.allergyDescription;
    if (this.magazineSubscription && this.magazineSubscription.constructor === Array) {
      data["MagazineSubscription"] = [];
      for (let item of this.magazineSubscription)
        data["MagazineSubscription"].push(item.toJSON());
    }
    if (this.gCDMRelationships && this.gCDMRelationships.constructor === Array) {
      data["GCDMRelationships"] = [];
      for (let item of this.gCDMRelationships)
        data["GCDMRelationships"].push(item.toJSON());
    }
    if (this.vehicles && this.vehicles.constructor === Array) {
      data["Vehicles"] = [];
      for (let item of this.vehicles)
        data["Vehicles"].push(item.toJSON());
    }
    if (this.productionNumbers && this.productionNumbers.constructor === Array) {
      data["ProductionNumbers"] = [];
      for (let item of this.productionNumbers)
        data["ProductionNumbers"].push(item);
    }
    if (this.selfReportedVins && this.selfReportedVins.constructor === Array) {
      data["SelfReportedVins"] = [];
      for (let item of this.selfReportedVins)
        data["SelfReportedVins"].push(item);
    }
    if (this.financialProducts && this.financialProducts.constructor === Array) {
      data["FinancialProducts"] = [];
      for (let item of this.financialProducts)
        data["FinancialProducts"].push(item.toJSON());
    }
    if (this.salesCompanyAddresses && this.salesCompanyAddresses.constructor === Array) {
      data["SalesCompanyAddresses"] = [];
      for (let item of this.salesCompanyAddresses)
        data["SalesCompanyAddresses"].push(item.toJSON());
    }
    if (this.phoneNumbers && this.phoneNumbers.constructor === Array) {
      data["PhoneNumbers"] = [];
      for (let item of this.phoneNumbers)
        data["PhoneNumbers"].push(item.toJSON());
    }
    if (this.emailAddresses && this.emailAddresses.constructor === Array) {
      data["EmailAddresses"] = [];
      for (let item of this.emailAddresses)
        data["EmailAddresses"].push(item.toJSON());
    }
    if (this.organizations && this.organizations.constructor === Array) {
      data["Organizations"] = [];
      for (let item of this.organizations)
        data["Organizations"].push(item);
    }
    if (this.privacyAttributes && this.privacyAttributes.constructor === Array) {
      data["PrivacyAttributes"] = [];
      for (let item of this.privacyAttributes)
        data["PrivacyAttributes"].push(item.toJSON());
    }
    if (this.canadaVehicleRecalls && this.canadaVehicleRecalls.constructor === Array) {
      data["CanadaVehicleRecalls"] = [];
      for (let item of this.canadaVehicleRecalls)
        data["CanadaVehicleRecalls"].push(item.toJSON());
    }
    if (this.preferredRetailers && this.preferredRetailers.constructor === Array) {
      data["PreferredRetailers"] = [];
      for (let item of this.preferredRetailers)
        data["PreferredRetailers"].push(item.toJSON());
    }
    if (this.purchaseIntents && this.purchaseIntents.constructor === Array) {
      data["PurchaseIntents"] = [];
      for (let item of this.purchaseIntents)
        data["PurchaseIntents"].push(item.toJSON());
    }
    if (this.modelsOfInteres && this.modelsOfInteres.constructor === Array) {
      data["ModelsOfInteres"] = [];
      for (let item of this.modelsOfInteres)
        data["ModelsOfInteres"].push(item.toJSON());
    }
    data["PrimaryOrganization"] = this.primaryOrganization;
    if (this.relatedRetailers && this.relatedRetailers.constructor === Array) {
      data["RelatedRetailers"] = [];
      for (let item of this.relatedRetailers)
        data["RelatedRetailers"].push(item.toJSON());
    }
    data["LastUpdatedBy"] = this.lastUpdatedBy;
    data["LastUpdatedBySystem"] = this.lastUpdatedBySystem;
    data["LastUpdatedByDate"] = this.lastUpdatedByDate ? this.lastUpdatedByDate.toISOString() : <any>undefined;
    data["PreferredLanguage"] = this.preferredLanguage;
    return data;
  }
}

export interface IContactDTO {
  contactId?: string | undefined;
  eDBCustomerNumber?: number | undefined;
  firstName?: string | undefined;
  middleName?: string | undefined;
  surname?: string | undefined;
  lastName?: string | undefined;
  isFsCustomer?: boolean | undefined;
  suffix?: string | undefined;
  salutation?: string | undefined;
  nickname?: string | undefined;
  gender?: string | undefined;
  preferredName?: string | undefined;
  disableNameCleansing?: boolean | undefined;
  companyIndicator?: string | undefined;
  status?: string | undefined;
  dateOfBirth?: Date | undefined;
  driversLicense?: string | undefined;
  driversLicenseExpiryDate?: Date | undefined;
  driversLicenseState?: string | undefined;
  employer?: string | undefined;
  income?: string | undefined;
  occupation?: string | undefined;
  jobTitle?: string | undefined;
  mealPreference?: string | undefined;
  allergyDescription?: string | undefined;
  magazineSubscription?: MagazineSubscriptionDTO[] | undefined;
  gCDMRelationships?: GCDMDTO[] | undefined;
  vehicles?: VehicleDTO[] | undefined;
  productionNumbers?: string[] | undefined;
  selfReportedVins?: string[] | undefined;
  financialProducts?: FinancialProductDTO[] | undefined;
  salesCompanyAddresses?: AddressDTO[] | undefined;
  phoneNumbers?: PhoneNumberDTO2[] | undefined;
  emailAddresses?: EmailAddressDTO[] | undefined;
  organizations?: Organizations[] | undefined;
  privacyAttributes?: PrivacyAttributeDTO[] | undefined;
  canadaVehicleRecalls?: VehicleRecallDTO[] | undefined;
  preferredRetailers?: PreferredRetailerDTO[] | undefined;
  purchaseIntents?: PurchaseIntentDTO[] | undefined;
  modelsOfInteres?: ModelOfInterestDTO[] | undefined;
  primaryOrganization?: ContactDTOPrimaryOrganization | undefined;
  relatedRetailers?: ContactRetailerDTO[] | undefined;
  lastUpdatedBy?: string | undefined;
  lastUpdatedBySystem?: string | undefined;
  lastUpdatedByDate?: Date | undefined;
  preferredLanguage?: string | undefined;
}

export class ContactRetailerDTO implements IContactRetailerDTO {
  retailerExternalId?: string | undefined;
  contactExternalId?: string | undefined;
  bmwDealerId?: string | undefined;
  contactRetailerStatus?: string | undefined;
  startDate?: Date | undefined;
  salesRepresentativePassportId?: string | undefined;
  bMWiIndicator?: boolean | undefined;
  retailerPrivacyList?: RetailerPrivacyDTO[] | undefined;

  constructor(data?: IContactRetailerDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.retailerExternalId = data["RetailerExternalId"];
      this.contactExternalId = data["ContactExternalId"];
      this.bmwDealerId = data["BmwDealerId"];
      this.contactRetailerStatus = data["ContactRetailerStatus"];
      this.startDate = data["StartDate"] ? new Date(data["StartDate"].toString()) : <any>undefined;
      this.salesRepresentativePassportId = data["SalesRepresentativePassportId"];
      this.bMWiIndicator = data["BMWiIndicator"];
      if (data["RetailerPrivacyList"] && data["RetailerPrivacyList"].constructor === Array) {
        this.retailerPrivacyList = [];
        for (let item of data["RetailerPrivacyList"])
          this.retailerPrivacyList.push(RetailerPrivacyDTO.fromJS(item));
      }
    }
  }

  static fromJS(data: any): ContactRetailerDTO {
    data = typeof data === 'object' ? data : {};
    let result = new ContactRetailerDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["RetailerExternalId"] = this.retailerExternalId;
    data["ContactExternalId"] = this.contactExternalId;
    data["BmwDealerId"] = this.bmwDealerId;
    data["ContactRetailerStatus"] = this.contactRetailerStatus;
    data["StartDate"] = this.startDate ? this.startDate.toISOString() : <any>undefined;
    data["SalesRepresentativePassportId"] = this.salesRepresentativePassportId;
    data["BMWiIndicator"] = this.bMWiIndicator;
    if (this.retailerPrivacyList && this.retailerPrivacyList.constructor === Array) {
      data["RetailerPrivacyList"] = [];
      for (let item of this.retailerPrivacyList)
        data["RetailerPrivacyList"].push(item.toJSON());
    }
    return data;
  }
}

export interface IContactRetailerDTO {
  retailerExternalId?: string | undefined;
  contactExternalId?: string | undefined;
  bmwDealerId?: string | undefined;
  contactRetailerStatus?: string | undefined;
  startDate?: Date | undefined;
  salesRepresentativePassportId?: string | undefined;
  bMWiIndicator?: boolean | undefined;
  retailerPrivacyList?: RetailerPrivacyDTO[] | undefined;
}

export class RetailerPrivacyDTO implements IRetailerPrivacyDTO {
  retailerPrivacyType?: RetailerPrivacyDTORetailerPrivacyType | undefined;
  retailerPrivacyIndicator?: boolean | undefined;

  constructor(data?: IRetailerPrivacyDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.retailerPrivacyType = data["RetailerPrivacyType"];
      this.retailerPrivacyIndicator = data["RetailerPrivacyIndicator"];
    }
  }

  static fromJS(data: any): RetailerPrivacyDTO {
    data = typeof data === 'object' ? data : {};
    let result = new RetailerPrivacyDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["RetailerPrivacyType"] = this.retailerPrivacyType;
    data["RetailerPrivacyIndicator"] = this.retailerPrivacyIndicator;
    return data;
  }
}

export interface IRetailerPrivacyDTO {
  retailerPrivacyType?: RetailerPrivacyDTORetailerPrivacyType | undefined;
  retailerPrivacyIndicator?: boolean | undefined;
}

export enum RetailerPrivacyDTORetailerPrivacyType {
  Phone = <any>"Phone",
  Mail = <any>"Mail",
  Email = <any>"Email",
  Other = <any>"Other",
}
export enum ContactDTOPrimaryOrganization {
  DefaultOrganization = <any>"DefaultOrganization",
  BMWFSUS = <any>"BMWFSUS",
  BMWFSCAN = <any>"BMWFSCAN",
  BMWCA = <any>"BMWCA",
  BMWNA = <any>"BMWNA",
  BMWFS = <any>"BMWFS",
  BMWMX = <any>"BMWMX",
  BMWBR = <any>"BMWBR",
  ParkNow = <any>"ParkNow",
  BMWAR = <any>"BMWAR",
  ALL = <any>"ALL",
}
export class EmailAddressDTO implements IEmailAddressDTO {
  emailAddress?: string | undefined;
  emailAddressType?: EmailAddressDTOEmailAddressType | undefined;
  isPrimary?: boolean | undefined;
  isPrevious?: boolean | undefined;
  emailId?: string | undefined;
  bMWCommercialElectronicMessages?: string | undefined;
  mINICommercialElectronicMessages?: string | undefined;
  externalUpdateSource?: string | undefined;
  externalSourceUpdateDt?: string | undefined;

  constructor(data?: IEmailAddressDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.emailAddress = data["EmailAddress"];
      this.emailAddressType = data["EmailAddressType"];
      this.isPrimary = data["IsPrimary"];
      this.isPrevious = data["IsPrevious"];
      this.emailId = data["EmailId"];
      this.bMWCommercialElectronicMessages = data["BMWCommercialElectronicMessages"];
      this.mINICommercialElectronicMessages = data["MINICommercialElectronicMessages"];
      this.externalUpdateSource = data["ExternalUpdateSource"];
      this.externalSourceUpdateDt = data["ExternalSourceUpdateDt"];
    }
  }

  static fromJS(data: any): EmailAddressDTO {
    data = typeof data === 'object' ? data : {};
    let result = new EmailAddressDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["EmailAddress"] = this.emailAddress;
    data["EmailAddressType"] = this.emailAddressType;
    data["IsPrimary"] = this.isPrimary;
    data["IsPrevious"] = this.isPrevious;
    data["EmailId"] = this.emailId;
    data["BMWCommercialElectronicMessages"] = this.bMWCommercialElectronicMessages;
    data["MINICommercialElectronicMessages"] = this.mINICommercialElectronicMessages;
    data["ExternalUpdateSource"] = this.externalUpdateSource;
    data["ExternalSourceUpdateDt"] = this.externalSourceUpdateDt;
    return data;
  }
}

export enum Organizations {
  DefaultOrganization = <any>"DefaultOrganization",
  BMWFSUS = <any>"BMWFSUS",
  BMWFSCAN = <any>"BMWFSCAN",
  BMWCA = <any>"BMWCA",
  BMWNA = <any>"BMWNA",
  BMWFS = <any>"BMWFS",
  BMWMX = <any>"BMWMX",
  BMWBR = <any>"BMWBR",
  ParkNow = <any>"ParkNow",
  BMWAR = <any>"BMWAR",
  ALL = <any>"ALL",
}
export interface IEmailAddressDTO {
  emailAddress?: string | undefined;
  emailAddressType?: EmailAddressDTOEmailAddressType | undefined;
  isPrimary?: boolean | undefined;
  isPrevious?: boolean | undefined;
  emailId?: string | undefined;
  bMWCommercialElectronicMessages?: string | undefined;
  mINICommercialElectronicMessages?: string | undefined;
  externalUpdateSource?: string | undefined;
  externalSourceUpdateDt?: string | undefined;
}

export enum EmailAddressDTOEmailAddressType {
  Billing = <any>"Billing",
  Home = <any>"Home",
  Marketing = <any>"Marketing",
  Other = <any>"Other",
  Work = <any>"Work",
}

export class PrivacyAttributeDTO implements IPrivacyAttributeDTO {
  privacyAttributeIndicator?: boolean | undefined;
  privacyAttributeType?: PrivacyAttributeDTOPrivacyAttributeType | undefined;
  countryCode?: CountryCode | undefined;
  organizationType?: PrivacyAttributeDTOOrganizationType | undefined;
  brandType?: PrivacyAttributeDTOBrandType | undefined;
  channelType?: PrivacyAttributeDTOChannelType | undefined;

  constructor(data?: IPrivacyAttributeDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.privacyAttributeIndicator = data["PrivacyAttributeIndicator"];
      this.privacyAttributeType = data["PrivacyAttributeType"];
      this.countryCode = data["CountryCode"] ? CountryCode.fromJS(data["CountryCode"]) : <any>undefined;
      this.organizationType = data["OrganizationType"];
      this.brandType = data["BrandType"];
      this.channelType = data["ChannelType"];
    }
  }

  static fromJS(data: any): PrivacyAttributeDTO {
    data = typeof data === 'object' ? data : {};
    let result = new PrivacyAttributeDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["PrivacyAttributeIndicator"] = this.privacyAttributeIndicator;
    data["PrivacyAttributeType"] = this.privacyAttributeType;
    data["CountryCode"] = this.countryCode ? this.countryCode.toJSON() : <any>undefined;
    data["OrganizationType"] = this.organizationType;
    data["BrandType"] = this.brandType;
    data["ChannelType"] = this.channelType;
    return data;
  }
}

export interface IPrivacyAttributeDTO {
  privacyAttributeIndicator?: boolean | undefined;
  privacyAttributeType?: PrivacyAttributeDTOPrivacyAttributeType | undefined;
  countryCode?: CountryCode | undefined;
  organizationType?: PrivacyAttributeDTOOrganizationType | undefined;
  brandType?: PrivacyAttributeDTOBrandType | undefined;
  channelType?: PrivacyAttributeDTOChannelType | undefined;
}


export enum PrivacyAttributeDTOPrivacyAttributeType {
  CANBMWGroupEventsAndPromotions = <any>"CANBMWGroupEventsAndPromotions",
  CANBMWGroupRetailerPromotions = <any>"CANBMWGroupRetailerPromotions",
  CANCustomerSatisfactionSurvey = <any>"CANCustomerSatisfactionSurvey",
  CANMarketingSurvey = <any>"CANMarketingSurvey",
  CANBMWMagazinePackage = <any>"CANBMWMagazinePackage",
  CANMINIGroupSpecialEventsAndPromotions = <any>"CANMINIGroupSpecialEventsAndPromotions",
  CANMINIGroupRetailerPromotions = <any>"CANMINIGroupRetailerPromotions",
  CANMINISatisfactionSurveys = <any>"CANMINISatisfactionSurveys",
  CANMINIMarketingSurveys = <any>"CANMINIMarketingSurveys",
  CANMINIMagazinePackage = <any>"CANMINIMagazinePackage",
  CANBMWCommercialElectronicMessages = <any>"CANBMWCommercialElectronicMessages",
  CANMINICommercialElectronicMessages = <any>"CANMINICommercialElectronicMessages",
  USAAutomotiveFinancing = <any>"USAAutomotiveFinancing",
  USABanking = <any>"USABanking",
  USACreditCards = <any>"USACreditCards",
  USAESCOrigOwnerProtectionProg = <any>"USAESCOrigOwnerProtectionProg",
  USAEvents = <any>"USAEvents",
  USAInsurance = <any>"USAInsurance",
  USALifestyle = <any>"USALifestyle",
  USAMotorsports = <any>"USAMotorsports",
  USANewsletter = <any>"USANewsletter",
  USAOnlineServices = <any>"USAOnlineServices",
  USAPerformanceCenter = <any>"USAPerformanceCenter",
  USASurveys = <any>"USASurveys",
  USAVehicleAccessoriesInfo = <any>"USAVehicleAccessoriesInfo",
  USAVehicleProductInfo = <any>"USAVehicleProductInfo",
  USAActiveE = <any>"USAActiveE",
  USAAllMarketingCommunications = <any>"USAAllMarketingCommunications",
  USALifestylesAccessories = <any>"USALifestylesAccessories",
  USANewsProductsOffers = <any>"USANewsProductsOffers",
  USANewsletters = <any>"USANewsletters",
  USAProductsandOffers = <any>"USAProductsandOffers",
  USAServiceAlerts = <any>"USAServiceAlerts",
  USATeleservices = <any>"USATeleservices",
  MEXMarketing = <any>"MEXMarketing",
}

export enum PrivacyAttributeDTOOrganizationType {
  DefaultOrganization = <any>"DefaultOrganization",
  BMWFSUS = <any>"BMWFSUS",
  BMWFSCAN = <any>"BMWFSCAN",
  BMWCA = <any>"BMWCA",
  BMWNA = <any>"BMWNA",
  BMWFS = <any>"BMWFS",
  BMWMX = <any>"BMWMX",
  BMWBR = <any>"BMWBR",
  ParkNow = <any>"ParkNow",
  BMWAR = <any>"BMWAR",
  ALL = <any>"ALL",
}

export enum PrivacyAttributeDTOBrandType {
  BMW = <any>"BMW",
  LANDROVER = <any>"LANDROVER",
  MINI = <any>"MINI",
  Alphera = <any>"Alphera",
  Bank = <any>"Bank",
  RollsRoyce = <any>"RollsRoyce",
  Up2Drive = <any>"Up2Drive",
  Motorrad = <any>"Motorrad",
  All = <any>"All",
}

export enum PrivacyAttributeDTOChannelType {
  Email = <any>"Email",
  WhiteMail = <any>"WhiteMail",
  HomePhone = <any>"HomePhone",
  WorkPhone = <any>"WorkPhone",
  CellPhone = <any>"CellPhone",
  All = <any>"All",
  Phone = <any>"Phone",
  MobileApp = <any>"MobileApp",
}

export class VehicleRecallDTO implements IVehicleRecallDTO {
  completionDate?: Date | undefined;
  campaignContactStatus?: string | undefined;
  contactId?: string | undefined;
  vehicleId?: string | undefined;
  campaignId?: string | undefined;
  campaignName?: string | undefined;
  campaignType?: string | undefined;
  campaignStatus?: string | undefined;
  campaignBulletin?: string | undefined;
  causeDescription?: string | undefined;
  complaintDescription?: string | undefined;
  estimatedTime?: number | undefined;

  constructor(data?: IVehicleRecallDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.completionDate = data["CompletionDate"] ? new Date(data["CompletionDate"].toString()) : <any>undefined;
      this.campaignContactStatus = data["CampaignContactStatus"];
      this.contactId = data["ContactId"];
      this.vehicleId = data["VehicleId"];
      this.campaignId = data["CampaignId"];
      this.campaignName = data["CampaignName"];
      this.campaignType = data["CampaignType"];
      this.campaignStatus = data["CampaignStatus"];
      this.campaignBulletin = data["CampaignBulletin"];
      this.causeDescription = data["CauseDescription"];
      this.complaintDescription = data["ComplaintDescription"];
      this.estimatedTime = data["EstimatedTime"];
    }
  }

  static fromJS(data: any): VehicleRecallDTO {
    data = typeof data === 'object' ? data : {};
    let result = new VehicleRecallDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["CompletionDate"] = this.completionDate ? this.completionDate.toISOString() : <any>undefined;
    data["CampaignContactStatus"] = this.campaignContactStatus;
    data["ContactId"] = this.contactId;
    data["VehicleId"] = this.vehicleId;
    data["CampaignId"] = this.campaignId;
    data["CampaignName"] = this.campaignName;
    data["CampaignType"] = this.campaignType;
    data["CampaignStatus"] = this.campaignStatus;
    data["CampaignBulletin"] = this.campaignBulletin;
    data["CauseDescription"] = this.causeDescription;
    data["ComplaintDescription"] = this.complaintDescription;
    data["EstimatedTime"] = this.estimatedTime;
    return data;
  }
}

export interface IVehicleRecallDTO {
  completionDate?: Date | undefined;
  campaignContactStatus?: string | undefined;
  contactId?: string | undefined;
  vehicleId?: string | undefined;
  campaignId?: string | undefined;
  campaignName?: string | undefined;
  campaignType?: string | undefined;
  campaignStatus?: string | undefined;
  campaignBulletin?: string | undefined;
  causeDescription?: string | undefined;
  complaintDescription?: string | undefined;
  estimatedTime?: number | undefined;
}

export class PreferredRetailerDTO implements IPreferredRetailerDTO {
  dealerNumber?: string | undefined;
  dealerId?: string | undefined;
  franchiseType?: PreferredRetailerDTOFranchiseType | undefined;
  locationId?: string | undefined;
  lineMakeId?: number | undefined;
  dealerType?: PreferredRetailerDTODealerType | undefined;

  constructor(data?: IPreferredRetailerDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.dealerNumber = data["DealerNumber"];
      this.dealerId = data["DealerId"];
      this.franchiseType = data["FranchiseType"];
      this.locationId = data["LocationId"];
      this.lineMakeId = data["LineMakeId"];
      this.dealerType = data["DealerType"];
    }
  }

  static fromJS(data: any): PreferredRetailerDTO {
    data = typeof data === 'object' ? data : {};
    let result = new PreferredRetailerDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["DealerNumber"] = this.dealerNumber;
    data["DealerId"] = this.dealerId;
    data["FranchiseType"] = this.franchiseType;
    data["LocationId"] = this.locationId;
    data["LineMakeId"] = this.lineMakeId;
    data["DealerType"] = this.dealerType;
    return data;
  }
}

export interface IPreferredRetailerDTO {
  dealerNumber?: string | undefined;
  dealerId?: string | undefined;
  franchiseType?: PreferredRetailerDTOFranchiseType | undefined;
  locationId?: string | undefined;
  lineMakeId?: number | undefined;
  dealerType?: PreferredRetailerDTODealerType | undefined;
}
export enum PreferredRetailerDTOFranchiseType {
  Automobile = <any>"Automobile",
  Mini = <any>"Mini",
  Motorcycle = <any>"Motorcycle",
  Alphera = <any>"Alphera",
  Up2drive = <any>"up2drive",
}

export enum PreferredRetailerDTODealerType {
  Sales = <any>"Sales",
  Service = <any>"Service",
}
export class PurchaseIntentDTO implements IPurchaseIntentDTO {
  purchaseIntentRangeType?: PurchaseIntentDTOPurchaseIntentRangeType | undefined;
  purchaseIntentDate?: Date | undefined;
  brandType?: PurchaseIntentDTOBrandType | undefined;

  constructor(data?: IPurchaseIntentDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.purchaseIntentRangeType = data["PurchaseIntentRangeType"];
      this.purchaseIntentDate = data["PurchaseIntentDate"] ? new Date(data["PurchaseIntentDate"].toString()) : <any>undefined;
      this.brandType = data["BrandType"];
    }
  }

  static fromJS(data: any): PurchaseIntentDTO {
    data = typeof data === 'object' ? data : {};
    let result = new PurchaseIntentDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["PurchaseIntentRangeType"] = this.purchaseIntentRangeType;
    data["PurchaseIntentDate"] = this.purchaseIntentDate ? this.purchaseIntentDate.toISOString() : <any>undefined;
    data["BrandType"] = this.brandType;
    return data;
  }
}

export interface IPurchaseIntentDTO {
  purchaseIntentRangeType?: PurchaseIntentDTOPurchaseIntentRangeType | undefined;
  purchaseIntentDate?: Date | undefined;
  brandType?: PurchaseIntentDTOBrandType | undefined;
}

export enum PurchaseIntentDTOPurchaseIntentRangeType {
  ZeroToThreeMonths = <any>"ZeroToThreeMonths",
  FourToSixMonths = <any>"FourToSixMonths",
  SeventToTwelveMonths = <any>"SeventToTwelveMonths",
  Over12Months = <any>"Over12Months",
  Undecided = <any>"Undecided",
}

export enum PurchaseIntentDTOBrandType {
  BMW = <any>"BMW",
  LANDROVER = <any>"LANDROVER",
  MINI = <any>"MINI",
  Alphera = <any>"Alphera",
  Bank = <any>"Bank",
  RollsRoyce = <any>"RollsRoyce",
  Up2Drive = <any>"Up2Drive",
  Motorrad = <any>"Motorrad",
  All = <any>"All",
}

export class ModelOfInterestDTO implements IModelOfInterestDTO {
  id?: string | undefined;
  make?: string | undefined;
  aGBrand?: string | undefined;
  modelYear?: string | undefined;
  series?: string | undefined;
  nAModelCode?: string | undefined;
  modelDescription?: string | undefined;
  timeToPurchase?: string | undefined;

  constructor(data?: IModelOfInterestDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data["Id"];
      this.make = data["Make"];
      this.aGBrand = data["AGBrand"];
      this.modelYear = data["ModelYear"];
      this.series = data["Series"];
      this.nAModelCode = data["NAModelCode"];
      this.modelDescription = data["ModelDescription"];
      this.timeToPurchase = data["TimeToPurchase"];
    }
  }

  static fromJS(data: any): ModelOfInterestDTO {
    data = typeof data === 'object' ? data : {};
    let result = new ModelOfInterestDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["Id"] = this.id;
    data["Make"] = this.make;
    data["AGBrand"] = this.aGBrand;
    data["ModelYear"] = this.modelYear;
    data["Series"] = this.series;
    data["NAModelCode"] = this.nAModelCode;
    data["ModelDescription"] = this.modelDescription;
    data["TimeToPurchase"] = this.timeToPurchase;
    return data;
  }
}

export interface IModelOfInterestDTO {
  id?: string | undefined;
  make?: string | undefined;
  aGBrand?: string | undefined;
  modelYear?: string | undefined;
  series?: string | undefined;
  nAModelCode?: string | undefined;
  modelDescription?: string | undefined;
  timeToPurchase?: string | undefined;
}


export class MagazineSubscriptionDTO implements IMagazineSubscriptionDTO {
  expiryDate?: Date | undefined;
  brandType?: MagazineSubscriptionDTOBrandType | undefined;

  constructor(data?: IMagazineSubscriptionDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.expiryDate = data["ExpiryDate"] ? new Date(data["ExpiryDate"].toString()) : <any>undefined;
      this.brandType = data["BrandType"];
    }
  }

  static fromJS(data: any): MagazineSubscriptionDTO {
    data = typeof data === 'object' ? data : {};
    let result = new MagazineSubscriptionDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["ExpiryDate"] = this.expiryDate ? this.expiryDate.toISOString() : <any>undefined;
    data["BrandType"] = this.brandType;
    return data;
  }
}

export interface IMagazineSubscriptionDTO {
  expiryDate?: Date | undefined;
  brandType?: MagazineSubscriptionDTOBrandType | undefined;
}
export enum MagazineSubscriptionDTOBrandType {
  BMW = <any>"BMW",
  LANDROVER = <any>"LANDROVER",
  MINI = <any>"MINI",
  Alphera = <any>"Alphera",
  Bank = <any>"Bank",
  RollsRoyce = <any>"RollsRoyce",
  Up2Drive = <any>"Up2Drive",
  Motorrad = <any>"Motorrad",
  All = <any>"All",
}
export class GCDMDTO implements IGCDMDTO {
  gCID?: string | undefined;
  createDate?: Date | undefined;
  lastAccessDate?: Date | undefined;
  activationDate?: Date | undefined;
  cDEnabled?: boolean | undefined;
  gCDMStatusType?: GCDMDTOGCDMStatusType | undefined;
  gCDMSourceType?: GCDMDTOGCDMSourceType | undefined;
  gCDMLevelType?: GCDMDTOGCDMLevelType | undefined;
  gCDMChannels?: GCDMChannels[] | undefined;

  constructor(data?: IGCDMDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.gCID = data["GCID"];
      this.createDate = data["CreateDate"] ? new Date(data["CreateDate"].toString()) : <any>undefined;
      this.lastAccessDate = data["LastAccessDate"] ? new Date(data["LastAccessDate"].toString()) : <any>undefined;
      this.activationDate = data["ActivationDate"] ? new Date(data["ActivationDate"].toString()) : <any>undefined;
      this.cDEnabled = data["CDEnabled"];
      this.gCDMStatusType = data["GCDMStatusType"];
      this.gCDMSourceType = data["GCDMSourceType"];
      this.gCDMLevelType = data["GCDMLevelType"];
      if (data["GCDMChannels"] && data["GCDMChannels"].constructor === Array) {
        this.gCDMChannels = [];
        for (let item of data["GCDMChannels"])
          this.gCDMChannels.push(item);
      }
    }
  }

  static fromJS(data: any): GCDMDTO {
    data = typeof data === 'object' ? data : {};
    let result = new GCDMDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["GCID"] = this.gCID;
    data["CreateDate"] = this.createDate ? this.createDate.toISOString() : <any>undefined;
    data["LastAccessDate"] = this.lastAccessDate ? this.lastAccessDate.toISOString() : <any>undefined;
    data["ActivationDate"] = this.activationDate ? this.activationDate.toISOString() : <any>undefined;
    data["CDEnabled"] = this.cDEnabled;
    data["GCDMStatusType"] = this.gCDMStatusType;
    data["GCDMSourceType"] = this.gCDMSourceType;
    data["GCDMLevelType"] = this.gCDMLevelType;
    if (this.gCDMChannels && this.gCDMChannels.constructor === Array) {
      data["GCDMChannels"] = [];
      for (let item of this.gCDMChannels)
        data["GCDMChannels"].push(item);
    }
    return data;
  }
}

export interface IGCDMDTO {
  gCID?: string | undefined;
  createDate?: Date | undefined;
  lastAccessDate?: Date | undefined;
  activationDate?: Date | undefined;
  cDEnabled?: boolean | undefined;
  gCDMStatusType?: GCDMDTOGCDMStatusType | undefined;
  gCDMSourceType?: GCDMDTOGCDMSourceType | undefined;
  gCDMLevelType?: GCDMDTOGCDMLevelType | undefined;
  gCDMChannels?: GCDMChannels[] | undefined;
}


export enum GCDMDTOGCDMStatusType {
  NotActivated = <any>"NotActivated",
  Activated = <any>"Activated",
  Expired = <any>"Expired",
  Admin_Locked = <any>"Admin_Locked",
  External_Authenticated = <any>"External_Authenticated",
  Unconfirmed = <any>"Unconfirmed",
  Reminded = <any>"Reminded",
}

export enum GCDMDTOGCDMSourceType {
  BMWCA = <any>"BMWCA",
  BMWUSA_Website = <any>"BMWUSA_Website",
  BMWUSA_MobileApp = <any>"BMWUSA_MobileApp",
  CASA = <any>"CASA",
  ESABMW = <any>"ESABMW",
  ESAMINI = <any>"ESAMINI",
  CDFUPO = <any>"CDFUPO",
}

export enum GCDMDTOGCDMLevelType {
  Prospect = <any>"Prospect",
  Driver = <any>"Driver",
  DriverAndFinancialServices = <any>"DriverAndFinancialServices",
  FinancialServices = <any>"FinancialServices",
  BMWCA = <any>"BMWCA",
  ConnectedDrive = <any>"ConnectedDrive",
}

export enum GCDMChannels {
  WebUser = <any>"WebUser",
  AppUser = <any>"AppUser",
  ConnectedApp = <any>"ConnectedApp",
}



export class VehicleDTO implements IVehicleDTO {
  vIN?: string | undefined;
  productionNumber?: string | undefined;
  make?: string | undefined;
  model?: string | undefined;
  year?: string | undefined;
  rDRDate?: Date | undefined;
  inServiceDate?: Date | undefined;
  siriusSubscription?: SiriusSubscriptionDTO | undefined;
  assistSubscription?: AssistSubscriptionDTO | undefined;
  roadsideAssistanceSubscription?: RoadsideAssistanceSubscriptionDTO | undefined;
  protectionProductSubscriptions?: ProtectionProductSubscriptionDTO[] | undefined;
  ownershipRelationType?: VehicleDTOOwnershipRelationType | undefined;
  vehicleId?: string | undefined;
  servicePlans?: ServicePlanDTO[] | undefined;

  constructor(data?: IVehicleDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.vIN = data["VIN"];
      this.productionNumber = data["ProductionNumber"];
      this.make = data["Make"];
      this.model = data["Model"];
      this.year = data["Year"];
      this.rDRDate = data["RDRDate"] ? new Date(data["RDRDate"].toString()) : <any>undefined;
      this.inServiceDate = data["InServiceDate"] ? new Date(data["InServiceDate"].toString()) : <any>undefined;
      this.siriusSubscription = data["SiriusSubscription"] ? SiriusSubscriptionDTO.fromJS(data["SiriusSubscription"]) : <any>undefined;
      this.assistSubscription = data["AssistSubscription"] ? AssistSubscriptionDTO.fromJS(data["AssistSubscription"]) : <any>undefined;
      this.roadsideAssistanceSubscription = data["RoadsideAssistanceSubscription"] ? RoadsideAssistanceSubscriptionDTO.fromJS(data["RoadsideAssistanceSubscription"]) : <any>undefined;
      if (data["ProtectionProductSubscriptions"] && data["ProtectionProductSubscriptions"].constructor === Array) {
        this.protectionProductSubscriptions = [];
        for (let item of data["ProtectionProductSubscriptions"])
          this.protectionProductSubscriptions.push(ProtectionProductSubscriptionDTO.fromJS(item));
      }
      this.ownershipRelationType = data["OwnershipRelationType"];
      this.vehicleId = data["VehicleId"];
      if (data["ServicePlans"] && data["ServicePlans"].constructor === Array) {
        this.servicePlans = [];
        for (let item of data["ServicePlans"])
          this.servicePlans.push(ServicePlanDTO.fromJS(item));
      }
    }
  }

  static fromJS(data: any): VehicleDTO {
    data = typeof data === 'object' ? data : {};
    let result = new VehicleDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["VIN"] = this.vIN;
    data["ProductionNumber"] = this.productionNumber;
    data["Make"] = this.make;
    data["Model"] = this.model;
    data["Year"] = this.year;
    data["RDRDate"] = this.rDRDate ? this.rDRDate.toISOString() : <any>undefined;
    data["InServiceDate"] = this.inServiceDate ? this.inServiceDate.toISOString() : <any>undefined;
    data["SiriusSubscription"] = this.siriusSubscription ? this.siriusSubscription.toJSON() : <any>undefined;
    data["AssistSubscription"] = this.assistSubscription ? this.assistSubscription.toJSON() : <any>undefined;
    data["RoadsideAssistanceSubscription"] = this.roadsideAssistanceSubscription ? this.roadsideAssistanceSubscription.toJSON() : <any>undefined;
    if (this.protectionProductSubscriptions && this.protectionProductSubscriptions.constructor === Array) {
      data["ProtectionProductSubscriptions"] = [];
      for (let item of this.protectionProductSubscriptions)
        data["ProtectionProductSubscriptions"].push(item.toJSON());
    }
    data["OwnershipRelationType"] = this.ownershipRelationType;
    data["VehicleId"] = this.vehicleId;
    if (this.servicePlans && this.servicePlans.constructor === Array) {
      data["ServicePlans"] = [];
      for (let item of this.servicePlans)
        data["ServicePlans"].push(item.toJSON());
    }
    return data;
  }
}

export interface IVehicleDTO {
  vIN?: string | undefined;
  productionNumber?: string | undefined;
  make?: string | undefined;
  model?: string | undefined;
  year?: string | undefined;
  rDRDate?: Date | undefined;
  inServiceDate?: Date | undefined;
  siriusSubscription?: SiriusSubscriptionDTO | undefined;
  assistSubscription?: AssistSubscriptionDTO | undefined;
  roadsideAssistanceSubscription?: RoadsideAssistanceSubscriptionDTO | undefined;
  protectionProductSubscriptions?: ProtectionProductSubscriptionDTO[] | undefined;
  ownershipRelationType?: VehicleDTOOwnershipRelationType | undefined;
  vehicleId?: string | undefined;
  servicePlans?: ServicePlanDTO[] | undefined;
}
export enum VehicleDTOOwnershipRelationType {
  Driver = <any>"Driver",
  FormerDriver = <any>"FormerDriver",
  FormerOwner = <any>"FormerOwner",
  OwnerDriver = <any>"OwnerDriver",
  Owner = <any>"Owner",
}

export class SiriusSubscriptionDTO implements ISiriusSubscriptionDTO {
  subscriptionStatus?: string | undefined;
  effectiveDate?: Date | undefined;
  expiryDate?: Date | undefined;
  eSN?: string | undefined;

  constructor(data?: ISiriusSubscriptionDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.subscriptionStatus = data["SubscriptionStatus"];
      this.effectiveDate = data["EffectiveDate"] ? new Date(data["EffectiveDate"].toString()) : <any>undefined;
      this.expiryDate = data["ExpiryDate"] ? new Date(data["ExpiryDate"].toString()) : <any>undefined;
      this.eSN = data["ESN"];
    }
  }

  static fromJS(data: any): SiriusSubscriptionDTO {
    data = typeof data === 'object' ? data : {};
    let result = new SiriusSubscriptionDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["SubscriptionStatus"] = this.subscriptionStatus;
    data["EffectiveDate"] = this.effectiveDate ? this.effectiveDate.toISOString() : <any>undefined;
    data["ExpiryDate"] = this.expiryDate ? this.expiryDate.toISOString() : <any>undefined;
    data["ESN"] = this.eSN;
    return data;
  }
}

export interface ISiriusSubscriptionDTO {
  subscriptionStatus?: string | undefined;
  effectiveDate?: Date | undefined;
  expiryDate?: Date | undefined;
  eSN?: string | undefined;
}

export class AssistSubscriptionDTO implements IAssistSubscriptionDTO {
  subscriptionStatus?: string | undefined;
  effectiveDate?: Date | undefined;
  expiryDate?: Date | undefined;
  subscriberFirstName?: string | undefined;
  subscriberLastName?: string | undefined;
  assistLoginId?: string | undefined;

  constructor(data?: IAssistSubscriptionDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.subscriptionStatus = data["SubscriptionStatus"];
      this.effectiveDate = data["EffectiveDate"] ? new Date(data["EffectiveDate"].toString()) : <any>undefined;
      this.expiryDate = data["ExpiryDate"] ? new Date(data["ExpiryDate"].toString()) : <any>undefined;
      this.subscriberFirstName = data["SubscriberFirstName"];
      this.subscriberLastName = data["SubscriberLastName"];
      this.assistLoginId = data["AssistLoginId"];
    }
  }

  static fromJS(data: any): AssistSubscriptionDTO {
    data = typeof data === 'object' ? data : {};
    let result = new AssistSubscriptionDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["SubscriptionStatus"] = this.subscriptionStatus;
    data["EffectiveDate"] = this.effectiveDate ? this.effectiveDate.toISOString() : <any>undefined;
    data["ExpiryDate"] = this.expiryDate ? this.expiryDate.toISOString() : <any>undefined;
    data["SubscriberFirstName"] = this.subscriberFirstName;
    data["SubscriberLastName"] = this.subscriberLastName;
    data["AssistLoginId"] = this.assistLoginId;
    return data;
  }
}

export interface IAssistSubscriptionDTO {
  subscriptionStatus?: string | undefined;
  effectiveDate?: Date | undefined;
  expiryDate?: Date | undefined;
  subscriberFirstName?: string | undefined;
  subscriberLastName?: string | undefined;
  assistLoginId?: string | undefined;
}

export class RoadsideAssistanceSubscriptionDTO implements IRoadsideAssistanceSubscriptionDTO {
  effectiveDate?: Date | undefined;
  expiryDate?: Date | undefined;

  constructor(data?: IRoadsideAssistanceSubscriptionDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.effectiveDate = data["EffectiveDate"] ? new Date(data["EffectiveDate"].toString()) : <any>undefined;
      this.expiryDate = data["ExpiryDate"] ? new Date(data["ExpiryDate"].toString()) : <any>undefined;
    }
  }

  static fromJS(data: any): RoadsideAssistanceSubscriptionDTO {
    data = typeof data === 'object' ? data : {};
    let result = new RoadsideAssistanceSubscriptionDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["EffectiveDate"] = this.effectiveDate ? this.effectiveDate.toISOString() : <any>undefined;
    data["ExpiryDate"] = this.expiryDate ? this.expiryDate.toISOString() : <any>undefined;
    return data;
  }
}

export interface IRoadsideAssistanceSubscriptionDTO {
  effectiveDate?: Date | undefined;
  expiryDate?: Date | undefined;
}

export class ProtectionProductSubscriptionDTO implements IProtectionProductSubscriptionDTO {
  protectionProductType?: ProtectionProductSubscriptionDTOProtectionProductType | undefined;
  expiryDate?: Date | undefined;

  constructor(data?: IProtectionProductSubscriptionDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.protectionProductType = data["ProtectionProductType"];
      this.expiryDate = data["ExpiryDate"] ? new Date(data["ExpiryDate"].toString()) : <any>undefined;
    }
  }

  static fromJS(data: any): ProtectionProductSubscriptionDTO {
    data = typeof data === 'object' ? data : {};
    let result = new ProtectionProductSubscriptionDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["ProtectionProductType"] = this.protectionProductType;
    data["ExpiryDate"] = this.expiryDate ? this.expiryDate.toISOString() : <any>undefined;
    return data;
  }
}

export interface IProtectionProductSubscriptionDTO {
  protectionProductType?: ProtectionProductSubscriptionDTOProtectionProductType | undefined;
  expiryDate?: Date | undefined;
}

export enum ProtectionProductSubscriptionDTOProtectionProductType {
  TireAndRim = <any>"TireAndRim",
  AppearanceProtection = <any>"AppearanceProtection",
  CreditorLifeDisability = <any>"CreditorLifeDisability",
  ExcessWearAndTear = <any>"ExcessWearAndTear",
  ExtendedWarranty = <any>"ExtendedWarranty",
  Other3rdPartyProduct = <any>"Other3rdPartyProduct",
  ServiceInclusive = <any>"ServiceInclusive",
  ServiceInclusivePlus = <any>"ServiceInclusivePlus",
  LoanProtection = <any>"LoanProtection",
  DebtWaiver = <any>"DebtWaiver",
  AntiTheftProtection = <any>"AntiTheftProtection",
}


export class ServicePlanDTO implements IServicePlanDTO {
  planName?: string | undefined;
  contractType?: string | undefined;
  contractNumber?: string | undefined;
  effectiveDate?: Date | undefined;
  declarationDate?: Date | undefined;
  planMonths?: number | undefined;
  planKms?: number | undefined;
  planType?: string | undefined;
  contractValue?: number | undefined;
  declarationKms?: number | undefined;
  effectiveKms?: number | undefined;
  contractStatus?: string | undefined;
  optionCode?: string | undefined;

  constructor(data?: IServicePlanDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.planName = data["PlanName"];
      this.contractType = data["ContractType"];
      this.contractNumber = data["ContractNumber"];
      this.effectiveDate = data["EffectiveDate"] ? new Date(data["EffectiveDate"].toString()) : <any>undefined;
      this.declarationDate = data["DeclarationDate"] ? new Date(data["DeclarationDate"].toString()) : <any>undefined;
      this.planMonths = data["PlanMonths"];
      this.planKms = data["PlanKms"];
      this.planType = data["PlanType"];
      this.contractValue = data["ContractValue"];
      this.declarationKms = data["DeclarationKms"];
      this.effectiveKms = data["EffectiveKms"];
      this.contractStatus = data["ContractStatus"];
      this.optionCode = data["OptionCode"];
    }
  }

  static fromJS(data: any): ServicePlanDTO {
    data = typeof data === 'object' ? data : {};
    let result = new ServicePlanDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["PlanName"] = this.planName;
    data["ContractType"] = this.contractType;
    data["ContractNumber"] = this.contractNumber;
    data["EffectiveDate"] = this.effectiveDate ? this.effectiveDate.toISOString() : <any>undefined;
    data["DeclarationDate"] = this.declarationDate ? this.declarationDate.toISOString() : <any>undefined;
    data["PlanMonths"] = this.planMonths;
    data["PlanKms"] = this.planKms;
    data["PlanType"] = this.planType;
    data["ContractValue"] = this.contractValue;
    data["DeclarationKms"] = this.declarationKms;
    data["EffectiveKms"] = this.effectiveKms;
    data["ContractStatus"] = this.contractStatus;
    data["OptionCode"] = this.optionCode;
    return data;
  }
}

export interface IServicePlanDTO {
  planName?: string | undefined;
  contractType?: string | undefined;
  contractNumber?: string | undefined;
  effectiveDate?: Date | undefined;
  declarationDate?: Date | undefined;
  planMonths?: number | undefined;
  planKms?: number | undefined;
  planType?: string | undefined;
  contractValue?: number | undefined;
  declarationKms?: number | undefined;
  effectiveKms?: number | undefined;
  contractStatus?: string | undefined;
  optionCode?: string | undefined;
}


export class FinancialProductDTO implements IFinancialProductDTO {
  financialProductId?: string | undefined;
  financialProductSystemKey?: string | undefined;
  financialProductType?: FinancialProductDTOFinancialProductType | undefined;
  selfServiceAuthorized?: boolean | undefined;
  contractAccountDetail?: ContractAccountDetailDTO | undefined;
  financialProductVin?: string | undefined;
  vehicleImageData?: string | undefined;

  constructor(data?: IFinancialProductDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.financialProductId = data["FinancialProductId"];
      this.financialProductSystemKey = data["FinancialProductSystemKey"];
      this.financialProductType = data["FinancialProductType"];
      this.selfServiceAuthorized = data["SelfServiceAuthorized"];
      this.contractAccountDetail = data["ContractAccountDetail"] ? ContractAccountDetailDTO.fromJS(data["ContractAccountDetail"]) : <any>undefined;
      this.financialProductVin = data["FinancialProductVin"];
      this.vehicleImageData = data["VehicleImageData"];
    }
  }

  static fromJS(data: any): FinancialProductDTO {
    data = typeof data === 'object' ? data : {};
    let result = new FinancialProductDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["FinancialProductId"] = this.financialProductId;
    data["FinancialProductSystemKey"] = this.financialProductSystemKey;
    data["FinancialProductType"] = this.financialProductType;
    data["SelfServiceAuthorized"] = this.selfServiceAuthorized;
    data["ContractAccountDetail"] = this.contractAccountDetail ? this.contractAccountDetail.toJSON() : <any>undefined;
    data["FinancialProductVin"] = this.financialProductVin;
    data["VehicleImageData"] = this.vehicleImageData;
    return data;
  }
}

export interface IFinancialProductDTO {
  financialProductId?: string | undefined;
  financialProductSystemKey?: string | undefined;
  financialProductType?: FinancialProductDTOFinancialProductType | undefined;
  selfServiceAuthorized?: boolean | undefined;
  contractAccountDetail?: ContractAccountDetailDTO | undefined;
  financialProductVin?: string | undefined;
  vehicleImageData?: string | undefined;
}

export enum FinancialProductDTOFinancialProductType {
  DirectRefinance = <any>"DirectRefinance",
  DirectRetail = <any>"DirectRetail",
  FlexLease = <any>"FlexLease",
  IndirectRefinance = <any>"IndirectRefinance",
  Lease = <any>"Lease",
  LeaseExtension = <any>"LeaseExtension",
  MiniMotoringLease = <any>"MiniMotoringLease",
  OwnersChoice = <any>"OwnersChoice",
  OwnersChoiceExtension = <any>"OwnersChoiceExtension",
  PrePayLease = <any>"PrePayLease",
  PrePayReLease = <any>"PrePayReLease",
  ReLease = <any>"ReLease",
  Retail = <any>"Retail",
  Select = <any>"Select",
  ServiceLoaner = <any>"ServiceLoaner",
}


export class AddressDTO implements IAddressDTO {
  streetNumber?: string | undefined;
  address1?: string | undefined;
  address2?: string | undefined;
  address3?: string | undefined;
  city?: string | undefined;
  state?: string | undefined;
  postalCode?: string | undefined;
  country?: string | undefined;
  county?: string | undefined;
  addressTypes?: AddressTypes[] | undefined;
  isBusinessAddress?: boolean | undefined;
  externalUpdateSource?: string | undefined;
  externalSourceUpdateDt?: string | undefined;
  mailReturnedFlag?: boolean | undefined;

  constructor(data?: IAddressDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.streetNumber = data["StreetNumber"];
      this.address1 = data["Address1"];
      this.address2 = data["Address2"];
      this.address3 = data["Address3"];
      this.city = data["City"];
      this.state = data["State"];
      this.postalCode = data["PostalCode"];
      this.country = data["Country"];
      this.county = data["County"];
      if (data["AddressTypes"] && data["AddressTypes"].constructor === Array) {
        this.addressTypes = [];
        for (let item of data["AddressTypes"])
          this.addressTypes.push(item);
      }
      this.isBusinessAddress = data["IsBusinessAddress"];
      this.externalUpdateSource = data["ExternalUpdateSource"];
      this.externalSourceUpdateDt = data["ExternalSourceUpdateDt"];
      this.mailReturnedFlag = data["MailReturnedFlag"];
    }
  }

  static fromJS(data: any): AddressDTO {
    data = typeof data === 'object' ? data : {};
    let result = new AddressDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["StreetNumber"] = this.streetNumber;
    data["Address1"] = this.address1;
    data["Address2"] = this.address2;
    data["Address3"] = this.address3;
    data["City"] = this.city;
    data["State"] = this.state;
    data["PostalCode"] = this.postalCode;
    data["Country"] = this.country;
    data["County"] = this.county;
    if (this.addressTypes && this.addressTypes.constructor === Array) {
      data["AddressTypes"] = [];
      for (let item of this.addressTypes)
        data["AddressTypes"].push(item);
    }
    data["IsBusinessAddress"] = this.isBusinessAddress;
    data["ExternalUpdateSource"] = this.externalUpdateSource;
    data["ExternalSourceUpdateDt"] = this.externalSourceUpdateDt;
    data["MailReturnedFlag"] = this.mailReturnedFlag;
    return data;
  }
}

export interface IAddressDTO {
  streetNumber?: string | undefined;
  address1?: string | undefined;
  address2?: string | undefined;
  address3?: string | undefined;
  city?: string | undefined;
  state?: string | undefined;
  postalCode?: string | undefined;
  country?: string | undefined;
  county?: string | undefined;
  addressTypes?: AddressTypes[] | undefined;
  isBusinessAddress?: boolean | undefined;
  externalUpdateSource?: string | undefined;
  externalSourceUpdateDt?: string | undefined;
  mailReturnedFlag?: boolean | undefined;
}
export enum AddressTypes {
  Primary = <any>"1",
  Secondary = <any>"2",
  Driver = <any>"3",
  Payor = <any>"4",
  Assumee = <any>"5",
  OriginalPrimaryCustomerAfterAssumption = <any>"6",
  OriginalOtherCustomerAfterAssumption = <any>"7",
  PreviouslyLinkedCustomer = <any>"8",
  CoAssumee = <any>"9",
  Home = <any>"Home",
  Mailing = <any>"Mailing",
  Other = <any>"Other",
  Business = <any>"Business",
}

export class AccountPhoneNumberDTO implements IAccountPhoneNumberDTO {
  phoneNumber?: string | undefined;
  phoneNumberType?: AccountPhoneNumberDTOPhoneNumberType | undefined;

  constructor(data?: IAccountPhoneNumberDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.phoneNumber = data["PhoneNumber"];
      this.phoneNumberType = data["PhoneNumberType"];
    }
  }

  static fromJS(data: any): AccountPhoneNumberDTO {
    data = typeof data === 'object' ? data : {};
    let result = new AccountPhoneNumberDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["PhoneNumber"] = this.phoneNumber;
    data["PhoneNumberType"] = this.phoneNumberType;
    return data;
  }
}

export interface IAccountPhoneNumberDTO {
  phoneNumber?: string | undefined;
  phoneNumberType?: AccountPhoneNumberDTOPhoneNumberType | undefined;
}
export enum AccountPhoneNumberDTOPhoneNumberType {
  Alternate = <any>"Alternate",
  Assistant = <any>"Assistant",
  Cell = <any>"Cell",
  Fax = <any>"Fax",
  Home = <any>"Home",
  Work = <any>"Work",
  WorkFax = <any>"WorkFax",
  Cell2 = <any>"Cell2",
}


export enum ContractAccountDetailDTOContractType {
  Lease = <any>"1",
  Retail = <any>"2",
  OwnersChoiceRetail = <any>"3",
  LeasetoRetail = <any>"4",
  ReLease = <any>"5",
  ServiceLoaner = <any>"6",
  PrePayLease = <any>"7",
  LeaseExtension = <any>"8",
  OCExtension = <any>"11",
  PrePayReLease = <any>"12",
  FlexLease = <any>"13",
  Select = <any>"14",
  MiniMotoringLease = <any>"15",
  RetailRefiDirect = <any>"17",
  RetailRefiIndirect = <any>"18",
  StratLease = <any>"19",
  PrePayBuyback = <any>"PrePayBuyback",
  CDC = <any>"CDC",
  FinanceLease = <any>"FinanceLease",
}

export enum ContractAccountDetailDTOPaymentMethod {
  Undefined = <any>"Undefined",
  Invoice = <any>"Invoice",
  ACH = <any>"ACH",
  Offset = <any>"Offset",
  Check = <any>"Check",
  PostDatedCheck = <any>"PostDatedCheck",
}

export enum ContractAccountDetailDTOAccountStatus {
  Active = <any>"1",
  Terminated = <any>"2",
  Inv_Consigned = <any>"3",
  ChargeOff = <any>"4",
  ExtendedLease = <any>"5",
  Release = <any>"6",
  Sold = <any>"7",
  Refinance = <any>"8",
  ExpiringLoan = <any>"9",
  Inventory_ASP = <any>"10",
  Sold_ASP = <any>"11",
  Bankruptcy = <any>"12",
  Inventory_CTI = <any>"13",
  PendingNonPrimeSale = <any>"14",
  SoldToNonPrime = <any>"15",
  Inventory_2AP = <any>"16",
  Sold_2AP = <any>"17",
}

export enum ContractAccountDetailDTOStatusCategoryCode {
  Terminated = <any>"0",
  Active = <any>"1",
}


export class CountryCode implements ICountryCode {
  codeValue?: string | undefined;
  integerValue?: number | undefined;

  constructor(data?: ICountryCode) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.codeValue = data["CodeValue"];
      this.integerValue = data["IntegerValue"];
    }
  }

  static fromJS(data: any): CountryCode {
    data = typeof data === 'object' ? data : {};
    let result = new CountryCode();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["CodeValue"] = this.codeValue;
    data["IntegerValue"] = this.integerValue;
    return data;
  }
}

export interface ICountryCode {
  codeValue?: string | undefined;
  integerValue?: number | undefined;
}

export interface ICashAccountViewServiceClient {
  /**
   * @return OK
   */
  findAccounts(request: FindAccountsRequest): Observable<FindAccountsResponse>;
}


@Injectable()
export class CashAccountViewServiceClient implements ICashAccountViewServiceClient {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  constructor(private userService: UserService,@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : "https:///GatewayApiServices";
  }

  /**
   * @return OK
   */
  findAccounts(request: FindAccountsRequest): Observable<FindAccountsResponse> {
    let url_ = this.baseUrl + "/api/CashAccountViewService/FindAccounts";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(request);

    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };

    return this.http.request("post", url_, options_).pipe(mergeMap((response_: any) => {
      return this.processFindAccounts(response_);
    }),catchError((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processFindAccounts(<any>response_);
        } catch (e) {
          return <Observable<FindAccountsResponse>><any>throwError(()=>e);
        }
      } else
        return <Observable<FindAccountsResponse>><any>throwError(()=>response_);
    }));
  }

  protected processFindAccounts(response: HttpResponseBase): Observable<FindAccountsResponse> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = resultData200 ? FindAccountsResponse.fromJS(resultData200) : new FindAccountsResponse();
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of<FindAccountsResponse>(<any>null);
  }
}


export class FindAccountsRequest implements IFindAccountsRequest {
  customerNumber?: number | undefined;
  accountNumbers?: string[] | undefined;

  constructor(data?: IFindAccountsRequest) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.customerNumber = data["CustomerNumber"];
      if (data["AccountNumbers"] && data["AccountNumbers"].constructor === Array) {
        this.accountNumbers = [];
        for (let item of data["AccountNumbers"])
          this.accountNumbers.push(item);
      }
    }
  }

  static fromJS(data: any): FindAccountsRequest {
    data = typeof data === 'object' ? data : {};
    let result = new FindAccountsRequest();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["CustomerNumber"] = this.customerNumber;
    if (this.accountNumbers && this.accountNumbers.constructor === Array) {
      data["AccountNumbers"] = [];
      for (let item of this.accountNumbers)
        data["AccountNumbers"].push(item);
    }
    return data;
  }
}

export interface IFindAccountsRequest {
  customerNumber?: number | undefined;
  accountNumbers?: string[] | undefined;
}

export class FindAccountsResponse implements IFindAccountsResponse {
  contractAccountDetails?: ContractAccountDetailDTO[] | undefined;

  constructor(data?: IFindAccountsResponse) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (data["ContractAccountDetails"] && data["ContractAccountDetails"].constructor === Array) {
        this.contractAccountDetails = [];
        for (let item of data["ContractAccountDetails"])
          this.contractAccountDetails.push(ContractAccountDetailDTO.fromJS(item));
      }
    }
  }

  static fromJS(data: any): FindAccountsResponse {
    data = typeof data === 'object' ? data : {};
    let result = new FindAccountsResponse();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (this.contractAccountDetails && this.contractAccountDetails.constructor === Array) {
      data["ContractAccountDetails"] = [];
      for (let item of this.contractAccountDetails)
        data["ContractAccountDetails"].push(item.toJSON());
    }
    return data;
  }
}

export interface IFindAccountsResponse {
  contractAccountDetails?: ContractAccountDetailDTO[] | undefined;
}

export class ProviderKey implements IProviderKey {
  providerId?: string | undefined;

  constructor(data?: IProviderKey) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.providerId = data["ProviderId"];
    }
  }

  static fromJS(data: any): ProviderKey {
    data = typeof data === 'object' ? data : {};
    let result = new ProviderKey();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["ProviderId"] = this.providerId;
    return data;
  }
}

export interface IProviderKey {
  providerId?: string | undefined;
}

export class SiteKey implements ISiteKey {
  siteId?: number | undefined;

  constructor(data?: ISiteKey) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.siteId = data["SiteId"];
    }
  }

  static fromJS(data: any): SiteKey {
    data = typeof data === 'object' ? data : {};
    let result = new SiteKey();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["SiteId"] = this.siteId;
    return data;
  }
}

export interface ISiteKey {
  siteId?: number | undefined;
}

export class GetContactByGcidClientIdRequest implements IGetContactByGcidClientIdRequest {
  gCID?: string | undefined;
  clientId?: string | undefined;
  appId?: GetContactByGcidClientIdRequestAppId | undefined;

  constructor(data?: IGetContactByGcidClientIdRequest) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.gCID = data["GCID"];
      this.clientId = data["ClientId"];
      this.appId = data["AppId"];
    }
  }

  static fromJS(data: any): GetContactByGcidClientIdRequest {
    data = typeof data === 'object' ? data : {};
    let result = new GetContactByGcidClientIdRequest();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["GCID"] = this.gCID;
    data["ClientId"] = this.clientId;
    data["AppId"] = this.appId;
    return data;
  }
}

export interface IGetContactByGcidClientIdRequest {
  gCID?: string | undefined;
  clientId?: string | undefined;
  appId?: GetContactByGcidClientIdRequestAppId | undefined;
}

export class GetContactByGcidClientIdResponse implements IGetContactByGcidClientIdResponse {
  contact?: ContactDTO | undefined;

  constructor(data?: IGetContactByGcidClientIdResponse) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.contact = data["Contact"] ? ContactDTO.fromJS(data["Contact"]) : <any>undefined;
    }
  }

  static fromJS(data: any): GetContactByGcidClientIdResponse {
    data = typeof data === 'object' ? data : {};
    let result = new GetContactByGcidClientIdResponse();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["Contact"] = this.contact ? this.contact.toJSON() : <any>undefined;
    return data;
  }
}

export interface IGetContactByGcidClientIdResponse {
  contact?: ContactDTO | undefined;
}

export enum GetContactByGcidClientIdRequestAppId {
  WebUser = <any>"WebUser",
  AppUser = <any>"AppUser",
}

export enum ClientLogRequestLevel {
  LogAlways = <any>"LogAlways",
  Critical = <any>"Critical",
  Error = <any>"Error",
  Warning = <any>"Warning",
  Informational = <any>"Informational",
  Verbose = <any>"Verbose",
}