import { Injectable, Injector, Inject } from '@angular/core';
import { RouterService } from './router.service';
import { LanguageService } from './language.service';
import { Router } from '@angular/router';

@Injectable()
export class GuardService {
  
  public routeBeforeGuardFailed: string | undefined;
  public guardHasFailed: boolean;

  constructor(
    @Inject('RouterService') private routerService: RouterService,
    @Inject("LanguageService") private languageService: LanguageService,
    private router: Router,
  ) {
    this.routeBeforeGuardFailed = undefined;
    this.guardHasFailed = false;
  }

  public guardSucceded(): boolean {
    this.guardHasFailed = false;
    return true;
  }

  public guardFailedForCheckDevice(route: string, returnUrl: string = ""): boolean {
    this.guardHasFailed = true;
    this.routeBeforeGuardFailed = route;
    this.router.navigate([route], { queryParams: { returnUrl: returnUrl } });
    return false;
  }

  public guardFailedForDeviceToken(route: string, isExternal?: boolean, paramsObject?: object): boolean {
    this.guardHasFailed = true;
    if (isExternal) {
      this.routerService.navigateToExternalUrlforDeviceToken(route, paramsObject);
    }
    else {
      this.routeBeforeGuardFailed = route;
      this.routerService.navigateToTarget(route, paramsObject);
    }
    return false;
  }

  public guardFailed(route: string, isExternal?: boolean, paramsObject?: object): boolean {
    this.guardHasFailed = true;
    if (isExternal) {
      this.routerService.navigateToExternalUrl(route);
    }
    else {
      this.routeBeforeGuardFailed = route;
      this.routerService.navigateToTarget(route, paramsObject);
    }
    return false;
  }

  public setLanguage(state: any): void {
    let language = state._root.value.queryParams["language"];
    if (language != undefined)
      this.languageService.setCurrentLanguage(language);
  }


}
