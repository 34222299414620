import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpResponseBase } from '@angular/common/http';

export const FS_TOKEN_API_BASE_URL = new InjectionToken('API_BASE_URL');

export interface IFSTokenServiceClient {
  getFSToken(request: FSTokenRequest): Observable<FSTokenResponse>;
}

@Injectable()
export class FSTokenServiceClient implements IFSTokenServiceClient {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  constructor( @Inject(HttpClient) http: HttpClient, @Optional() @Inject(FS_TOKEN_API_BASE_URL) baseUrl?: string) {
    this.http = http;
    
    this.baseUrl = baseUrl ? baseUrl : "/GatewayApiServices";
  }

  getFSToken(request: FSTokenRequest): Observable<FSTokenResponse> {
    let url_ = this.baseUrl + "/fstoken";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = request.grant_type;

    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };

    return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
      return this.processFsToken(response_);
    })).pipe(_observableCatch((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processFsToken(<any>response_);
        } catch (e) {
          return <Observable<FSTokenResponse>><any>_observableThrow(e);
        }
      } else
        return <Observable<FSTokenResponse>><any>_observableThrow(response_);
    }));
  }

  protected processFsToken(response: HttpResponseBase): Observable<FSTokenResponse> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
    if (status === 200) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = resultData200 ? FSTokenResponse.fromJS(resultData200) : new FSTokenResponse();
        return _observableOf(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return _observableOf<FSTokenResponse>(<any>null);
  }

}



export class FSTokenRequest implements IFSTokenRequest {
  grant_type?: string | undefined;

  constructor(data?: IFSTokenRequest) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.grant_type = data["grant_type"];
    }
  }

  static fromJS(data: any): FSTokenRequest {
    let result = new FSTokenRequest();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = data ? data : {};
    data["grant_type"] = this.grant_type;
    return data;
  }
}

export interface IFSTokenRequest {
  grant_type?: string | undefined;
}

export class FSTokenResponse implements IFSTokenResponse {
  access_token?: string | undefined;
  token_type?: string | undefined;
  expires_in?: string | undefined;

  constructor(data?: IFSTokenResponse) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.access_token = data["access_token"];
      this.token_type = data["token_type"];
      this.expires_in = data["expires_in"];
    }
  }

  static fromJS(data: any): FSTokenResponse {
    let result = new FSTokenResponse();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = data ? data : {};
    data["access_token"] = this.access_token;
    data["token_type"] = this.token_type;
    data["expires_in"] = this.expires_in;
    return data;
  }
}

export interface IFSTokenResponse {
  access_token?: string | undefined;
  token_type?: string | undefined;
  expires_in?: string | undefined;
}

export class SwaggerException extends Error {
  message: string;
  status: number;
  response: string;
  headers: { [key: string]: any; };
  result: any;
  //faultType: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;
  constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
    super();

    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
    let data = JSON.parse(response, this.jsonParseReviver);

    // if (data) {
    //   if (data["FaultType"]) {
    //     this.faultType = data["FaultType"];
    //   }
    // }
  }

  protected isSwaggerException = true;

  static isSwaggerException(obj: any): obj is SwaggerException {
    return obj.isSwaggerException === true;
  }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): Observable<any> {
  if (result !== null && result !== undefined)
    return _observableThrow(result);
  else
    return _observableThrow(new SwaggerException(message, status, response, headers, null));
}

function blobToText(blob: any): Observable<string> {
  return new Observable<string>((observer: any) => {
    if (!blob) {
      observer.next("");
      observer.complete();
    } else {
      let reader = new FileReader();
      reader.onload = function () {
        observer.next(this.result);
        observer.complete();
      }
      reader.readAsText(blob);
    }
  });
}
