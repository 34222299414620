import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { StringModel } from '../../../models/string.model';
import { FSA } from 'flux-standard-action';

import { tassign } from 'tassign';


export class LanguageActions {
  static readonly SET_LANGUAGE: string = 'SET_LANGUAGE';



  setLanguage(language: string): FSA<string, string, null> {
    return {
      type: LanguageActions.SET_LANGUAGE,
      payload: language,
      meta: null
    };
  }

}

@Injectable()
export class LanguageReducers {
  static readonly LanguageReducer: Reducer<StringModel | undefined,FSA<string, string,void>> = (state: StringModel = new StringModel(''), action: FSA<string, string, void>) => {
    if(action.type == LanguageActions.SET_LANGUAGE) {
      return tassign(state, new StringModel(action.payload as string));
    }
    else
      return state;
  }
}
