import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { FSA, isError, isFSA } from 'flux-standard-action';
import { tassign } from 'tassign';
import { ContactDTO } from 'projects/_shared/core/gatewayapi/auth-service.api';
import { INITIAL_STATE } from '../app.store';

export class ContactActions {
  static SET_CONTACT: string = 'SET_CONTACT';
  static CLEAR_CONTACT: string = 'CLEAR_CONTACT';

  setContact(contact: ContactDTO): FSA<string, ContactDTO, null> {
    return {
      type: ContactActions.SET_CONTACT,
      payload: contact,
      meta: null
    };
  }

  clearContact(): FSA<string, ContactDTO, null> {
    return {
      type: ContactActions.CLEAR_CONTACT,
      payload: new ContactDTO(),
      meta: null
    };
  }

}

@Injectable()
export class ContactReducers {
  static ContactReducer: Reducer<ContactDTO | undefined, FSA<string, ContactDTO, void>> = (state: ContactDTO = INITIAL_STATE.Contact as ContactDTO, action: FSA<string, ContactDTO, void>) => {
    switch (action.type) {
      case ContactActions.SET_CONTACT:
        return tassign(state, action.payload);
      case ContactActions.CLEAR_CONTACT:
        return tassign(undefined);
      default:
        return state;
    }
  }
}