import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { GuardService } from '../shared/helper-service/guard.service';
import { UrlBuilderService, LibStorageService } from 'customerdigital-service-lib';


export const AuthGuard: CanActivateFn =  (activeRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
     const urlBuilderService = inject(UrlBuilderService);
     const libStorageService = inject(LibStorageService);
     const guardService = inject(GuardService);
     guardService.setLanguage(state);
     if (libStorageService.isAuthenticated()) {
        return guardService.guardSucceded();
     }
     else {
       let redirectUrl = urlBuilderService.getLoginRedirectUrl(state, '');
       return guardService.guardFailed(redirectUrl,
         true, { origUrl: state.url });
     }
}