import { IconType } from "./enums";

export class HeaderMenuItem {
  iconType?: IconType | string;
  text: string;
  isImage?: boolean | null;
  eventName?: string;

  constructor(text: string, iconType?: IconType, isImage?: boolean, eventName?: string) {
    this.text = text;
    this.iconType = iconType;
    this.isImage = isImage;
    this.eventName = eventName
  }
}

export class HeaderModel {
  home?: string;
  myGarage?: string;
  myAccount?: string;
  myProfile?: string;
  bmwLogin?: string;
  logOutMyBMW?: string;

  constructor(home?: string, myGarage?: string, myAccount?: string, myProfile?: string, bmwLogin?: string, logOutMyBMW?: string) {
    this.home = home;
    this.myGarage = myGarage;
    this.myAccount = myAccount;
    this.myProfile = myProfile;
    this.bmwLogin = bmwLogin;
    this.logOutMyBMW = logOutMyBMW;
  }
}
