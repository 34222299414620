import { mergeMap, catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpResponseBase, HttpErrorResponse } from '@angular/common/http';
import { API_BASE_URL, blobToText, throwException } from '../helper/api-helper';

export interface IBmwCardServiceClient {
  /**
   * @return OK
   */
  findCreditCardAccounts(request: FindCreditCardAccountsRequest): Observable<FindCreditCardAccountsResponse>;
}
@Injectable()
export class BmwCardServiceClient implements IBmwCardServiceClient {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.http = http;
    this.baseUrl = baseUrl ? baseUrl : "https:///GatewayApiServices";
  }

  /**
   * @return OK
   */
  findCreditCardAccounts(request: FindCreditCardAccountsRequest): Observable<FindCreditCardAccountsResponse> {
    let url_ = this.baseUrl + "/api/BmwCardService/FindCreditCardAccounts";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(request);

    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };

    return this.http.request("post", url_, options_).pipe(mergeMap((response_: any) => {
      return this.processFindCreditCardAccounts(response_);
    }),catchError((response_: any) => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processFindCreditCardAccounts(<any>response_);
        } catch (e) {
          return <Observable<FindCreditCardAccountsResponse>><any>throwError(()=>e);
        }
      } else
        return <Observable<FindCreditCardAccountsResponse>><any>throwError(()=>response_);
    }));
  }

  protected processFindCreditCardAccounts(response: HttpResponseBase): Observable<FindCreditCardAccountsResponse> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse ? response.body :
        (<any>response).error instanceof Blob ? (<any>response).error : undefined;

    let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200: any = null;
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        result200 = FindCreditCardAccountsResponse.fromJS(resultData200);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of<FindCreditCardAccountsResponse>(<any>null);
  }
}

export class FindCreditCardAccountsRequest implements IFindCreditCardAccountsRequest {
  groupId! : string | undefined | null;
  gcid?: string | undefined;
  contactId?: string | undefined;
  customerNumber! : number | undefined | null;
  bmwCreditCardBrand?: FindCreditCardAccountsRequestBmwCreditCardBrand | undefined;
  includeExpired?: boolean | undefined;

  constructor(data?: IFindCreditCardAccountsRequest) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.groupId = data["GroupId"];
      this.gcid = data["Gcid"];
      this.contactId = data["ContactId"];
      this.customerNumber = data["CustomerNumber"];
      this.bmwCreditCardBrand = data["BmwCreditCardBrand"];
      this.includeExpired = data["IncludeExpired"];
    }
  }

  static fromJS(data: any): FindCreditCardAccountsRequest {
    let result = new FindCreditCardAccountsRequest();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = data ? data : {};
    data["GroupId"] = this.groupId;
    data["Gcid"] = this.gcid;
    data["ContactId"] = this.contactId;
    data["CustomerNumber"] = this.customerNumber;
    data["BmwCreditCardBrand"] = this.bmwCreditCardBrand;
    data["IncludeExpired"] = this.includeExpired;
    return data;
  }
}

export interface IFindCreditCardAccountsRequest {
  groupId?: string | undefined | null;
  gcid?: string | undefined;
  contactId?: string | undefined;
  customerNumber?: number | undefined | null;
  bmwCreditCardBrand?: FindCreditCardAccountsRequestBmwCreditCardBrand | undefined;
  includeExpired?: boolean | undefined;
}

export class FindCreditCardAccountsResponse implements IFindCreditCardAccountsResponse {
  creditCardsKeyInfo?: BmwCreditCardKeyDataDTO[] | undefined;

  constructor(data?: IFindCreditCardAccountsResponse) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (data["CreditCardsKeyInfo"] && data["CreditCardsKeyInfo"].constructor === Array) {
        this.creditCardsKeyInfo = [];
        for (let item of data["CreditCardsKeyInfo"])
          this.creditCardsKeyInfo.push(BmwCreditCardKeyDataDTO.fromJS(item));
      }
    }
  }

  static fromJS(data: any): FindCreditCardAccountsResponse {
    let result = new FindCreditCardAccountsResponse();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = data ? data : {};
    if (this.creditCardsKeyInfo && this.creditCardsKeyInfo.constructor === Array) {
      data["CreditCardsKeyInfo"] = [];
      for (let item of this.creditCardsKeyInfo)
        data["CreditCardsKeyInfo"].push(item.toJSON());
    }
    return data;
  }
}

export interface IFindCreditCardAccountsResponse {
  creditCardsKeyInfo?: BmwCreditCardKeyDataDTO[] | undefined;
}

export class BmwCreditCardKeyDataDTO implements IBmwCreditCardKeyDataDTO {
  eac?: string | undefined;
  creditCardBrand?: BmwCreditCardKeyDataDTOCreditCardBrand | undefined;
  creditCardType?: string | undefined;
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  cardIsActive?: boolean | undefined;

  constructor(data?: IBmwCreditCardKeyDataDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.eac = data["Eac"];
      this.creditCardBrand = data["CreditCardBrand"];
      this.creditCardType = data["CreditCardType"];
      this.startDate = data["StartDate"] ? new Date(data["StartDate"].toString()) : <any>undefined;
      this.endDate = data["EndDate"] ? new Date(data["EndDate"].toString()) : <any>undefined;
      this.cardIsActive = data["CardIsActive"];
    }
  }

  static fromJS(data: any): BmwCreditCardKeyDataDTO {
    let result = new BmwCreditCardKeyDataDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = data ? data : {};
    data["Eac"] = this.eac;
    data["CreditCardBrand"] = this.creditCardBrand;
    data["CreditCardType"] = this.creditCardType;
    data["StartDate"] = this.startDate ? this.startDate.toISOString() : <any>undefined;
    data["EndDate"] = this.endDate ? this.endDate.toISOString() : <any>undefined;
    data["CardIsActive"] = this.cardIsActive;
    return data;
  }
}

export interface IBmwCreditCardKeyDataDTO {
  eac?: string | undefined;
  creditCardBrand?: BmwCreditCardKeyDataDTOCreditCardBrand | undefined;
  creditCardType?: string | undefined;
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  cardIsActive?: boolean | undefined;
}


export enum FindCreditCardAccountsRequestBmwCreditCardBrand {
  BMW = <any>"BMW",
  Mini = <any>"Mini",
  Motorrad = <any>"Motorrad",
}

export enum BmwCreditCardKeyDataDTOCreditCardBrand {
  BMW = <any>"BMW",
  Mini = <any>"Mini",
  Motorrad = <any>"Motorrad",
}
