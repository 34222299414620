import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { BooleanModel } from '../../../models/boolean.model';
import { FSA, isError, isFSA } from 'flux-standard-action';
import { tassign } from 'tassign';

export class IsEpassInitializedActions
{
    public static EPAAS_INITIALIZED: string = 'IsEpassInitialized';

    setIsEpassInitialized(value: boolean):any{
        return {
            type: IsEpassInitializedActions.EPAAS_INITIALIZED,
            payload: value,
            meta: null
        };
    }

}

@Injectable()
export class IsEpassInitializedReducers
{
    static IsEpassInitializedReducer: Reducer<BooleanModel | undefined, any> = (state: BooleanModel = new BooleanModel(false), action: any) => {
        if (action.type === IsEpassInitializedActions.EPAAS_INITIALIZED) {
            return tassign(state, new BooleanModel(action.payload as boolean));
        }
        else {
            return state;
        }
    }
}
