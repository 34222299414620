import { ApplicationConfig } from "projects/_shared/models/configuration/application.config";
import { Brand, Country } from "projects/_shared/models/enums";
import { StorageType } from 'customerdigital-service-lib';
import { Market } from 'projects/_shared/shared/enum';

export const applicationConfig: ApplicationConfig = {
  BRAND: Brand.BMW,
  COUNTRY: Country.UNITED_STATES,
  CLIENT_ID: 'b175da89-bcb4-4e85-aa88-b2b99e9b319f',
  SITEID: 1,
  LANGUAGE: 'EN',
  REFERSH_BEARER_TOKEN_INTERVAL_IN_SEC: 3300,
  MARKET: "USA",
  COUNTRYCODE: 'US',
  SCOPE: "authenticate_user",
  RESPONSE_TYPE: "code",
  GRANT_TYPE: "authorization_code",
  STORAGE_TYPE: StorageType.session,
  CLIENT: "usahub",
  POLICY_FORMAT: 'HTML',
  POLICY_ID: 'BMW_AUTHHUB_TAC',
  FAQ_PRESENTATION_TYPE: 10,
  CONTACT_US: "https://www.bmwusa.com/contact-us.html",
  TITLE: "MyProfile-BMW-US",
  REGION: Market.US,
  LOADER_IMG_SRC: "assets/images/loading-icon.gif",
  TENANT: "",
  PRODUCT: "",
  EPAAS_TENANT_ID: "",
  EPAAS_FEATURE_TYPE: "",
  FOOTER_MENU_ITEM: ""
}
