import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { FSA, isError, isFSA } from 'flux-standard-action';
import { tassign } from 'tassign';
import { CustomerContact } from '../../../models/customer-data.model';
import { INITIAL_STATE } from '../app.store';

export class CustomerNaContactActions {
  static SET_NA_CUSTOMER_INFORMATION: string = 'SET_NA_CUSTOMER_INFORMATION';

  setCustomerNaInformation(customerContactResult: CustomerContact): FSA<string, CustomerContact, null> {
    return {
      type: CustomerNaContactActions.SET_NA_CUSTOMER_INFORMATION,
      payload: customerContactResult,
      meta: null
    };
  }

}

@Injectable()
export class CustomerNaContactReducers {

  static CustomerNaContactReducer: Reducer<CustomerContact | undefined, FSA<string, CustomerContact, void>> = (state: CustomerContact = INITIAL_STATE.CustomerContact as CustomerContact, action: FSA<string, CustomerContact, void>) => {

    switch (action.type) {

      case CustomerNaContactActions.SET_NA_CUSTOMER_INFORMATION:
        return tassign(state, action.payload);

      default:
        return state;
    }
  }
}




