import { Brand, Country } from "../enums";
import { StorageType } from 'customerdigital-service-lib';
import { Market } from 'projects/_shared/shared/enum';

export class ApplicationConfig {
  BRAND!: Brand;
  COUNTRY!: Country;
  SITEID!: number;
  LANGUAGE!: string;
  REFERSH_BEARER_TOKEN_INTERVAL_IN_SEC!: number;
  MARKET!: string;
  COUNTRYCODE!: string;
  CLIENT_ID!: string;
  SCOPE!: string;
  RESPONSE_TYPE!: string;
  GRANT_TYPE!: string;
  STORAGE_TYPE!: StorageType;
  CLIENT!: string;
  POLICY_FORMAT!: string;
  POLICY_ID!: string;
  FAQ_PRESENTATION_TYPE!: number;
  CONTACT_US!: string;
  TITLE!: string;
  REGION!: Market;
  LOADER_IMG_SRC!: string;
  EPAAS_TENANT_ID!: string;
  EPAAS_FEATURE_TYPE!: string;
  TENANT!:string;
  PRODUCT!: string;
  FOOTER_MENU_ITEM!: string;
}
