import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { FSA, isError, isFSA } from 'flux-standard-action';
import { tassign } from 'tassign';
import { StringModel } from 'projects/_shared/models/string.model';

export class GlobalErrorActions {
  static SET_GLOBAL_ERROR_MESSAGE: string = 'SET_GLOBAL_ERROR_MESSAGE';
  static SET_SSO_ERROR_MESSAGE: string = 'SET_SSO_ERROR_MESSAGE';
  static CLEAR_SSO_ERROR_MESSAGE: string = 'CLEAR_SSO_ERROR_MESSAGE';

  setGlobalError(globalError: string): FSA<string, string, null> {
    return {
      type: GlobalErrorActions.SET_GLOBAL_ERROR_MESSAGE,
      payload: globalError,
      meta: null
    };
  }

  setSSOError(sSOError: string):any {
    return {
      type: GlobalErrorActions.SET_SSO_ERROR_MESSAGE,
      payload: sSOError,
      meta: null
    };
  }

  clearSSOError(): any {
    return {
      type: GlobalErrorActions.CLEAR_SSO_ERROR_MESSAGE,
      payload: "",
      meta: null
    };
  }

}

@Injectable()
export class GlobalErrorReducers {
  static GlobalErrorReducer: Reducer<StringModel | undefined, any> = (state: StringModel = new StringModel(''), action: any) => {
    switch (action.type) {
      case GlobalErrorActions.SET_GLOBAL_ERROR_MESSAGE:
        return tassign(state, new StringModel(action.payload as string));
      case GlobalErrorActions.SET_SSO_ERROR_MESSAGE:
        return tassign(state, new StringModel(action.payload as string));
      case GlobalErrorActions.CLEAR_SSO_ERROR_MESSAGE:
        return tassign(undefined);
      default:
        return state;
    }
  }
}