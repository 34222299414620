import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { UserService } from '../shared/helper-service/user.service';
import { IAppState } from "../shared/store/app.store";
import { mergeMap, catchError } from 'rxjs/operators';
import {
  GetPartnerTokenByCustomerNumberRequest, GetPartnerTokenByCustomerNumberRequestIntegrationProduct, GetPartnerTokenByCustomerNumberRequestIntegrationProvider,
  GetPartnerTokenByGcIdClientIdRequest, GetPartnerTokenByGcIdClientIdRequestIntegrationProduct, GetPartnerTokenByGcIdClientIdRequestIntegrationProvider,
  PartnerIntegrationServiceClient
} from '../core/gatewayapi/partner-integration.service.api';
import { Store } from '@ngrx/store';


@Injectable()
export class PartnerIntegrationService {
  private gcId!: string;
  private clientId!: string;
  private customerNumber: number|null=null;
  private brand: string="";
  private region: string="";

  constructor(
    private partnerIntegrationServiceClient: PartnerIntegrationServiceClient,
    private userService: UserService,
    private store: Store<IAppState>
  ) { }

  public getSamlResponse(): Observable<string> {
    this.store.select(state => state.ApplicationConfig!.BRAND).subscribe( x => this.brand = x.toUpperCase());
    this.store.select(state => state.ApplicationConfig!.REGION).subscribe( x => this.region = x.toUpperCase());
    this.gcId = this.userService.getGcid();
    this.store.select(state => state.ApplicationConfig!.CLIENT_ID).subscribe( x => this.clientId = x.toUpperCase());
    this.customerNumber = this.userService.getCustomerNumber();

    if (this.gcId != undefined && this.gcId != "" && this.gcId != null
      && this.clientId != undefined && this.clientId != "" && this.clientId != null) {
      var gcidclientidrequest = new GetPartnerTokenByGcIdClientIdRequest();
      this.brand = this.mapIntegrationProduct(GetPartnerTokenByGcIdClientIdRequestIntegrationProduct);
      gcidclientidrequest.integrationProduct = (<any>GetPartnerTokenByGcIdClientIdRequestIntegrationProduct)[this.brand];
      gcidclientidrequest.gcId = this.gcId;
      gcidclientidrequest.clientId = this.clientId;
      gcidclientidrequest.region = this.region;
      gcidclientidrequest.integrationProvider = GetPartnerTokenByGcIdClientIdRequestIntegrationProvider.SamlResponse;
      return this.getSamlResponseByGcIdClientId(gcidclientidrequest);
    }
    else if (this.customerNumber != undefined && this.customerNumber > 0) {
      var customernumberrequest = new GetPartnerTokenByCustomerNumberRequest();
      this.brand = this.mapIntegrationProduct(GetPartnerTokenByCustomerNumberRequestIntegrationProduct);
      customernumberrequest.integrationProduct = (<any>GetPartnerTokenByCustomerNumberRequestIntegrationProduct)[this.brand];
      customernumberrequest.customerNumber = this.customerNumber;
      customernumberrequest.region = this.region;
      customernumberrequest.integrationProvider = GetPartnerTokenByCustomerNumberRequestIntegrationProvider.SamlResponse;
      return this.getSamlResponseByCustomerNumber(customernumberrequest);
    }
    else {
      return of("");
    }
  }

  private mapIntegrationProduct(request: any) {

    switch (this.brand) {
      case request.Bmw.toString().toUpperCase():
        return request.Bmw;
      case request.Mini.toString().toUpperCase():
        return request.Mini;
      case request.Motorad.toString().toUpperCase():
        return request.Motorad;
      case request.RollRoyceFuture.toString().toUpperCase():
        return request.RollRoyceFuture;
      default:
        return request.Bmw;
    }
  }

  public getSamlResponseByCustomerNumber(request:any): Observable<string> {
    return this.partnerIntegrationServiceClient.getPartnerTokenByCustomerNumber(request)
      .pipe(mergeMap((response) => { return this.aftergetSamlResponseBySuccess(response); }))
      .pipe(catchError((error: any) => { return this.aftergetSamlResponseByFailure(error); }));
  }

  public getSamlResponseByGcIdClientId(request:any): Observable<string> {
    return this.partnerIntegrationServiceClient.getPartnerTokenByGcIdClientId(request)
      .pipe(mergeMap((response) => { return this.aftergetSamlResponseBySuccess(response); }))
      .pipe(catchError((error: any) => { return this.aftergetSamlResponseByFailure(error); }));
  }

  private aftergetSamlResponseBySuccess(response:any): Observable<string> {
    return of(response.integrationToken != undefined && response.integrationToken.length > 0 ? response.integrationToken : "");
  }

  private aftergetSamlResponseByFailure(error: any): Observable<string> {
    return of("");
  }

}
