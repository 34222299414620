import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { UserService } from '../shared/helper-service/user.service';
import { FindCreditCardAccountsResponse, FindCreditCardAccountsRequestBmwCreditCardBrand, FindCreditCardAccountsRequest, BmwCardServiceClient } from '../core/gatewayapi/bmw-card.service.api';
import { TileTypes } from '../shared/enum';
import { mergeMap, catchError } from 'rxjs/operators';

@Injectable()
export class BmwCardService {
  constructor(
    private bmwCardServiceClient: BmwCardServiceClient,
    private userService: UserService,
  ) { }


  public getCardHolder(): Observable<TileTypes> {
    var request = new FindCreditCardAccountsRequest();
    request.bmwCreditCardBrand = FindCreditCardAccountsRequestBmwCreditCardBrand.BMW;
    request.includeExpired = false;
    request.gcid = this.userService.getGcid();
    request.groupId = this.userService.getGroupId();
    request.customerNumber = null;

    return this.bmwCardServiceClient.findCreditCardAccounts(request)
      .pipe(mergeMap((response) => { return this.afterFindCreditCardAccountsSuccess(response); }),
      catchError((error: any) => { return this.afterFindCreditCardAccountsFailure(error); }));
  }

  private afterFindCreditCardAccountsSuccess(response: FindCreditCardAccountsResponse): Observable<TileTypes> {
    let tileType = response.creditCardsKeyInfo != undefined && response.creditCardsKeyInfo.length > 0 ? TileTypes.CardHolder : TileTypes.NonCardHolder;
    return of(tileType);
  }

  private afterFindCreditCardAccountsFailure(error: any): Observable<TileTypes> {
    return of(TileTypes.AllCustomer);
  }

}
