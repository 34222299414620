import { Injectable, Injector } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { Constants } from '../constants';
import { IAppState } from '../store/app.store';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { AppTileModel } from "../../models/app-tile.model"

export const route: any | undefined = {
  baseUrl: "",
  home: "home",
  sso: "sso",
  ssoLogout: "sso/logout",
  termsaccept: 'terms/accept',
  termsview: 'terms/view',
  faq: "faq",
  myprofile: "profile",
  preferencesconfirmation: "profile/preferences/confirmation",
  editprofile: 'profile/edit',
  cookiePolicy: "cookiePolicy",
  error: "error"
}

export enum routes {
  baseUrl = <any>"baseUrl",
  home = <any>"home",
  sso = <any>"sso",
  ssoLogout = <any>"ssoLogout",
  termsaccept = <any>"termsaccept",
  termsview = <any>"termsview",
  faq = <any>"faq",
  myprofile = <any>"myprofile",
  preferencesconfirmation = <any>"preferencesconfirmation",
  editprofile= <any>"editprofile",
  cookiePolicy = <any>"cookiePolicy",
  error = <any>"error"
}

@Injectable()
export class RouterService {
  router!: Router;
  activatedRoute!: ActivatedRoute;
  constructor(private injector: Injector, private store: Store<IAppState>) { }

  public navigateTo(page: routes, parametar?: string): void {
    if (this.router == undefined) { this.router = this.injector.get(Router); }
    if (parametar) {
      this.router.navigate([route[page], parametar]);
    }
    else {
      this.router.navigate([route[page]]);
    }
  }

  public navigateWithParam(page: routes, parameters?: object, fragmentValue?: string): void {
    if (this.router == undefined) { this.router = this.injector.get(Router); }
    if (parameters) {
      if (fragmentValue) {
          this.router.navigate([route[page]], { queryParams: parameters, fragment: fragmentValue, preserveFragment: true });
      }
      else {
          this.router.navigate([route[page]], { queryParams: parameters });
      }
    }
    else {
      if (fragmentValue) {
          this.router.navigate([route[page]], { fragment: fragmentValue });
      }
      else {
          this.router.navigate([route[page]]);       
      }
    }
  }

  public navigateWithParamforFaq(page: routes, parameters?: object, fragmentValue?: string): void {
    if (this.router == undefined) { this.router = this.injector.get(Router); }
    if (parameters) {
      if (fragmentValue) {
        this.router.navigate([route[page]], { queryParams: parameters, fragment: fragmentValue, preserveFragment: true }).then(() => {
          window.location.reload();
        });
      }
      else {
          this.router.navigate([route[page]], { queryParams: parameters }).then(() => {
            window.location.reload();
          });
      }
    } 
  }
  public navigateToBaseUrl() {
    this.navigateTo(routes.baseUrl);
  }

  public navigateToHome() {
    this.navigateTo(routes.home);
  }

  public navigateToSsoLogout() {
    this.navigateTo(routes.ssoLogout);
  }

  public navigateByUrl(routeUrl: string) {
    if (this.router == undefined) { this.router = this.injector.get(Router); }
    this.router.navigateByUrl(routeUrl);
  }

  public navigateToMyProfile() {
    this.navigateTo(routes.myprofile);
  }

  public getRouteData(): any {
    if (this.activatedRoute == undefined) { this.activatedRoute = this.injector.get(ActivatedRoute); }
    if (this.router == undefined) { this.router = this.injector.get(Router); }
    let routeData = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((activatdRoute) => {
        while (activatdRoute.firstChild) activatdRoute = activatdRoute.firstChild;
        return activatdRoute;
      }),
      filter((activatdRoute) => activatdRoute.outlet === 'primary'),
      mergeMap((activatdRoute) => activatdRoute.data));
    return routeData;
  }

  public navigateToTarget(page?: string, paramsObject?: object): void {
    //if target page is requested, nav to the target with deeplink in querystring (used by guards), else nav to deeplink (used by components)
    if (this.router == undefined) { this.router = this.injector.get(Router); }
    if (page && page !== "" && page != "undefined") {
      if (page.indexOf('/') !== -1) {
        this.router.navigateByUrl(page);
      }
      else {
        if (paramsObject) {
          this.router.navigate([page], { queryParams: paramsObject });
        }
        else {
          this.router.navigate([page]);
        }

      }
    }
    else {
      this.navigateToHome();
    }

  }

  public navigateToExternalUrlforDeviceToken(url: string, parameter?: any) {
    window.location.href = url + "?gcid=" + parameter["gcid"] + "&state=" + parameter["state"];
  }

  public navigateToExternalUrl(url: any, isNewTab?: any) {
    if (isNewTab){
      window.open(url, '_blank');
    }
    else{
      window.location.href = url;
    }
  }
  public navigateToSSOLogout() {
    this.navigateTo(routes.ssoLogout);
  }

  public navigateToPreferencesConfirmation(paramsObject: object): void {
    this.navigateWithParam(routes.preferencesconfirmation, paramsObject);
  }

  public getFaqView(): any {
    return route.faq;
  }
  public getTermsView(): any {
    return route.termsview;
  }

  public navigateToMyAccountDeepLink(url: string, pageId1: string) {
    let deeplink = url.concat(Constants.redirect, Constants.paramPageId1, pageId1);
    this.navigateToExternalUrl(deeplink);
  }

  public navigateToMyProfileDeepLink(url: string, pageId1: string, pageId2: string) {
    let deeplink = url.concat(Constants.redirect, Constants.paramPageId1, pageId1,Constants.paramConcat, Constants.paramPageId2,pageId2);
    this.navigateToExternalUrl(deeplink);
  }

  public navigateToEditNaProfile(){
    this.navigateTo(routes.editprofile);
  }

  public navigateToMyGarage(garageTileClass: string) {
    let garageConfig = this.store.select(state => state.EnvironmentConfig?.CLIENT_CONFIG).subscribe(s => _.filter(s, function (config) {
      return config &&config.tileClass == garageTileClass;
    }));

    if (garageConfig != undefined) {
      let loginUri: AppTileModel[] | undefined ;
      this.store.select(state => state.EnvironmentConfig?.CLIENT_CONFIG).subscribe(s => loginUri = s)
      this.navigateToExternalUrl(loginUri![0].loginUri);
    }
  }

  public navigateToTermsView(isNewTab?: any){
    if (isNewTab){
      const url = route[routes.termsview];
      window.open(url, '_blank');
    }
    else{
      this.navigateTo(routes.termsview);
    }
  }

  public navigateToNewTabWithParam(url: any, queryParam: any){
    window.open(url + Constants.UrlParametersPrefix + queryParam);
  }
  
  public navigateToTermsAccept(lang?: string): void {
   if(lang)
    this.navigateWithParam(routes.termsaccept, { language: lang });
   else
     this.navigateTo(routes.termsaccept);
  }

  public navigateToFaq(topic: string = "") {
    this.navigateWithParamforFaq(routes.faq, { topic: topic });
  }
  public navigateToCookiePolicy() {
    this.navigateTo(routes.cookiePolicy);
  }
  public navigateToErrorPage() {
    this.navigateTo(routes.error);
  }
 
  public navigateToFaqWithLang(lang: string, topic: string = "" ) {
    this.navigateWithParamforFaq(routes.faq, { topic: topic, language: lang });
  }
}
