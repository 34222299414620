export enum Brand {
    BMW = 'BMW',
    MINI = 'MINI'
}

export enum Country {
    CANADA = 'CA',
    UNITED_STATES = 'US'
}

export enum Environment {
    LOCAL = 'local',
    DEV = 'dev',
    UAT = 'uat',
    PREP = 'prep',
    PROD = 'prod'
}

export enum Translation {
    BMW_CA_ENGLISH = 'my-profile-bmw-ca-en',
    BMW_CA_FRENCH = 'my-profile-bmw-ca-fr',
    BMW_US_ENGLISH = 'my-profile-bmw-us-en',
    MINI_CA_ENGLISH = 'my-profile-mini-ca-en',
    MINI_CA_FRENCH = 'my-profile-mini-ca-fr',
    MINI_US_ENGLISH = 'my-profile-mini-us-en',
}

export enum Language {
    English = "en",
    French = "fr"
}

export enum OrganizationBrand {
    BMW_GROUP_BMW = "BMW_GROUP_BMW",
    BMW_FS_BMW = "BMW_FS_BMW"
}

export enum Channel {
    MAIL = "MAIL",
    EMAIL = "EMAIL",
    APP = "APP"
}

export enum MarketingType {
    BMW_GROUP_BMW_EVENTS = "BMW_GROUP_BMW_EVENTS",
    BMW_GROUP_BMW_LIFESTYLES_AND_ACCESSORIES = "BMW_GROUP_BMW_LIFESTYLES_AND_ACCESSORIES",
    BMW_GROUP_BMW_NEWSLETTERS = "BMW_GROUP_BMW_NEWSLETTERS",
    BMW_GROUP_BMW_PRODUCTS_AND_OFFERS = "BMW_GROUP_BMW_PRODUCTS_AND_OFFERS",
    BMW_GROUP_BMW_SURVEYS = "BMW_GROUP_BMW_SURVEYS",
    BMW_GROUP_BMW_ACTIVE_E = "BMW_GROUP_BMW_ACTIVE_E",
    BMWFS_BMW_CREDIT_CARDS = "BMWFS_BMW_CREDIT_CARDS",
    BMWFS_BMW_INSURANCE = "BMWFS_BMW_INSURANCE",
    BMWFS_BMW_NEWS_PRODUCTS_AND_OFFERS = "BMWFS_BMW_NEWS_PRODUCTS_AND_OFFERS"
}

export enum SourceSystem {
    MyBMW = "MyBMW"
}

export enum LanguageCode {
    English = 1,
    French = 2
}

export enum ResponseContentDTOResponseCode {
    Success = <any>"Success",
    No_Matching_GCID = <any>"No_Matching_GCID",
    GCID_Missing_Incorrectly_Spelled = <any>"GCID_Missing_Incorrectly_Spelled",
    Trillium_Service_exception = <any>"Trillium_Service_exception",
    GCDM_Service_Exception = <any>"GCDM_Service_Exception",
    Invalid_Requested_Address_Profile_Not_Updated = <any>"Invalid_Requested_Address_Profile_Not_Updated",
    Address_Suggestion_Found_Profile_Not_Updated = <any>"Address_Suggestion_Found_Profile_Not_Updated",
  }
  
export enum IconType {
    MyAccount = "myaccount",
    Edit = "edit",
    Logout= "logout",
    Dropdown = "dropdown"
  }