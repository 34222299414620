import { Injectable } from '@angular/core';
import { EnvironmentConfig } from '../../../../_shared/models/configuration/environment.config';
import { ApplicationConfig } from '../../../../_shared/models/configuration/application.config';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { applicationConfig } from '../../environments/application';

@Injectable()
export class EnvironmentSpecificService {

  public envSpecific!: EnvironmentConfig;
  public envSpecificNull: EnvironmentConfig | null = null;
  public appSpecific!: ApplicationConfig;
  public appSpecificNull: ApplicationConfig | null = null;

  constructor(private http: HttpClient) {
  }


  public loadEnvironment(): Observable<EnvironmentConfig> {
    // Only want to do this once - if root page is revisited, it calls this again.
    if (this.envSpecific === null || this.envSpecific === undefined) {

      this.envSpecific = new EnvironmentConfig();

      this.envSpecific.GATEWAY_API_BASE_URL = `${environment.GATEWAY_API_BASE_URL}`;
      this.envSpecific.APP_LOGGING_ENABLED = `${environment.APP_LOGGING_ENABLED}` == "true" ? true : false;
      this.envSpecific.CONSOLE_LOGGING_ENABLED = `${environment.CONSOLE_LOGGING_ENABLED}` == "true" ? true : false;
      this.envSpecific.SSO_REDIRECT_URL = `${environment.SSO_REDIRECT_URL}`;
      this.envSpecific.LOGIN_URL = `${environment.LOGIN_URL}`;
      this.envSpecific.LOGOUT_URL = `${environment.LOGOUT_URL}`;
      this.envSpecific.BASE_URL = `${environment.BASE_URL}`;
      this.envSpecific.PROFILE_URL = `${environment.PROFILE_URL}`;
      this.envSpecific.CLIENT_CONFIG = JSON.parse(JSON.stringify(environment.CLIENT_CONFIG));
      this.envSpecific.IDLE_SESSION = parseInt(`${environment.IDLE_SESSION}`);
      this.envSpecific.TIME_OUT_SESSION = parseInt(`${environment.TIME_OUT_SESSION}`);
      this.envSpecific.GLOBAL_NAV_DOMAIN_URL = `${environment.GLOBAL_NAV_DOMAIN_URL}`;
      this.envSpecific.PRIVACY_POLICY_URL = `${environment.PRIVACY_POLICY_URL}`;
      this.envSpecific.MY_DOMAIN_URL = `${environment.MY_DOMAIN_URL}`;
      this.envSpecific.CONTACT_US = `${environment.CONTACT_US}`;
      this.envSpecific.CLIENT_CONFIG = JSON.parse(JSON.stringify(environment.CLIENT_CONFIG));
      this.envSpecific.ANALYTICS_CONFIG = { IS_MOBILE: false, ...JSON.parse(JSON.stringify(environment.ANALYTICS_CONFIG)) };
      this.envSpecific.TOP_HEADER_NAVIGATION_CONFIG = JSON.parse(JSON.stringify(environment.TOP_HEADER_NAVIGATION_CONFIG));
      this.envSpecific.FOOTER_NAVIGATION_CONFIG = JSON.parse(JSON.stringify(environment.FOOTER_NAVIGATION_CONFIG));
      this.envSpecific.DEVICE_TOKEN_URL = `${environment.DEVICE_TOKEN_URL}`;
      this.envSpecific.MYPROFILE_URL =  `${environment.MYPROFILE_URL}`;
    }

    return of(this.envSpecific);

  }

  public loadApplicationConfig(): ApplicationConfig {

    if (this.appSpecific === null || this.appSpecific === undefined) {

      this.appSpecific = new ApplicationConfig();

      this.appSpecific = applicationConfig;
    }
    return this.appSpecific;
  }

}
