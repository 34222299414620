import { Injectable, Injector } from '@angular/core';
import { ActivityLogging, ActivityLoggingFactory } from 'projects/_shared/core/logging/logging.factory';
import { ILogger } from 'customerdigital-service-lib';
import { ActivatedRoute, Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { IAppState } from '../store/app.store';
import { Store } from '@ngrx/store';

@Injectable()
export class LoggerHelperService implements ILogger {

  protected logger: ActivityLogging | undefined;
  protected logFactory: ActivityLoggingFactory;
  public currentRoute: string | undefined;

  constructor(injector: Injector,
    private store: Store<IAppState>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    ) {
    this.logFactory = injector.get(ActivityLoggingFactory);
    
  }
  

  private getLoggerForComponent(componentName: string, activityName: string, isParentActivity: boolean) {
    return this.logFactory.getLogger(
      componentName,
      activityName,
      isParentActivity
    );
  }

  public logAlways(message: string):void {
    let client : any;
    this.store.select(state => state.ApplicationConfig!.CLIENT).subscribe(x => client =x);
    this.getLoggerForComponent(
      this.activatedRoute.snapshot.root.children[0].children[0].routeConfig!.component!.name,
      client, false).then(rtrn => {
      this.logger = rtrn;
      this.logger.logalways([message]);
    });
  }

  public logCritical(message : string): void {
    let client : any;
    this.store.select(state => state.ApplicationConfig!.CLIENT).subscribe(x => client =x);
    this.getLoggerForComponent(this.activatedRoute.snapshot.root.children[0].children[0].routeConfig!.component!.name,
      client, false).then(rtrn => {
      this.logger = rtrn;
      this.logger.logcritical([message]);
    });
  }

  public logError(message: string): void {
    let client : any;
    this.store.select(state => state.ApplicationConfig!.CLIENT).subscribe(x => client =x);
    this.getLoggerForComponent(this.activatedRoute.snapshot.root.children[0].children[0].routeConfig!.component!.name,
      client, false).then(rtrn => {
      this.logger = rtrn;
      this.logger.logerror([message]);
    });
  }

  public logInformational(message: string): void {
    let client : any;
    this.store.select(state => state.ApplicationConfig!.CLIENT).subscribe(x => client =x);
    this.getLoggerForComponent(this.activatedRoute.snapshot.root.children[0].children[0].routeConfig!.component!.name,
      client, false).then(rtrn => {
      this.logger = rtrn;
      this.logger.loginformational([message]);
    });
  }

  public logVerbose(message: string): void {
    let client : any;
    this.store.select(state => state.ApplicationConfig!.CLIENT).subscribe(x => client =x);
    this.getLoggerForComponent(this.activatedRoute.snapshot.root.children[0].children[0].routeConfig!.component!.name,
      client, false).then(rtrn => {
      this.logger = rtrn;
      this.logger.logverbose([message]);
    });
  }

  public logWarning(message: string): void {
    let client : any;
    this.store.select(state => state.ApplicationConfig!.CLIENT).subscribe(x => client =x);
    this.getLoggerForComponent(this.activatedRoute.snapshot.root.children[0].children[0].routeConfig!.component!.name,
      client, false).then(rtrn => {
      this.logger = rtrn;
      this.logger.logwarning([message]);
    });
  }
}


