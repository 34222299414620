import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { BooleanModel } from '../../../models/boolean.model';
import { FSA } from 'flux-standard-action';
import { tassign } from 'tassign';

export class CannotAuthenticateActions {
    public static CANNOT_AUTHENTICATE: string = 'CannotAuthenticate';

    setCannotAuthenticate(value: boolean): FSA<string, boolean, null> {
        return {
            type: CannotAuthenticateActions.CANNOT_AUTHENTICATE,
            payload: value,
            meta: null
        };
    }

}

@Injectable()
export class CannotAuthenticateReducers {
    static CannotAuthenticateReducer: Reducer<BooleanModel | undefined, any> = (state: BooleanModel = new BooleanModel(false), action: any) => {
        if (action.type === CannotAuthenticateActions.CANNOT_AUTHENTICATE) {
            return tassign(state, new BooleanModel(action.payload as boolean));
        } else {
            return state;
        }
    }
}
