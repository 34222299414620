import { NgModule } from '@angular/core';
import { TermsComponent } from './terms.component';
import {FsButtonModule, FsAnchorModule, FsTextModule} from 'customerdigital-ui-lib';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [FsButtonModule, FsAnchorModule, MatCheckboxModule, FsTextModule, CommonModule],
  declarations: [TermsComponent],
  exports: [TermsComponent]
  
})

export class TermsComponentModule {

}
