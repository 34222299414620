import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core'
import { GCDMServiceClient, GetUserContactDetailsRequest, GetUserAccountRequest, GetUserAccountResponse } from '../../core/gatewayapi/gcdm-service.api';
import { mergeMap, catchError } from 'rxjs/operators';
import { UserService } from './user.service';
import { IAppState } from '../store/app.store';
import { CustomerData } from 'projects/_shared/models/customer-data.model';
import { Store } from '@ngrx/store';

@Injectable()
export class GCDMService {

  constructor(private gcdmServiceClient: GCDMServiceClient,
    private userService: UserService,
    private store: Store<IAppState>) {

  }

  public getUserContactDetails(): Observable<CustomerData> {
    let getUserContactDetailsRequest = new GetUserContactDetailsRequest();
    getUserContactDetailsRequest.gcId = this.userService.getGcid();
    this.store.select(x=> x.ApplicationConfig?.CLIENT_ID).subscribe(s=> getUserContactDetailsRequest.clientId = s);
    return this.gcdmServiceClient.getUserContactDetails(getUserContactDetailsRequest)
      .pipe(mergeMap((response) => { return this.afterGetUserContactDetailsSuccess(response); })
      ,catchError((error: any) => { return this.afterGetUserContactDetailsFailure(error); }));
  }

  private afterGetUserContactDetailsSuccess(result: any): Observable<CustomerData> {
    let customerData = new CustomerData(result, false);
    return of(customerData);
  }

  private afterGetUserContactDetailsFailure(error: any): Observable<CustomerData> {
    let customerData = new CustomerData(null, true);
    return of(customerData);
  }

  public getUserAccount(): Observable<GetUserAccountResponse> {
    let getUserAccountRequest = new GetUserAccountRequest();
    getUserAccountRequest.gCID = this.userService.getGcid();
    this.store.select(x=> x.ApplicationConfig?.CLIENT_ID).subscribe(s=> getUserAccountRequest.clientId = s);
    return this.gcdmServiceClient.getUserAccount(getUserAccountRequest)
      .pipe(mergeMap((response) => { return this.afterGetUserAccountSuccess(response); })
      ,catchError((error: any) => { return this.afterGetUserAccountFailure(error); }));
  }

  private afterGetUserAccountSuccess(result: any): Observable<GetUserAccountResponse> {
    let userAccountResponse = new GetUserAccountResponse(result);
    return of(userAccountResponse);
  }

  private afterGetUserAccountFailure(error: any): Observable<GetUserAccountResponse> {
    let userAccountResponse = new GetUserAccountResponse();
    return of(userAccountResponse);
  }
}


