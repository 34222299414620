import { Injectable } from '@angular/core';
import { DataLoadedActions } from '../store/reducers/dataloaded.reducer';
import { Store } from '@ngrx/store';
import { UserService } from './user.service';
import { PartnerContactService } from './partner-contact.service';

@Injectable()
export class AuthenticatorHelperService {

    constructor(private userService: UserService, private dataLoadedActions: DataLoadedActions,
        private partnerContactService: PartnerContactService, private store: Store) { }

    public async resolveAuthentication(deviceToken: string) : Promise<any>  {
        if (this.userService.customerDeviceTokenExists() && deviceToken != undefined && deviceToken && 
            deviceToken != null) {
          this.userService.setDeviceTokenChecked(true,deviceToken);
          this.store.dispatch(this.dataLoadedActions.setDataLoaded(false));
          // Get the deviceId from Cookie
          return await this.partnerContactService.generateFsToken("auth_device", deviceToken).subscribe(response => {
            if (!response.error) {
              this.onGenerateFsTokenFailure(deviceToken);
            }
          });
        }
      }
    
    private onGenerateFsTokenFailure(deviceToken: string) {
        this.userService.setDeviceTokenChecked(true, deviceToken);
        this.store.dispatch(this.dataLoadedActions.setDataLoaded(true));
    }
}