  export enum HeaderMenuItemType {
    MyAccount = "My Account",
    EditUsername = "Edit Username",
    Logout = "Logout"
  }
  
  export enum FooterMenuItemTypeForMini {
    LanguageEnglish = "English",
    LanguageFrench = "French",
    PrivacyAndLegal = "Privacy & Legal",
    TermsAndConditions = "Terms and Conditions",
    FAQ = "FAQ",
    CookiePolicy = "Cookie Policy",
    ContactUs = "Contact Us",
    MINICA = "MINI.ca"
  }  