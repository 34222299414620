import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { tassign } from 'tassign';
import { ContractAccountDetailDTO } from 'projects/_shared/core/gatewayapi/auth-service.api';
import { FSA } from 'flux-standard-action';
import { INITIAL_STATE } from '../app.store';

export class ContractAccountDetailsActions {
  static SET_CONTRACTS: string = 'SET_CONTRACTS';
  static CLEAR_CONTRACTS: string = 'CLEAR_CONTRACTS';

  setContracts(contracts: ContractAccountDetailDTO[]): FSA<string, ContractAccountDetailDTO[], null> {
    return {
      type: ContractAccountDetailsActions.SET_CONTRACTS,
      payload: contracts,
      meta: null
    };
  }

  clearContracts(): FSA<string, ContractAccountDetailDTO[], null> {
    return {
      type: ContractAccountDetailsActions.CLEAR_CONTRACTS,
      payload: [],
      meta: null
    };
  }

}

@Injectable()
export class ContractAccountDetailsReducers {
  static ContractAccountDetailsReducer: Reducer<ContractAccountDetailDTO[] | undefined, FSA<string, ContractAccountDetailDTO[], void>> = (state: ContractAccountDetailDTO[] = INITIAL_STATE.ContractAccountDetails as ContractAccountDetailDTO[], action: FSA<string, ContractAccountDetailDTO[], void>) => {
    switch (action.type) {
      case ContractAccountDetailsActions.SET_CONTRACTS:
        return tassign(state, action.payload);
      case ContractAccountDetailsActions.CLEAR_CONTRACTS:
        return state = [];
      default:
        return state;
    }
  }
}