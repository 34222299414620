import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { BooleanModel } from '../../../models/boolean.model';
import { FSA } from 'flux-standard-action';
import { tassign } from 'tassign';


export class FeatureIndicatorActions {
  static SET_MULTIFACTOR_AUTHENTICATION: string = 'SET_MULTIFACTOR_AUTHENTICATION';
  static SET_EPAAS: string = 'SET_EPAAS';

  setMultiFactorAuthentication(multiFactorAuthentication: boolean): FSA<string, boolean, null> {
    return {
      type: FeatureIndicatorActions.SET_MULTIFACTOR_AUTHENTICATION,
      payload: multiFactorAuthentication,
      meta: null
    };
  }
  
  setEPaas(featureState: boolean): FSA<string, boolean, null> {
    return {
      type: FeatureIndicatorActions.SET_EPAAS,
      payload: featureState,
      meta: null
    }
  }


}

@Injectable()
export class FeatureIndicatorReducers {
  static MultiFactorAuthenticationReducer: Reducer<BooleanModel | undefined,FSA<string, boolean,void>> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
    if (action.type === FeatureIndicatorActions.SET_MULTIFACTOR_AUTHENTICATION) {
      return tassign(state, new BooleanModel(action.payload as boolean));
    }
    else{
      return state;
    }
  }
  
  static EPaasEnabledReducer: Reducer<BooleanModel | undefined, FSA<string, boolean, void>> = (state: BooleanModel = new BooleanModel(false), action: FSA<string, boolean, void>) => {
    if (action.type === FeatureIndicatorActions.SET_EPAAS) {
      return tassign(state, new BooleanModel(action.payload as boolean));
    }
    else {
      return state;
    }
  }

}
