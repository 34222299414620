import { Injectable } from "@angular/core";
import { FSA } from "flux-standard-action";
import { ContractAccountDetailDTO } from "projects/_shared/core/gatewayapi/auth-service.api";
import { Reducer } from "redux";
import { tassign } from "tassign";
import { INITIAL_STATE } from "../app.store";

export class ContractAccountDetailActions {
  static SET_CONTRACT_ACCOUNT_DETAIL: string = 'SET_CONTRACT_ACCOUNT_DETAIL';
  static CLEAR_CONTRACT_ACCOUNT_DETAIL: string = 'CLEAR_CONTRACT_ACCOUNT_DETAIL';

  setContractAccountDetail(contractAccountDetail: ContractAccountDetailDTO): FSA<string, ContractAccountDetailDTO, null> {
    return {
      type: ContractAccountDetailActions.SET_CONTRACT_ACCOUNT_DETAIL,
      payload: contractAccountDetail,
      meta: null
    };
  }

  clearContractAccountDetail(): FSA<string, ContractAccountDetailDTO, null> {
    return {
      type: ContractAccountDetailActions.CLEAR_CONTRACT_ACCOUNT_DETAIL,
      payload: new ContractAccountDetailDTO(),
      meta: null
    };
  }

}

@Injectable()
export class ContractAccountDetailReducers {
  static ContractAccountDetailReducer: Reducer<ContractAccountDetailDTO | undefined, FSA<string, ContractAccountDetailDTO, void>> = (state: ContractAccountDetailDTO = INITIAL_STATE.ContractAccountDetail as ContractAccountDetailDTO, action: FSA<string, ContractAccountDetailDTO, void>) => {
    switch (action.type) {
      case ContractAccountDetailActions.SET_CONTRACT_ACCOUNT_DETAIL.toString():
        return tassign(state, action.payload);
      case ContractAccountDetailActions.CLEAR_CONTRACT_ACCOUNT_DETAIL.toString():
        return tassign(undefined);
      default:
        return state;
    }
  }
}