import { Injectable } from '@angular/core';
import { IAppState } from "../store/app.store";
import { TranslateService } from '@ngx-translate/core';
import { route, RouterService } from './router.service';
import * as _ from 'lodash';
import { GlobalNavigationUser } from '../../models/global-navigation.model';
import { UserService } from './user.service';
import { FooterModel } from '../../models/footer.model';
import { HeaderModel } from '../../models/header.model';
import { UrlBuilderService } from 'customerdigital-service-lib';
import { Store } from '@ngrx/store';


declare var GlobalnavPrimaryEmbedded: any;

@Injectable()
export class GlobalNavigationService {

  constructor(private translateService: TranslateService, private routerService: RouterService,
    private store: Store<IAppState>, private userService: UserService,private urlBuilderService: UrlBuilderService) {

  }
  private headerTranslationText!: string;
  private headerTranslationKey!: string;
  private editUsernameUrl!: string;
  private logoutUrl!: string;
  private footerTranslationText!: FooterModel;
  private footerTranslationKey!: FooterModel;
  private footerUrl!: FooterModel;
  private headerUrl!: HeaderModel;


  public initializeGlobalNavigation(isAuthenticated?: boolean): void {
    let translationKeys = this.getTranslationKeys();
    this.translateService.get(translationKeys).subscribe((res: any) => {
      let globalNavConfigObj = this.getGlobalNavConfigObject(res, isAuthenticated!);
      if (GlobalnavPrimaryEmbedded && globalNavConfigObj) {
        GlobalnavPrimaryEmbedded.init(globalNavConfigObj);
      }
    });
  }

  public initializeGlobalNavigationAfterLogin() {
    this.initializeGlobalNavigationAfterLoginStateChanged(true);
  }

  public initializeGlobalNavigationAfterLogout() {
    this.initializeGlobalNavigationAfterLoginStateChanged(false);
  }

  public initializeGlobalNavigationAfterLoginStateChanged(authenticated: boolean) {
    //reset config
    if (GlobalnavPrimaryEmbedded &&
      GlobalnavPrimaryEmbedded.config &&
      GlobalnavPrimaryEmbedded.defaultConfig &&
      GlobalnavPrimaryEmbedded.isInitialized) {
      GlobalnavPrimaryEmbedded.config = GlobalnavPrimaryEmbedded.defaultConfig;
      GlobalnavPrimaryEmbedded.isInitialized = false;
    }
    this.initializeGlobalNavigation(authenticated);
  }

  private setUrls() {
    this.footerUrl = new FooterModel();
    this.headerUrl = new HeaderModel();
    this.store.select(state => state.EnvironmentConfig!.PRIVACY_POLICY_URL).subscribe(x => this.footerUrl.privacy = x);
    this.store.select(state => state.EnvironmentConfig!.MY_DOMAIN_URL).subscribe(x => this.footerUrl.myBMW = x);
    this.store.select(state => state.EnvironmentConfig!.CONTACT_US).subscribe(x => this.footerUrl.contactus = x);
    this.store.select(state => state.EnvironmentConfig!.FOOTER_NAVIGATION_CONFIG?.TERMS_URL).subscribe(x => this.footerUrl.terms = x);
    this.store.select(state => state.EnvironmentConfig!.FOOTER_NAVIGATION_CONFIG?.FAQ_URL).subscribe(x => this.footerUrl.faq = x);
    this.logoutUrl = route.ssoLogout;
    this.store.select(state => state.EnvironmentConfig!.TOP_HEADER_NAVIGATION_CONFIG?.HOME_URL).subscribe(x => this.headerUrl.home = x);
    this.store.select(state => state.EnvironmentConfig!.TOP_HEADER_NAVIGATION_CONFIG?.MY_GARAGE_URL).subscribe(x => this.headerUrl.myGarage = x);
    this.store.select(state => state.EnvironmentConfig!.TOP_HEADER_NAVIGATION_CONFIG?.MY_ACCOUNT_URL).subscribe(x => this.headerUrl.myAccount = x);
    this.store.select(state => state.EnvironmentConfig!.TOP_HEADER_NAVIGATION_CONFIG?.MY_PROFILE_URL).subscribe(x => this.headerUrl.myProfile = x);
    this.headerUrl.bmwLogin =this.urlBuilderService.getEditUsernameRedirectUrl();
    this.store.select(state => state.EnvironmentConfig!.TOP_HEADER_NAVIGATION_CONFIG?.LOG_OUT_MY_BMW_URL).subscribe(x => this.headerUrl.logOutMyBMW  = x);
  }

  private getTranslationKeys():string[] {
    
    this.footerTranslationKey = new FooterModel(
      'footer.privacy-policy',
      'footer.lnk-online-services-agreement',
      'footer.faq',
      'footer.contact-us',
      'footer.my-bmw'
      );
    this.headerTranslationKey = "header.manage-settings";
    let footerTranslationKeys = _.values(this.footerTranslationKey!);
    let  translationKeys = footerTranslationKeys.concat([this.headerTranslationKey]) as string[];
    return translationKeys;
  }

  private setTranslationText(response: any) {
    if (response) {
      if (this.footerTranslationKey) {
        this.footerTranslationText = new FooterModel();
        this.footerTranslationText.privacy = response[this.footerTranslationKey.privacy!];
        this.footerTranslationText.terms = response[this.footerTranslationKey.terms!];
        this.footerTranslationText.faq = response[this.footerTranslationKey.faq!];
        this.footerTranslationText.contactus = response[this.footerTranslationKey.contactus!];
        this.footerTranslationText.myBMW = response[this.footerTranslationKey.myBMW!];
        
      }
      if (this.headerTranslationKey) {
        this.headerTranslationText = response[this.headerTranslationKey]
      }
    }
  }

  private getGlobalNavConfigObject(translationResponse: any, isAuthenticated: boolean) {
    this.setUrls();
    this.setTranslationText(translationResponse);
    let headerTitle! : string;
    this.translateService.get('global.header-title').subscribe(res => headerTitle = res);
    let name = this.userService.getFirstName();
    let email = this.userService.getUserName();
    let user = isAuthenticated ? new GlobalNavigationUser(name, email) : null;
    let domain : string | undefined;
    this.store.select(state => state.EnvironmentConfig!.GLOBAL_NAV_DOMAIN_URL).subscribe(x => domain = x)
    let configObject = {
      navigationElement: "header",
      footerElement: "div#footer",
      title: headerTitle,
      domain: domain,
      logout: this.logoutUrl,
      id: "logoutLink",
      profileLinks: this.getProfileLinks(),
      footerLinks: this.getFooterLinks(),
      user: user
    }
    return configObject;
  }

  private getProfileLinks(): any[] {
    return [
      {        
        url: this.headerUrl.home,
        id: 'home'
      },
      {
        url: this.headerUrl.myGarage,
        id: 'myGarage'
      },
      { 
        url: this.headerUrl.myAccount,
        id: 'myAccount'
      },
      {
        url: this.headerUrl.myProfile,
        id: 'myProfile'
      },
      {
        url: this.headerUrl.bmwLogin,
        id: 'bmwLogin',
        isNewTab: true,
        active: true
      },
      {
        url: this.headerUrl.logOutMyBMW,
        id: 'logOutMyBMW'
      }
    ];
  }
  private getFooterLinks(): any[] {
    return [
      {
        name: this.footerTranslationText.privacy,
        title: this.footerTranslationText.privacy,
        url: this.footerUrl.privacy,
        newTab: true,
        active: true,
        analyticEvent: "flyout.authhub.privacy-policy"
      },
      {
        name: this.footerTranslationText.terms,
        title: this.footerTranslationText.terms,
        url: this.footerUrl.terms,
        newTab: false,
        active: true,
        analyticEvent: "flyout.authhub.terms-and-conditions"
      },
      {
        name: this.footerTranslationText.faq,
        title: this.footerTranslationText.faq,
        url: this.footerUrl.faq,
        newTab: false,
        active: true,
        analyticEvent: "flyout.authhub.faq"
      },
      {
        name: this.footerTranslationText.contactus,
        title: this.footerTranslationText.contactus,
        url: this.footerUrl.contactus,
        newTab: false,
        active: true,
        analyticEvent: "flyout.authhub.contact-us"
      },
      {
        name: this.footerTranslationText.myBMW,
        title: this.footerTranslationText.myBMW,
        url: this.footerUrl.myBMW,
        newTab: false,
        active: true,
        analyticEvent: "flyout.authhub.bmwusa"
      }
    ];
  }  

}
