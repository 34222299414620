import { Store } from '@ngrx/store';
import { LibConfigurationProvider, LibConfig } from 'customerdigital-service-lib';
import { IAppState } from '../store/app.store';

export class LibConfiguration implements LibConfigurationProvider {

  constructor(private store: Store<IAppState>) { }

  get config(): LibConfig {
    let gateway_api_base_url: string | undefined;
    let clientId: string | undefined;
    let client: string | undefined;
    let siteId: number | undefined ;
    let brand: string | undefined;
    let country: string | undefined;
    let language: string | undefined;
    let base_url: string | undefined;
    let profile_url: string | undefined;
    let ssoRedirectUri: string | undefined;
    let scope: string | undefined;
    let responsetype:string | undefined;
    let loginurl: string | undefined;
    let granttype: string | undefined;
    let logouturl: string | undefined;
    let storageType: number | undefined;

    let idleTime: number | undefined;
    let timeoutTime: number | undefined;
  
    let baseDomain: string | undefined;
    let customLinkTracking: boolean | undefined;
    let dataLayerName: string | undefined;
    let debug: boolean | undefined;
    let embedScriptUrl: string | undefined;
    let isMobile: boolean | undefined;
    let subDomain: string | undefined;
    let siteName: string | undefined;

    this.store.select(x=> x.EnvironmentConfig?.GATEWAY_API_BASE_URL).subscribe(s=> gateway_api_base_url = s)
    this.store.select(x=> x.ApplicationConfig?.CLIENT_ID).subscribe(s=> clientId = s)
    this.store.select(x=> x.ApplicationConfig?.SITEID).subscribe(s=> siteId = s)
    this.store.select(x=> x.ApplicationConfig?.BRAND.toLowerCase()).subscribe(s=> brand = s)
    this.store.select(x=> x.ApplicationConfig?.COUNTRYCODE).subscribe(s=> country = s)
    this.store.select(x=> x.ApplicationConfig?.LANGUAGE).subscribe(s=> language = s)
    this.store.select(x=> x.EnvironmentConfig?.BASE_URL).subscribe(s=> base_url = s)
    this.store.select(x=> x.EnvironmentConfig?.PROFILE_URL).subscribe(s=> profile_url = s)
    this.store.select(x=> x.EnvironmentConfig?.SSO_REDIRECT_URL).subscribe(s=> ssoRedirectUri = s)
    this.store.select(x=> x.ApplicationConfig?.CLIENT).subscribe(s=> client = s)

    this.store.select(x=> x.ApplicationConfig?.SCOPE).subscribe(s=> scope = s)
    this.store.select(x=> x.ApplicationConfig?.RESPONSE_TYPE).subscribe(s=> responsetype = s)
    this.store.select(x=> x.EnvironmentConfig?.LOGIN_URL).subscribe(s=> loginurl = s)
    this.store.select(x=> x.ApplicationConfig?.GRANT_TYPE).subscribe(s=> granttype = s)
    this.store.select(x=> x.EnvironmentConfig?.LOGOUT_URL).subscribe(s=> logouturl = s)
    this.store.select(x=> x.ApplicationConfig?.STORAGE_TYPE).subscribe(s=> storageType = s)

    this.store.select(x=> x.EnvironmentConfig?.IDLE_SESSION).subscribe(s=> idleTime = s)
    this.store.select(x=> x.EnvironmentConfig?.TIME_OUT_SESSION).subscribe(s=> timeoutTime = s)
    this.store.select(x=> x.EnvironmentConfig?.ANALYTICS_CONFIG?.BASE_DOMAIN).subscribe(s=> 
      baseDomain = s )
    this.store.select(x=> x.EnvironmentConfig?.ANALYTICS_CONFIG?.CUSTOM_LINK_TRACKING).subscribe(s=> customLinkTracking = s)
    this.store.select(x=> x.EnvironmentConfig?.ANALYTICS_CONFIG?.DATALAYER_NAME).subscribe(s=> dataLayerName = s)
    this.store.select(x=> x.EnvironmentConfig?.ANALYTICS_CONFIG?.DEBUG).subscribe(s=> debug = s)
    this.store.select(x=> x.EnvironmentConfig?.ANALYTICS_CONFIG?.EMBED_SCRIPT_URL).subscribe(s=> embedScriptUrl = s)
    this.store.select(x=> x.EnvironmentConfig?.ANALYTICS_CONFIG?.IS_MOBILE).subscribe(s=> isMobile = s)
    this.store.select(x=> x.EnvironmentConfig?.ANALYTICS_CONFIG?.SUB_DOMAIN).subscribe(s=> subDomain = s)
    this.store.select(x=> x.EnvironmentConfig?.ANALYTICS_CONFIG?.SITE_NAME).subscribe(s=> siteName = s)

    return {
      gateway_api_base_url: gateway_api_base_url,
      clientId: clientId,
      siteId: siteId,
      brand: brand,
      country: country,
      language: language,
      base_url: base_url,
      profile_url: profile_url,
      sso: {
        ssoRedirectUri: ssoRedirectUri,
        idp: {
          client: client,
          scope: scope,
          responsetype: responsetype,
          loginurl: loginurl,
          granttype: granttype,
          logouturl: logouturl
        },
        storageType: storageType
      },
      IdleTimeOutConfig: {
        idleTime: (idleTime ? idleTime : 18) * 60,
        timeoutTime: (timeoutTime ? timeoutTime : 2) * 60
      },
      AnalyticsConfig: {
        baseDomain: baseDomain,
        customLinkTracking: customLinkTracking,
        dataLayerName: dataLayerName,
        debug: debug,
        embedScriptUrl: embedScriptUrl,
        isMobile: isMobile,
        subDomain: subDomain,
        siteName: siteName
      }
    };
  }
}
