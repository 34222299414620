import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ErrorComponent } from 'projects/_shared/components/error/error.component';

const sharedRoutes: Routes = [
    { path: 'error', component: ErrorComponent },
];

@NgModule({
    imports: [
        RouterModule.forChild(sharedRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class SharedRoutingModule { }