import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { FSA } from 'flux-standard-action';


export class IndicatorActions
{
    static SET_DATA_LOADED: string = 'DataLoaded';
    dataLoaded(state: boolean): FSA<string, boolean, null>{
        return {
            type: IndicatorActions.SET_DATA_LOADED,
            payload: state,
            meta: null
        };
    }
}

@Injectable()
export class IndicatorReducers
{
    static DataLoadedReducer: Reducer<boolean | undefined,any> = (state: boolean = false, action: any) => {
        if(action.type == IndicatorActions.SET_DATA_LOADED){
            return action.payload;
        }
        else{
            return state;
        }
    }
    
}
