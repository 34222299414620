import { Injectable } from '@angular/core';
import { Constants } from '../constants';
import * as _ from 'lodash-es';
import { IAppState } from "../../shared/store/app.store";
import { TranslateService } from '@ngx-translate/core';
import { AccountStatus, FinanceProduct } from '../enum';
import { ContractAccountDetailDTO, ContractAccountDetailDTOContractType } from 'projects/_shared/core/gatewayapi/auth-service.api';
import { Store } from '@ngrx/store';

@Injectable()
//this service should be used for any account/contract info/data manipulation
export class AccountInfoService {

  constructor(private store: Store<IAppState>,
    private translateService: TranslateService) { }

  public getAccountCategory(portfolioCategoryCode: string): string {
    let lease!: string;
    let financing!: string;
    this.translateService.get("general.lease").subscribe((res: string) => {
      lease = res;
    });
    this.translateService.get("general.financing").subscribe((res: string) => {
      financing = res;
    });
    if (portfolioCategoryCode === FinanceProduct.Lease.toString()) {
      return lease ;
    }
    else if (portfolioCategoryCode === FinanceProduct.Loan.toString()) {
      return financing;
    }
    else {
      return Constants.EMPTY;
    }
  }

  public isLeaseAccount(portfolioCategoryCode: any): boolean {
    return portfolioCategoryCode === FinanceProduct.Lease.toString();
  }

  public isActiveAccount(statusCategoryCode: string): boolean {
    return statusCategoryCode == AccountStatus.Active.toString();
  }

  public isTerminatedAccount(statusCategoryCode: any): boolean {
    return statusCategoryCode == AccountStatus.Terminated.toString();
  }

  public isOwnersChoiceAccount(contractType: ContractAccountDetailDTOContractType): boolean {
    return contractType === ContractAccountDetailDTOContractType.OwnersChoiceRetail;
  }

  public isSelectAccount(contractType: ContractAccountDetailDTOContractType): boolean {
    return contractType === ContractAccountDetailDTOContractType.Select;
  }

  public isPastDue(pastDueAmount: number) {
    return pastDueAmount > 0;
  }
   
}